import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sdk from 'api-sdk';
import './dark.css'

// Define defenseRulesOptions
const defenceRulesOptions = [
  'Sysmon',
  'CrowdStrike Falcon',
  'Microsoft Defender',
  'Carbon Black',
  'SentinelOne',
  'Cynet',
  'Tanium',
  'Symantec',
  'FireEye',
  'McAfee',
  'Bitdefender GravityZone',
  'Palo Alto Cortex',
  'Azure Sentinel',
  'Aws Athena',
  'GPO',
];

// Define statusOptions and color map
const statusOptions = [
  'Pass',
  'Fail',
  'No defense',
  'Detected',
  'No POC',
  'No Working',
  'Declined',
];
// Map status => color for the dropdown text
const statusColorMap = {
  'Pass': 'green',
  'Fail': 'red',
  'No defense': 'orange',
  'Detected': 'blue',
  'No POC': 'purple',
  'No Working': 'brown',
  'Declined': 'darksalmon',
};

// If you want colors for categories
const categoryColorMap = {
  'Anti Forensics': '#6610f2',
  'C&C': '#9400D3',
  Collection: '#007BFF',
  Compile: '#4FC3F7',
  'Credential Access': '#20C997',
  'Data Exfiltration': '#28A745',
  'Defense Evasion': '#93C432',
  Discovery: '#67882F',
  Execution: '#FFC107',
  Impact: '#FD7E14',
  'Initial Access': '#F4A460',
  'Lateral Movement': '#FA8072',
  Misconfiguration: '#DC3545',
  Persistence: '#E83E8C',
  'Privilege Escalation': '#811840',
};

const MultiRunHistoryModal = ({
  isOpen,
  toggle,
  selectedBullets, // array of bullet objects
  onSaveRunHistory, // function to call when saving
}) => {
  // Single choice for all bullets:
  const [selectedCompany, setSelectedCompany] = useState('');
  const [runDate, setRunDate] = useState('');
  const [runNumber, setRunNumber] = useState('');
  const [companies, setCompanies] = useState([]);

  // Per-bullet fields: defenseRule, status, notes
  const [bulletFields, setBulletFields] = useState([]);

  // Fetch companies once (similar to your single-run form)
  useEffect(() => {
    sdk.companies.getAll()
      .then((res) => setCompanies(res))
      .catch((err) => {
        console.error('Error loading companies:', err);
        toast.error('Failed to load companies');
      });
  }, []);

  // Initialize bulletFields each time the selectedBullets changes
  useEffect(() => {
    if (!selectedBullets) return;
    // Build default states for each bullet:
    const initialFields = selectedBullets.map((b) => ({
      bulletId: b.id,
      bulletName: b.name,
      // Use bullet.attackVector or some fallback
      category: b.attackVector || '',
      defenceRule: '',
      status: '',
      notes: '',
    }));
    setBulletFields(initialFields);
  }, [selectedBullets]);

  // Helper to get color for category
  const getCategoryColor = (cat) => {
    return categoryColorMap[cat] || 'inherit';
  };

  // When user modifies the row (defense rule, status, or notes):
  const handleRowChange = (bulletId, fieldName, value) => {
    setBulletFields((prev) =>
      prev.map((bf) => {
        if (bf.bulletId === bulletId) {
          return { ...bf, [fieldName]: value };
        }
        return bf;
      })
    );
  };

  const validateForm = () => {
    if (!selectedCompany) {
      toast.error('Please select a company');
      return false;
    }
    if (!runNumber) {
      toast.error('Please enter a Run Number');
      return false;
    }
    // Make sure each bullet has status & defense rule
    for (let bf of bulletFields) {
      if (!bf.status) {
        toast.error(`Please choose a status for bullet "${bf.bulletName}"`);
        return false;
      }
      if (!bf.defenceRule) {
        toast.error(`Please choose a defense rule for bullet "${bf.bulletName}"`);
        return false;
      }
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    // Convert each row to a runHistory object
    const records = bulletFields.map((bf) => ({
      bulletId: bf.bulletId,
      bulletName: bf.bulletName,
      category: bf.category,
      companyName: selectedCompany,
      runDate: runDate || null,
      runNumber: runNumber,
      status: bf.status,
      defenceRules: bf.defenceRule,
      notes: bf.notes,
    }));

    try {
      const validRecords = [];
      let duplicatesCount = 0;

      // 1) Check duplicates for each bullet
      for (const record of records) {
        const { bulletName, runNumber } = record;
        const res = await sdk.runHistory.duplicateExists(bulletName, runNumber);

        if (res.exists) {
          // If a duplicate, skip it
          duplicatesCount++;
          // Optionally show a warning for each:
          toast.warn(
            `Duplicate found: Bullet "${bulletName}" (Run #${runNumber}) was not saved.`
          );
        } else {
          // Not a duplicate -> keep this record
          validRecords.push(record);
        }
      }

      // 2) If we have any valid (non-duplicate) records, create them
      if (validRecords.length > 0) {
        await onSaveRunHistory(validRecords);
        toast.success(`${validRecords.length} run histories created successfully!`);
      }

      // 3) If all were duplicates, none get created
      if (validRecords.length === 0 && duplicatesCount > 0) {
        toast.error('All selected bullets were duplicates. Nothing was created.');
        return;
      }

      // 4) If partial duplicates found, you already showed warnings above
      if (duplicatesCount > 0 && validRecords.length > 0) {
        toast.info(`Some records were skipped due to duplicates: ${duplicatesCount} total.`);
      }

      // 5) Finally, close the modal (if you want to)
      toggle();

    } catch (error) {
      console.error('Error creating run history:', error);
      toast.error('Failed to create run histories');
    }
  };



  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      className="dark-modal"
    >
      <ModalHeader toggle={toggle} className="dark-modal-header">
        Create Run History for Selected Bullets
      </ModalHeader>

      <ModalBody className="dark-modal-body">
        <Form>
          {/* GLOBAL FIELDS for all bullets */}
          <FormGroup>
            <Label for="companySelect">Company</Label>
            <Input
              type="select"
              id="companySelect"
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
            >
              <option value="">Select Company</option>
              {companies.map((c) => (
                <option key={c.companyID} value={c.companyName}>
                  {c.companyName}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="runDate">Run Date (optional)</Label>
            <Input
              type="date"
              id="runDate"
              value={runDate}
              onChange={(e) => setRunDate(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="runNumber">Run Number</Label>
            <Input
              type="text"
              id="runNumber"
              value={runNumber}
              onChange={(e) => setRunNumber(e.target.value)}
            />
          </FormGroup>

          {/* TABLE for each bullet's data */}
          <Table bordered className="dark-table">
            <thead>
              <tr>
                <th>Bullet Name</th>
                <th>Category</th>
                <th>Defense Rule</th>
                <th>Status</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {bulletFields.map((bf) => (
                <tr key={bf.bulletId}>
                  <td>{bf.bulletName}</td>
                  <td style={{ color: getCategoryColor(bf.category) }}>
                    {bf.category}
                  </td>
                  <td>
                    <Input
                      type="select"
                      value={bf.defenceRule}
                      onChange={(e) =>
                        handleRowChange(bf.bulletId, 'defenceRule', e.target.value)
                      }
                      className="dark-select"
                    >
                      <option value="">Select Rule</option>
                      {defenceRulesOptions.map((rule) => (
                        <option key={rule} value={rule}>
                          {rule}
                        </option>
                      ))}
                    </Input>
                  </td>
                  <td>
                    <Input
                      type="select"
                      value={bf.status}
                      onChange={(e) =>
                        handleRowChange(bf.bulletId, 'status', e.target.value)
                      }
                      className="dark-select"
                      style={{ color: statusColorMap[bf.status] }}
                    >
                      <option value="">Select Status</option>
                      {statusOptions.map((st) => (
                        <option
                          key={st}
                          value={st}
                          style={{ color: statusColorMap[st] }}
                        >
                          {st}
                        </option>
                      ))}
                    </Input>
                  </td>
                  <td>
                    <Input
                      type="text"
                      value={bf.notes}
                      onChange={(e) =>
                        handleRowChange(bf.bulletId, 'notes', e.target.value)
                      }
                      placeholder="Optional notes"
                      className="dark-input"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </ModalBody>

      <ModalFooter className="dark-modal-footer">
        <Button color="primary" onClick={handleSave}>
          Save Run History
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MultiRunHistoryModal;

