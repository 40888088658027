import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

class PlaybookTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playbookText: props.playbookText || "",
      prevPropsPlaybookText: props.playbookText || ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Only update state from props if the prop actually changed
    if (nextProps.playbookText !== prevState.prevPropsPlaybookText) {
      return {
        playbookText: nextProps.playbookText || "",
        prevPropsPlaybookText: nextProps.playbookText
      };
    }
    return null; // No state update needed
  }

  handleChange(e) {
    const playbookText = e.target.value;
    this.setState({ playbookText });
    
    // Notify the parent of the change
    if (this.props.onPlaybookChange) {
      this.props.onPlaybookChange(playbookText);
    }
  }

  render() {
    const { title } = this.props;
    
    return (
      <div>
        <FormGroup>
          <Label for="playbookText">{title || "Playbook"} Instructions:</Label>
          <Input
            type="textarea"
            id="playbookText"
            rows="15"
            value={this.state.playbookText}
            onChange={this.handleChange}
            placeholder="Enter detailed instructions or procedural steps for implementing this detection..."
            style={{ fontFamily: "monospace" }}
          />
        </FormGroup>
      </div>
    );
  }
}

export default PlaybookTab; 