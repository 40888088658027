import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  FormGroup,
  Label,
  Input,
  CustomInput,
  Row,
  Col,
  Button
} from "reactstrap";
import { cortexConfig } from "../../assets/data/cortexConfig";

/**
 * A functional component that:
 * - Renders a dropdown for the user to pick a "cortex type" (e.g. "Custom IOA Rule", "File Vantage Rules", "NextGen Siem Correlation rule").
 * - If the user picks "Custom IOA Rule", we show a dynamic builder with (Field + Value) pairs and a "Testing String".
 * - If the user picks "File Vantage Rules", we show a second dropdown for sub-rules, then show static fields from cortexConfig.
 * - If the user picks "NextGen Siem Correlation rule", we show static fields from cortexConfig.
 * - Calls onChange(...) with the entire data each time the user changes anything.
 */

// The list of fields for "Custom IOA Rule" dynamic builder:
const customIoaFields = [
  "Grandparent Image Filename",
  "Grandparent Image Filename– exclude",
  "Grandparent Command Line",
  "Grandparent Command Line -exclude",
  "Parent Image Filename",
  "Parent Image Filename -exclude",
  "Parent Command Line",
  "Parent Command Line -exclude",
  "Image Filename",
  "Image Filename -exclude",
  "Command Line",
  "Command Line– exclude"
];

function AddCortex({ fieldData, onChange }) {
  const didMountRef = useRef(false);
  
  // Overall "type" or "rule" user picks in the first dropdown
  const [cortexType, setCortexType] = useState(fieldData && fieldData.cortexType ? fieldData.cortexType : "");

  // For "File Vantage Rules" or "NextGen Siem Correlation rule", we keep subRule or fields logic
  const [subRule, setSubRule] = useState(fieldData && fieldData.cortexSubRule ? fieldData.cortexSubRule : "");

  // For any "static" fields we get from cortexConfig (like File Vantage or NextGen Siem):
  const [fieldValues, setFieldValues] = useState(fieldData && fieldData.fieldValues ? fieldData.fieldValues : {});

  // === DYNAMIC BUILDER (used only for "Custom IOA Rule") ===
  // Each array element is { field: string, value: string }
  const [dynamicFields, setDynamicFields] = useState(
    fieldData && fieldData.customIoaData && fieldData.customIoaData.dynamicFields && 
    fieldData.customIoaData.dynamicFields.length ? 
    fieldData.customIoaData.dynamicFields : 
    [{ field: "", value: "" }]
  );
  
  // The "Testing String" that always shows for "Custom IOA Rule"
  const [testingString, setTestingString] = useState(
    fieldData && fieldData.customIoaData && fieldData.customIoaData.testingString ? 
    fieldData.customIoaData.testingString : ""
  );

  // Helper function to build and notify the parent with updated data
  const notifyParent = useCallback(() => {
    if (!onChange) return;
    
    console.log("AddCortex: Notifying parent of changes");

    // If "Custom IOA Rule", we'll pass the dynamic builder data
    if (cortexType === "Custom IOA Rule") {
      const data = {
        cortexType: cortexType,
        customIoaData: {
          dynamicFields: dynamicFields.slice(), // copy
          testingString: testingString
        }
      };
      console.log("Sending custom IOA data:", data);
      onChange(data);
      return;
    }

    // Otherwise, handle the old logic with sub-rules or direct fields from cortexConfig
    const chosenConfig = cortexConfig[cortexType] || {};

    // 1) If there is subRules and we picked one, we gather its fields from fieldValues
    if (chosenConfig.subRules && subRule) {
      const data = {
        cortexType: cortexType,
        cortexSubRule: subRule,
        fieldValues: fieldValues
      };
      console.log("Sending sub-rule data:", data);
      onChange(data);
    }
    // 2) If we have direct fields (like NextGen Siem)
    else if (chosenConfig.fields) {
      const data = {
        cortexType: cortexType,
        fieldValues: fieldValues
      };
      console.log("Sending field data:", data);
      onChange(data);
    }
    // Otherwise, nothing special
    else if (cortexType) {
      console.log("Sending basic cortex type:", { cortexType });
      onChange({ cortexType: cortexType });
    }
  }, [cortexType, subRule, fieldValues, dynamicFields, testingString, onChange]);

  // Watch for changes in the primary dependencies and notify parent
  useEffect(() => {
    if (didMountRef.current) {
      notifyParent();
    } else {
      didMountRef.current = true;
    }
  }, [cortexType, subRule, notifyParent]);

  // Effect to handle changes in fieldData prop (for edit mode)
  useEffect(() => {
    if (!fieldData) return;

    console.log("AddCortex: Received fieldData update", fieldData);
    
    // Only update state from fieldData if it's different from current state
    const newCortexType = fieldData.cortexType || "";
    const newSubRule = fieldData.cortexSubRule || "";
    const newFieldValues = fieldData.fieldValues || {};
    
    let newDynamicFields = [{ field: "", value: "" }];
    let newTestingString = "";
    
    if (fieldData.customIoaData) {
      if (fieldData.customIoaData.dynamicFields && fieldData.customIoaData.dynamicFields.length) {
        newDynamicFields = fieldData.customIoaData.dynamicFields;
      }
      newTestingString = fieldData.customIoaData.testingString || "";
    }

    // Only update if values are different to prevent infinite loop
    if (newCortexType !== cortexType) {
      setCortexType(newCortexType);
    }
    
    if (newSubRule !== subRule) {
      setSubRule(newSubRule);
    }
    
    // For objects, do simple comparison
    if (JSON.stringify(newFieldValues) !== JSON.stringify(fieldValues)) {
      setFieldValues(newFieldValues);
    }
    
    // For arrays, do simple comparison
    if (JSON.stringify(newDynamicFields) !== JSON.stringify(dynamicFields)) {
      setDynamicFields(newDynamicFields);
    }
    
    if (newTestingString !== testingString) {
      setTestingString(newTestingString);
    }
  }, [fieldData]);

  /** Helper: Called when user picks a new cortexType from the main dropdown. */
  const handleCortexTypeChange = (e) => {
    const newType = e.target.value;
    setCortexType(newType);

    // Reset subRule & fieldValues & dynamic stuff
    setSubRule("");
    setFieldValues({});
    setDynamicFields([{ field: "", value: "" }]);
    setTestingString("");
    
    // Immediately notify parent with the new cortex type only
    if (onChange && newType) {
      onChange({ cortexType: newType });
    }
  };

  /** Helper: Called when user picks a sub-rule (if any) */
  const handleSubRuleChange = (e) => {
    const newSubRule = e.target.value;
    setSubRule(newSubRule);
    // Reset the fields
    setFieldValues({});
    
    // Immediately notify parent when subrule changes
    if (onChange && cortexType && newSubRule) {
      onChange({
        cortexType,
        cortexSubRule: newSubRule,
        fieldValues: {}
      });
    }
  };

  /** For "File Vantage" or "NextGen Siem" static fields: we update fieldValues. */
  const handleFieldValueChange = (label, val) => {
    const newFieldValues = { ...fieldValues, [label]: val };
    setFieldValues(newFieldValues);
    
    // Immediately notify parent when field values change
    if (onChange && cortexType) {
      const data = subRule 
        ? { cortexType, cortexSubRule: subRule, fieldValues: newFieldValues }
        : { cortexType, fieldValues: newFieldValues };
      onChange(data);
    }
  };

  // === DYNAMIC BUILDER LOGIC FOR "Custom IOA Rule" ===

  /** Add another (Field+Value) row. */
  const addFieldRow = () => {
    const newDynamicFields = [...dynamicFields, { field: "", value: "" }];
    setDynamicFields(newDynamicFields);
    
    // Notify parent of the change if we're in Custom IOA Rule mode
    if (onChange && cortexType === "Custom IOA Rule") {
      onChange({
        cortexType,
        customIoaData: {
          dynamicFields: newDynamicFields,
          testingString
        }
      });
    }
  };

  /** Remove a row by index (optional). If you do not want to remove lines, remove this button. */
  const removeFieldRow = (index) => {
    const newDynamicFields = dynamicFields.filter((_, i) => i !== index);
    setDynamicFields(newDynamicFields);
    
    // Notify parent of the change if we're in Custom IOA Rule mode
    if (onChange && cortexType === "Custom IOA Rule") {
      onChange({
        cortexType,
        customIoaData: {
          dynamicFields: newDynamicFields,
          testingString
        }
      });
    }
  };

  /** Update a particular row's field or value. */
  const updateFieldRow = (index, key, newVal) => {
    const newDynamicFields = [...dynamicFields];
    newDynamicFields[index] = { ...newDynamicFields[index], [key]: newVal };
    setDynamicFields(newDynamicFields);
    
    // Notify parent of the change if we're in Custom IOA Rule mode
    if (onChange && cortexType === "Custom IOA Rule") {
      onChange({
        cortexType,
        customIoaData: {
          dynamicFields: newDynamicFields, 
          testingString
        }
      });
    }
  };
  
  /** Update testing string value */
  const handleTestingStringChange = (e) => {
    const newTestingString = e.target.value;
    setTestingString(newTestingString);
    
    // Notify parent of the change if we're in Custom IOA Rule mode
    if (onChange && cortexType === "Custom IOA Rule") {
      onChange({
        cortexType,
        customIoaData: {
          dynamicFields,
          testingString: newTestingString
        }
      });
    }
  };

  // === Determine any direct fields to show (for File Vantage or NextGen Siem) ===
  const chosenConfig = cortexConfig[cortexType] || {};

  // subRuleOptions is for File Vantage
  const subRuleOptions = chosenConfig.subRules
    ? Object.keys(chosenConfig.subRules)
    : [];

  // If there's a subRule, use that subRule's fields; else use directFields
  let directFields = chosenConfig.fields || [];
  let relevantFields = directFields;

  if (subRule && chosenConfig.subRules && chosenConfig.subRules[subRule]) {
    relevantFields = chosenConfig.subRules[subRule].fields || [];
  }

  return (
    <div>
      {/* 1) cortexType dropdown */}
      <Row form>
        <Col md="6">
          <FormGroup>
            <Label for="cortexTypeSelect">CrowdStrike / SIEM Type</Label>
            <Input
              type="select"
              id="cortexTypeSelect"
              value={cortexType}
              onChange={handleCortexTypeChange}
            >
              <option value="">-- Select a Type --</option>
              {Object.keys(cortexConfig).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>

        {/* 2) subRule dropdown if subRules exist and we're NOT using dynamic approach */}
        {subRuleOptions.length > 0 && !chosenConfig.dynamic && (
          <Col md="6">
            <FormGroup>
              <Label for="cortexSubRuleSelect">Sub-rule</Label>
              <Input
                type="select"
                id="cortexSubRuleSelect"
                value={subRule}
                onChange={handleSubRuleChange}
                disabled={!cortexType}
              >
                <option value="">-- Select a Sub-rule --</option>
                {subRuleOptions.map((sr) => (
                  <option key={sr} value={sr}>
                    {sr}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        )}
      </Row>

      {/* 3) If "Custom IOA Rule", show the dynamic field builder */}
      {cortexType === "Custom IOA Rule" && (
        <div className="mt-3">
          <h5>Custom IOA Rule: Dynamic Field Builder</h5>
          {dynamicFields.map((row, idx) => (
            <Row form key={idx} className="align-items-center mb-2">
              <Col md="5">
                <FormGroup>
                  <Label>Field</Label>
                  <Input
                    type="select"
                    value={row.field}
                    onChange={(e) => updateFieldRow(idx, "field", e.target.value)}
                  >
                    <option value="">-- Select a Field --</option>
                    {customIoaFields.map((fieldOpt) => (
                      <option key={fieldOpt} value={fieldOpt}>
                        {fieldOpt}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="5">
                <FormGroup>
                  <Label>Value</Label>
                  <Input
                    type="text"
                    value={row.value}
                    onChange={(e) => updateFieldRow(idx, "value", e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="2" className="d-flex align-items-end">
                <Button
                  type="button"
                  className="mr-2"
                  style={{ marginTop: '10px' }}
                  onClick={addFieldRow}
                >
                  +
                </Button>

                {/* Optional remove button: If you don't want removal, remove this. */}
                {dynamicFields.length > 1 && (
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => removeFieldRow(idx)}
                  >
                    -
                  </Button>
                )}
              </Col>
            </Row>
          ))}

          {/* "Testing String" field */}
          <FormGroup>
            <Label>Testing String</Label>
            <Input
              type="text"
              value={testingString}
              onChange={handleTestingStringChange}
            />
          </FormGroup>
        </div>
      )}

      {/* 4) If user chose a type with direct fields (NextGen Siem) or a sub-rule with fields (File vantage)
             and it's NOT the dynamic approach, show them. */}
      {cortexType !== "Custom IOA Rule" && (relevantFields.length > 0 || subRule) && (
        <div className="mt-3">
          <h5>
            Fields for: {cortexType}
            {subRule ? " → " + subRule : ""}
          </h5>

          {relevantFields.map(function (fieldDef) {
            var label = fieldDef.label;
            var type = fieldDef.type;
            var options = fieldDef.options;

            // We'll pick a fallback value
            var fallback = (type === "bool") ? false : "";
            var currentValue = (
              fieldValues[label] !== undefined &&
              fieldValues[label] !== null
            ) ? fieldValues[label] : fallback;

            // boolean checkbox
            if (type === "bool") {
              return (
                <FormGroup check key={label} className="mb-2">
                  <CustomInput
                    id={"check-" + label}
                    type="checkbox"
                    label={label}
                    checked={!!currentValue}
                    onChange={(e) => handleFieldValueChange(label, e.target.checked)}
                  />
                </FormGroup>
              );
            }

            // dropdown
            if (type === "select" && Array.isArray(options)) {
              return (
                <FormGroup key={label}>
                  <Label>{label}</Label>
                  <Input
                    type="select"
                    value={currentValue}
                    onChange={(e) => handleFieldValueChange(label, e.target.value)}
                  >
                    <option value="">-- select an option --</option>
                    {options.map(function (opt) {
                      return (
                        <option key={opt} value={opt}>
                          {opt}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              );
            }

            // text input
            return (
              <FormGroup key={label}>
                <Label>{label}</Label>
                <Input
                  type="text"
                  value={currentValue}
                  onChange={(e) => handleFieldValueChange(label, e.target.value)}
                />
              </FormGroup>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default AddCortex;
