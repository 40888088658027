import React from 'react';

const StatusCounters = ({ companies, selectedCompany, statusColorMap }) => {
    // Compute counts per status
    const computeStatusCounts = () => {
        const counts = {};
        Object.keys(statusColorMap).forEach((key) => {
            counts[key] = 0;
        });

        if (selectedCompany && companies[selectedCompany]) {
            const histories = companies[selectedCompany].histories || [];
            histories.forEach((history) => {
                if (counts.hasOwnProperty(history.status)) {
                    counts[history.status] += 1;
                } else {
                    counts[history.status] = 1;
                }
            });
        } else {
            Object.values(companies).forEach((company) => {
                const histories = company.histories || [];
                histories.forEach((history) => {
                    if (counts.hasOwnProperty(history.status)) {
                        counts[history.status] += 1;
                    } else {
                        counts[history.status] = 1;
                    }
                });
            });
        }
        return counts;
    };

    // Compute unique payload count based on bulletId
    const computeUniquePayloadCount = () => {
        const payloadSet = new Set();
        if (selectedCompany && companies[selectedCompany]) {
            const histories = companies[selectedCompany].histories || [];
            histories.forEach((history) => {
                if (history.bulletId) {
                    payloadSet.add(history.bulletId);
                }
            });
        } else {
            Object.values(companies).forEach((company) => {
                const histories = company.histories || [];
                histories.forEach((history) => {
                    if (history.bulletId) {
                        payloadSet.add(history.bulletId);
                    }
                });
            });
        }
        return payloadSet.size;
    };

    const counts = computeStatusCounts();
    const uniquePayloadCount = computeUniquePayloadCount();

    return (
        <div
            style={{
                backgroundColor: '#333',
                color: '#fff',
                padding: '5px',
                borderRadius: '4px',
                fontSize: '12px',
            }}
        >
            <div
                style={{
                    paddingTop: '4px',
                }}
            >
                <span style={{ fontWeight: 'bold', color: '#fff' }}>
                    Total Payloads Run:
                </span>{' '}
                {uniquePayloadCount}
            </div>
            {Object.entries(counts).map(([status, count]) => (
                <div key={status} style={{ marginBottom: '1px' }}>
                    <span
                        style={{
                            fontWeight: 'bold',
                            color: statusColorMap[status] || '#fff',
                        }}
                    >
                        {status}:
                    </span>{' '}
                    {count}
                </div>
            ))}

        </div>
    );
};

export default StatusCounters;
