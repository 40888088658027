import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import sdk from "api-sdk";

class BlueRule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1", // Default to the Description tab
      chartBase64: null,
      chartLoading: true,
      clients: [], // Store clients for this rule
      clientsLoading: true
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  componentDidMount() {
    this.loadChartImage();
    this.loadClientData();
  }

  componentDidUpdate(prevProps) {
    const prevRuleId = prevProps.rule && prevProps.rule.id;
    const currentRuleId = this.props.rule && this.props.rule.id;
    
    if (prevRuleId !== currentRuleId) {
      this.loadChartImage();
      this.loadClientData();
    }
  }

  loadChartImage() {
    const { rule } = this.props;
    if (rule && rule.id) {
      this.setState({ chartLoading: true });
      sdk.blueRules.getChartBase64(rule.id)
        .then(response => {
          if (response && response.base64Chart) {
            this.setState({ chartBase64: response.base64Chart, chartLoading: false });
          } else {
            this.setState({ chartBase64: null, chartLoading: false });
          }
        })
        .catch(err => {
          console.error("Failed to load chart:", err);
          this.setState({ chartBase64: null, chartLoading: false });
        });
    }
  }

  async loadClientData() {
    const { rule } = this.props;
    if (rule && rule.id) {
      this.setState({ clientsLoading: true });
      try {
        const clients = await sdk.blueRuleClients.getByRuleId(rule.id);
        this.setState({ 
          clients: clients.map(client => client.client_name),
          clientsLoading: false 
        });
      } catch (err) {
        console.error("Failed to load client data:", err);
        this.setState({ clients: [], clientsLoading: false });
      }
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  renderSysmonContent(sysmon, sysmonPlaybook) {
    return (
      <div>
        {/* Regular Sysmon Configuration */}
        <div className="mb-4">
          <h4 className="border-bottom pb-2">Sysmon Configuration</h4>
          {!sysmon ? (
            <div>No Sysmon configuration available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {sysmon.xmlText}
            </pre>
          )}
        </div>

        {/* Sysmon Playbook */}
        <div>
          <h4 className="border-bottom pb-2">Sysmon Playbook</h4>
          {!sysmonPlaybook ? (
            <div>No Sysmon playbook available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {sysmonPlaybook.playbookText}
            </pre>
          )}
        </div>
      </div>
    );
  }

  renderSplunkContent(splunk, splunkPlaybook) {
    return (
      <div>
        {/* Regular Splunk Configuration */}
        <div className="mb-4">
          <h4 className="border-bottom pb-2">Splunk Configuration</h4>
          {!splunk ? (
            <div>No Splunk configuration available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {splunk.xmlText}
            </pre>
          )}
        </div>

        {/* Splunk Playbook */}
        <div>
          <h4 className="border-bottom pb-2">Splunk Playbook</h4>
          {!splunkPlaybook ? (
            <div>No Splunk playbook available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {splunkPlaybook.playbookText}
            </pre>
          )}
        </div>
      </div>
    );
  }

  renderSyslogContent(syslog, syslogPlaybook) {
    return (
      <div>
        {/* Regular Syslog Configuration */}
        <div className="mb-4">
          <h4 className="border-bottom pb-2">Syslog Configuration</h4>
          {!syslog ? (
            <div>No Syslog configuration available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {syslog.xmlText}
            </pre>
          )}
        </div>

        {/* Syslog Playbook */}
        <div>
          <h4 className="border-bottom pb-2">Syslog Playbook</h4>
          {!syslogPlaybook ? (
            <div>No Syslog playbook available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {syslogPlaybook.playbookText}
            </pre>
          )}
        </div>
      </div>
    );
  }

  renderQradarContent(qradar, qradarPlaybook) {
    return (
      <div>
        {/* Regular Qradar Configuration */}
        <div className="mb-4">
          <h4 className="border-bottom pb-2">Qradar Configuration</h4>
          {!qradar ? (
            <div>No Qradar configuration available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {qradar.xmlText}
            </pre>
          )}
        </div>

        {/* Qradar Playbook */}
        <div>
          <h4 className="border-bottom pb-2">Qradar Playbook</h4>
          {!qradarPlaybook ? (
            <div>No Qradar playbook available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {qradarPlaybook.playbookText}
            </pre>
          )}
        </div>
      </div>
    );
  }

  renderCortexContent(cortex, cortexPlaybook) {
    // Separate out the Testing String row from the rest for regular configuration
    const testingStringRow = cortex.find(
      (entry) => entry.fields === "Testing String"
    );
    const filteredCortex = cortex.filter(
      (entry) => entry.fields !== "Testing String"
    );

    // Split the remaining cortex entries into boolean and non-boolean fields.
    const trueBoolFields = filteredCortex.filter(
      (entry) => entry.bool_value === true
    );
    const otherFields = filteredCortex.filter(
      (entry) => entry.bool_value !== true
    );

    // Get the first playbook entry if it exists
    const playbookData = cortexPlaybook && cortexPlaybook.length > 0 ? cortexPlaybook[0] : null;

    return (
      <div>
        {/* Regular Cortex Configuration */}
        <div className="mb-4">
          <h4 className="border-bottom pb-2">CrowdStrike Configuration</h4>
          <ul className="list-unstyled">
            {otherFields.map((entry) => (
              <li key={entry.id} className="mb-2">
                <strong>{entry.fields}:</strong>{" "}
                {entry.string_value ||
                  (entry.bool_value !== undefined
                    ? entry.bool_value.toString()
                    : "")}
              </li>
            ))}
            {trueBoolFields.length > 0 && (
              <div className="mt-4">
                <h5>Boolean Fields</h5>
                {trueBoolFields.map((entry) => (
                  <li key={entry.id} className="mb-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked
                          readOnly
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 16 },
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: "0.875rem" }}>
                          {entry.fields}
                        </span>
                      }
                    />
                  </li>
                ))}
              </div>
            )}
          </ul>
          {testingStringRow && (
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#f8f9fa",
                borderRadius: "4px",
              }}
            >
              <strong>Testing String:</strong> {testingStringRow.string_value}
            </div>
          )}
        </div>

        {/* Cortex Playbook */}
        <div>
          <h4 className="border-bottom pb-2">CrowdStrike Playbook</h4>
          {!playbookData ? (
            <div>No CrowdStrike playbook available</div>
          ) : (
            <pre
              style={{
                backgroundColor: "#343a40",
                color: "#f8f9fa",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {playbookData.playbookText}
            </pre>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { rule, closeFunc } = this.props;
    const { activeTab, chartBase64, chartLoading } = this.state;
    
    // Create an array with all possible tabs
    const allTabs = [
      {
        id: "1",
        label: "Description",
        content: (
          <div className="p-3">
            {/* Rule name and header section */}
            <Row className="mb-4">
              <Col md="12">
                <h1 className="font-weight-bold mb-3">{rule.name}</h1>
                
                <div className="mb-3">
                  <h5 className="font-weight-bold mb-2">
                    MITRE ATT&CK: 
                    <a href={`https://attack.mitre.org/techniques/${rule.mitre ? rule.mitre.split(',')[0].trim() : ''}`} 
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="ml-2 text-primary">
                      {rule.mitre || "N/A"}
                    </a>
                  </h5>
                  <h5 className="font-weight-bold mb-0">
                    Creator: <span className="font-weight-normal">{rule.creator || "Red Team"}</span>
                  </h5>
                  
                  {/* Clients section (Implemented In) */}
                  <div className="mt-3">
                    <h5 className="font-weight-bold mb-2">Implemented In:</h5>
                    {this.state.clientsLoading ? (
                      <div className="d-flex align-items-center">
                        <i className="fas fa-spin fa-spinner mr-2" />
                        <span>Loading client data...</span>
                      </div>
                    ) : this.state.clients.length > 0 ? (
                      <div className="d-flex flex-wrap">
                        {this.state.clients.map((client, index) => (
                          <span key={index} className="badge badge-info m-1 p-2" style={{ fontSize: "14px" }}>
                            {client}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <p>This rule is not implemented in any client.</p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            
            {/* Rule description section with clear section headers */}
            <div className="mb-4">
              <h3 className="border-bottom pb-2 mb-3 font-weight-bold">Attack Description:</h3>
              <div style={{ whiteSpace: "pre-line", fontSize: "16px", lineHeight: "1.6" }} 
                   className="mb-4">
                {rule.ruleDescription}
              </div>
            </div>
            
            <div className="mb-4">
              <h3 className="border-bottom pb-2 mb-3 font-weight-bold">Rule Summary:</h3>
              <div style={{ whiteSpace: "pre-line", fontSize: "16px", lineHeight: "1.6" }} 
                   className="mb-4">
                {rule.ruleSummary}
              </div>
            </div>
            
            {/* Chart section with clear header */}
            {chartBase64 && (
              <div className="mb-4">
                <h3 className="border-bottom pb-2 mb-3 font-weight-bold">PNG:</h3>
                {chartLoading ? (
                  <div className="text-center py-5">
                    <i className="fas fa-spin fa-spinner fa-2x" />
                  </div>
                ) : (
                  <div className="text-center p-3" style={{ border: "2px solid #ccc", borderRadius: "5px" }}>
                    <img 
                      src={`data:image/png;base64,${chartBase64}`} 
                      alt="Rule Chart" 
                      className="img-fluid" 
                      style={{ maxWidth: "100%", maxHeight: "400px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ),
        hasData: true, // Description tab is always shown
      },
      {
        id: "2",
        label: "Sysmon",
        content: this.renderSysmonContent(
          rule.sysmon && rule.sysmon.length > 0 ? rule.sysmon[0] : null,
          rule.sysmonPlaybooks && rule.sysmonPlaybooks.length > 0 ? rule.sysmonPlaybooks[0] : null
        ),
        hasData: rule.sysmon && rule.sysmon.length > 0,
      },
      {
        id: "3",
        label: "CrowdStrike",
        content: this.renderCortexContent(
          rule.cortex,
          rule.cortexPlaybooks && rule.cortexPlaybooks.length > 0 ? rule.cortexPlaybooks : null
        ),
        hasData: rule.cortex && rule.cortex.length > 0,
      },
      {
        id: "4",
        label: "Splunk",
        content: this.renderSplunkContent(
          rule.splunk && rule.splunk.length > 0 ? rule.splunk[0] : null,
          rule.splunkPlaybooks && rule.splunkPlaybooks.length > 0 ? rule.splunkPlaybooks[0] : null
        ),
        hasData: rule.splunk && rule.splunk.length > 0,
      },
      {
        id: "5",
        label: "Qradar",
        content: this.renderQradarContent(
          rule.qradar && rule.qradar.length > 0 ? rule.qradar[0] : null,
          rule.qradarPlaybooks && rule.qradarPlaybooks.length > 0 ? rule.qradarPlaybooks[0] : null
        ),
        hasData: rule.qradar && rule.qradar.length > 0,
      },
      {
        id: "6",
        label: "Syslog",
        content: this.renderSyslogContent(
          rule.syslog && rule.syslog.length > 0 ? rule.syslog[0] : null,
          rule.syslogPlaybooks && rule.syslogPlaybooks.length > 0 ? rule.syslogPlaybooks[0] : null
        ),
        hasData: rule.syslog && rule.syslog.length > 0,
      }
    ];

    // Filter to only include tabs that have data
    const availableTabs = allTabs.filter(tab => tab.hasData);
    
    // If the active tab is no longer in the available tabs, switch to the first tab
    if (!availableTabs.find(tab => tab.id === activeTab) && availableTabs.length > 0) {
      setTimeout(() => {
        this.setState({ activeTab: availableTabs[0].id });
      }, 0);
    }

    return (
      <div className="mt-4">
        <Nav tabs>
          {availableTabs.map((tab) => (
            <NavItem key={tab.id}>
              <NavLink
                className={classnames({ active: activeTab === tab.id })}
                onClick={() => this.toggleTab(tab.id)}
              >
                {tab.label}
              </NavLink>
            </NavItem>
          ))}
          <NavItem>
            <NavLink onClick={closeFunc}>
              <i className="fas fa-times" />
            </NavLink>
          </NavItem>
        </Nav>
        <Card body className="pt-4 pb-4">
          <TabContent activeTab={activeTab}>
            {availableTabs.map((tab) => (
              <TabPane key={tab.id} tabId={tab.id}>
                {tab.content}
              </TabPane>
            ))}
          </TabContent>
        </Card>
      </div>
    );
  }
}

export default BlueRule;
