import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText
} from 'reactstrap';
import ReactSelect from 'react-select';  // <-- Import from react-select
import mammoth from 'mammoth';
import { v4 as uuidv4 } from 'uuid';
import sdk from 'api-sdk';
import { toast } from 'react-toastify';

const customStyles = {
  // Control the styles of the "selected" or currently visible value
  singleValue: (provided) => ({
    ...provided,
    color: '#000', // set to black or your desired color
  }),
  // Control the styles of the dropdown options list
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#eee' : '#fff',
    color: '#000', // ensure text is visible against the background
  }),
  // (Optional) Control the styles of the placeholder text
  placeholder: (provided) => ({
    ...provided,
    color: '#666', // or whatever color you want for the placeholder
  }),
};

const DocxParser = () => {
  const [scenarios, setScenarios] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [silverBullets, setSilverBullets] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [existingScenarios, setExistingScenarios] = useState([]);

  // Fetch initial data (SilverBullets + existing scenarios) on mount
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [bulletsResponse, scenariosResponse] = await Promise.all([
          sdk.silverBullets.get(),
          sdk.scenario.get()
        ]);
        setSilverBullets(bulletsResponse);
        setExistingScenarios(scenariosResponse);
      } catch (err) {
        console.error('Error loading initial data:', err);
        toast.error('Failed to load initial data');
      }
    };
    fetchInitialData();
  }, []);

  // Define known tactic types (adjust as needed)
  const tacticTypes = [
    'Lateral Movement',
    'Anti Forensics',
    'Defense Evasion',
    'Exfiltration',
    'Collection',
    'Credential Access'
  ];

  // Helper function to remove a tactic type from the beginning of a tactic string
  const cleanTactic = (text) => {
    for (const type of tacticTypes) {
      // Remove the tactic type if it appears at the very start,
      // possibly followed by a hyphen, colon, or spaces.
      const regex = new RegExp('^' + type + '[-–:]*\\s*', 'i');
      text = text.replace(regex, '');
    }
    return text;
  };

  /**
   * Extract scenarios from an HTML string (converted from .docx)
   * Adjust the logic here to match your docx structure and how you want to parse headings, etc.
   */
  const extractScenarios = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = Array.from(doc.body.children);

    const newScenarios = [];
    let currentScenario = null;
    let isInDescription = false;
    let isInTactic = false;
    const endHeaders = ['attack flow', 'proof of concept', 'mitigations'];

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const tagName = element.tagName.toLowerCase();
      const text = element.textContent.trim();
      const lowerText = text.toLowerCase();

      if (tagName.match(/h[1-3]/i) && lowerText.includes('scenario')) {
        // If we were building a scenario previously, push it before starting a new one
        if (currentScenario) {
          if (currentScenario.tactic) {
            currentScenario.tactic = cleanTactic(currentScenario.tactic);
          }
          newScenarios.push(currentScenario);
        }

        currentScenario = {
          id: uuidv4(),
          name: text.replace(/Scenario \d+\s*[-–]+\s*/i, ''),
          difficulty: '',
          impact: '',
          tactic: '',
          description: '',
          attackFlowImage: null,
          selectedSilverBullet: null
        };
        isInDescription = false;
        isInTactic = false;
      } else if (currentScenario) {

        const sectionEndHeaders = [
          'description of the attack',
          'attack flow',
          'proof of concept',
          'mitigations',
        ];

        // When a Tactic header is found:
        if (/^tactic\s*[-–]+/i.test(lowerText)) {
          let tacticContent = text.replace(/^Tactic\s*[-–]+\s*/i, '').trim();
          // Insert a missing space between a lowercase letter followed by an uppercase letter
          // (e.g. "MovementThis" becomes "Movement This")
          tacticContent = tacticContent.replace(/([a-z])([A-Z])/g, '$1 $2');

          // Split into words to remove the tactic type (assumed to be the initial title)
          const words = tacticContent.split(/\s+/);
          // Define trigger words that indicate the start of the paragraph
          const triggerWords = ['This', 'Adversaries'];

          // If the first word is already a trigger, then nothing needs to be removed
          let startIndex = 0;
          if (words.length > 0 && !triggerWords.includes(words[0])) {
            // Find the first occurrence of a trigger word and mark that index as the start
            for (let i = 0; i < words.length; i++) {
              if (triggerWords.includes(words[i])) {
                startIndex = i;
                break;
              }
            }
          }

          // If a trigger word was found (and it isn't the first word), use the remaining words
          if (startIndex > 0) {
            currentScenario.tactic = words.slice(startIndex).join(' ');
          } else {
            currentScenario.tactic = tacticContent;
          }
          isInTactic = true; // Start collecting subsequent text
        }
        // Continue collecting text until a new section starts
        else if (isInTactic) {
          const isEndOfSection = sectionEndHeaders.some(header =>
            lowerText.startsWith(header)
          );

          if (isEndOfSection) {
            isInTactic = false; // Stop collecting
          } else {
            // Append the text to the tactic field
            currentScenario.tactic += ' ' + text.trim();
          }
        }


        // Example: matching "Description of the attack..."
        if (lowerText.includes('description of the attack')) {
          const descriptionPart = text.split(/description of the attack[\s:-]*/i)[1] || '';
          currentScenario.description = descriptionPart.trim();
          isInDescription = true;
        } else if (isInDescription) {
          const isEndHeader = endHeaders.some((header) => lowerText.startsWith(header));
          if (isEndHeader) {
            isInDescription = false;
          } else {
            currentScenario.description += ' ' + text;
          }
        } else if (lowerText.startsWith('difficulty realizing the threat:')) {
          currentScenario.difficulty = text
            .replace('Difficulty realizing the threat:', '')
            .trim();
        } else if (lowerText.startsWith('level of impact:')) {
          currentScenario.impact = text.replace('Level of impact:', '').trim();
        }

        // Example: searching for an image after encountering "attack flow"
        if (lowerText.includes('attack flow')) {
          currentScenario.attackFlowImage = null;

          // 1. First check if image exists in the same element
          const currentImg = element.querySelector('img');
          if (currentImg) {
            currentScenario.attackFlowImage = currentImg.getAttribute('src');
            continue;
          }

          // 2. Look ahead more aggressively for images
          for (let j = i + 1; j < elements.length; j++) {
            const nextEl = elements[j];
            const nextText = nextEl.textContent.trim().toLowerCase();

            // Stop at ANY header or next scenario
            if (nextEl.tagName.match(/h[1-4]/i) || nextText.includes('scenario')) {
              break;
            }

            // Check all images in this element
            const imgs = nextEl.querySelectorAll('img');
            if (imgs.length > 0) {
              // Take first image that contains "attack" in filename (if possible)
              const attackImage = Array.from(imgs).find(img =>
                (img.getAttribute('src') || '').toLowerCase().includes('attack')
              );

              currentScenario.attackFlowImage = (attackImage ? attackImage.getAttribute('src') : null) || (imgs[0] ? imgs[0].getAttribute('src') : null);
              break;
            }
          }
        }
      }
    }

    // If a scenario is being built at the end, push it
    if (currentScenario) newScenarios.push(currentScenario);

    // Clean up descriptions (spaces, etc.)
    newScenarios.forEach((scenario) => {
      scenario.description = scenario.description.replace(/\s+/g, ' ').trim();
    });

    return newScenarios;
  };

  /**
   * Handle user uploading a .docx file
   */
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsLoading(true);
    setError('');
    setScenarios([]);

    try {
      // Convert the docx to HTML using mammoth
      const result = await mammoth.convertToHtml(
        { arrayBuffer: await file.arrayBuffer() },
        {
          includeEmbeddedStyleMap: true,
          convertImage: mammoth.images.imgElement((image) => {
            return image.read('base64').then((content) => ({
              src: `data:${image.contentType};base64,${content}`,
              contentType: image.contentType,
              content: content
            }));
          })
        }
      );

      // Parse the returned HTML for scenario data
      const extractedScenarios = extractScenarios(result.value);

      // Attempt to match a scenario to a silverBullet by name (optional)
      const mappedScenarios = extractedScenarios.map((scenario) => ({
        ...scenario,
        selectedSilverBullet: silverBullets.find((b) => b.name === scenario.name) || null
      }));

      setScenarios(mappedScenarios);
    } catch (err) {
      setError('Error parsing document. Please check the file format.');
      console.error('DOCX parsing error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle user selecting a silver bullet from the dropdown
   */
  const handleSilverBulletSelect = (scenarioId, bulletId) => {
    const parsedId = parseInt(bulletId, 10);
    setScenarios((prev) =>
      prev.map((scenario) => {
        if (scenario.id === scenarioId) {
          const selectedSilverBullet = silverBullets.find((b) => b.id === parsedId);
          return {
            ...scenario,
            selectedSilverBullet,
            name: selectedSilverBullet ? selectedSilverBullet.name : scenario.name
          };
        }
        return scenario;
      })
    );
  };

  /**
   * Convert dataURL (base64 image) to a File
   */
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  /**
   * Create each scenario in your API
   */
  const createScenariosFromDoc = async () => {
    setIsCreating(true);
    try {
      let createdCount = 0;
      let skippedCount = 0;

      for (const scenario of scenarios) {
        // Skip scenarios without a SilverBullet connection
        if (!scenario.selectedSilverBullet) {
          skippedCount++;
          continue;
        }

        try {
          // Check for existing scenario
          const alreadyExists = existingScenarios.some(
            (s) => s.silverBulletId === scenario.selectedSilverBullet.id
          );

          if (alreadyExists) {
            toast.info(`Skipping "${scenario.name}" - scenario already exists`);
            skippedCount++;
            continue;
          }

          // Create scenario payload
          const scenarioData = {
            silverBulletId: scenario.selectedSilverBullet.id,
            silverBulletName: scenario.selectedSilverBullet.name,
            scenarioDifficulty: scenario.difficulty,
            scenarioImpact: scenario.impact,
            tactic: scenario.tactic,
            description: scenario.description,
            status: 'Pending'
          };

          // API call to create scenario
          await sdk.scenario.create(scenarioData);

          // Upload attack flow image if exists
          if (scenario.attackFlowImage) {
            const imageFile = dataURLtoFile(
              scenario.attackFlowImage,
              `${scenario.name}_lightchart.png`
            );
            await sdk.silverBullets.uploadFile(
              imageFile,
              scenario.selectedSilverBullet.id,
              'lightchart'
            );
          }

          createdCount++;
        } catch (error) {
          console.error(`Error creating scenario ${scenario.name}:`, error);
          toast.error(`Failed to create "${scenario.name}": ${error.message}`);
        }
      }

      // Show summary notification
      toast.success(
        `Successfully created ${createdCount} scenario(s)! ${skippedCount > 0 ? `${skippedCount} were skipped.` : ''
        }`
      );

      // Refresh existing scenarios list
      const updatedScenarios = await sdk.scenario.get();
      setExistingScenarios(updatedScenarios);

    } catch (error) {
      console.error('Global error in scenario creation:', error);
      toast.error(
        (error.response && error.response.data && error.response.data.message) || 'An unexpected error occurred'
      );
    } finally {
      setIsCreating(false);
    }
  };

  // Check if *every* scenario has a selected silverBullet
  const readyCount = scenarios.filter((s) => s.selectedSilverBullet !== null).length;
  const hasReadyScenarios = readyCount > 0;

  const readyScenarios = scenarios.filter(
    (scenario) =>
      scenario.selectedSilverBullet &&
      !existingScenarios.some((s) => s.silverBulletId === scenario.selectedSilverBullet.id)
  );

  const existingDocScenarios = scenarios.filter(
    (scenario) =>
      scenario.selectedSilverBullet &&
      existingScenarios.some((s) => s.silverBulletId === scenario.selectedSilverBullet.id)
  );

  const notMappedScenarios = scenarios.filter(
    (scenario) => !scenario.selectedSilverBullet
  );

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow-sm border-0">
            <CardBody>
              <CardTitle tag="h4" className="text-center mb-4">
                DOCX Scenario Parser
              </CardTitle>

              {/* File Upload */}
              <FormGroup>
                <Label for="docxUpload">Upload Report DOCX</Label>
                <Input
                  type="file"
                  id="docxUpload"
                  accept=".docx"
                  onChange={handleFileUpload}
                  disabled={isLoading}
                />
                {error && <div className="text-danger mt-2">{error}</div>}
              </FormGroup>

              {/* Scenario Summary Section */}
              {scenarios.length > 0 && (
                <Row className="mb-4">
                  {/* Not Mapped Scenarios */}
                  <Col md={4}>
                    <Card className="border-danger mb-3">
                      <CardBody>
                        <CardTitle tag="h5" className="text-danger">
                          Not Mapped ({notMappedScenarios.length})
                        </CardTitle>
                        {notMappedScenarios.length > 0 ? (
                          <ul className="list-unstyled mb-0">
                            {notMappedScenarios.map((scenario) => (
                              <li key={scenario.id} className="text-truncate">
                                ✗ {scenario.name}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-muted">All scenarios mapped</div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>

                  {/* Ready to Create */}
                  <Col md={4}>
                    <Card className="border-success mb-3">
                      <CardBody>
                        <CardTitle tag="h5" className="text-success">
                          Ready to Create ({readyScenarios.length})
                        </CardTitle>
                        {readyScenarios.length > 0 ? (
                          <ul className="list-unstyled mb-0">
                            {readyScenarios.map((scenario) => (
                              <li key={scenario.id} className="text-truncate">
                                ✓ {scenario.selectedSilverBullet.name}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-muted">No scenarios ready for creation</div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>

                  {/* Existing Scenarios */}
                  <Col md={4}>
                    <Card className="border-warning mb-3">
                      <CardBody>
                        <CardTitle tag="h5" className="text-warning">
                          Existing ({existingDocScenarios.length})
                        </CardTitle>
                        {existingDocScenarios.length > 0 ? (
                          <ul className="list-unstyled mb-0">
                            {existingDocScenarios.map((scenario) => (
                              <li key={scenario.id} className="text-truncate">
                                ⚠ {scenario.selectedSilverBullet.name}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-muted">No existing scenarios</div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}

              {/* Scenarios List */}
              {scenarios.length > 0 && (
                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Button
                      color="success"
                      onClick={createScenariosFromDoc}
                      disabled={isCreating || !hasReadyScenarios}
                    >
                      {isCreating ? 'Creating...' : 'Create All Scenarios'}
                    </Button>
                    <div className="text-muted">
                      {readyCount} of {scenarios.length} scenarios ready
                    </div>
                  </div>

                  {/* Render each scenario */}
                  <Row className="g-0">
                    {scenarios.map((scenario) => {
                      const scenarioExists = existingScenarios.some(
                        (s) =>
                          s.silverBulletId ===
                          (scenario.selectedSilverBullet &&
                            scenario.selectedSilverBullet.id)
                      );

                      // Prepare the options for ReactSelect
                      const silverBulletOptions = silverBullets.map((bullet) => ({
                        value: bullet.id,
                        label: bullet.name
                      }));

                      // If scenario already has a bullet selected
                      const selectedOption = scenario.selectedSilverBullet
                        ? {
                          value: scenario.selectedSilverBullet.id,
                          label: scenario.selectedSilverBullet.name
                        }
                        : null;

                      return (
                        <Card key={scenario.id} className="mb-3">
                          <CardBody className="py-3">
                            <div
                              className={`mb-3 ${scenarioExists
                                ? 'text-warning'
                                : scenario.selectedSilverBullet
                                  ? 'text-success'
                                  : 'text-danger'
                                }`}
                            >
                              <strong>
                                {scenarioExists
                                  ? '⚠ Already exists'
                                  : scenario.selectedSilverBullet
                                    ? '✓ Ready to create'
                                    : '✗ No SilverBullet selected'}
                              </strong>
                            </div>

                            {/* Let users pick or change the bullet, as long as it doesn't already exist */}
                            {!scenarioExists && (
                              <FormGroup>
                                <Label for={`silverBulletSelect-${scenario.id}`}>
                                  SilverBullet for "{scenario.name}"
                                </Label>
                                <ReactSelect
                                  id={`silverBulletSelect-${scenario.id}`}
                                  placeholder="Search or select a SilverBullet..."
                                  value={selectedOption}
                                  options={silverBulletOptions}
                                  onChange={(option) =>
                                    handleSilverBulletSelect(scenario.id, option.value)
                                  }
                                  styles={customStyles}
                                />
                              </FormGroup>
                            )}

                            <CardTitle tag="h5">
                              {scenario.selectedSilverBullet
                                ? scenario.selectedSilverBullet.name
                                : scenario.name}
                            </CardTitle>

                            <CardText>
                              <p className="mb-2">
                                <strong>Difficulty:</strong> {scenario.difficulty}
                              </p>
                              <p className="mb-2">
                                <strong>Impact Level:</strong> {scenario.impact}
                              </p>
                              <p className="mb-2">
                                <strong>Tactic:</strong> {scenario.tactic}
                              </p>
                              <p className="mb-2">
                                <strong>Description:</strong> {scenario.description}
                              </p>
                            </CardText>


                            {/* Show the Attack Flow image if present */}
                            {scenario.attackFlowImage && (
                              <div className="mt-3">
                                <strong>Attack Flow:</strong>
                                <img
                                  src={scenario.attackFlowImage}
                                  alt="Attack flow diagram"
                                  className="img-fluid mt-2"
                                  style={{ maxHeight: '300px' }}
                                />
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      );
                    })}
                  </Row>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DocxParser;
