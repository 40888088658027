import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

class AddQradar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qradarText: props.qradarText || "",
      prevPropsQradarText: props.qradarText || ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Only update state from props if the prop actually changed
    if (nextProps.qradarText !== prevState.prevPropsQradarText) {
      return {
        qradarText: nextProps.qradarText || "",
        prevPropsQradarText: nextProps.qradarText
      };
    }
    return null; // No state update needed
  }

  handleChange(e) {
    const qradarText = e.target.value;
    this.setState({ qradarText }, () => {
      if (this.props.onQradarChange) {
        this.props.onQradarChange(qradarText);
      }
    });
  }

  render() {
    return (
      <div>
        <FormGroup>
          <Label for="qradarXml">Enter Qradar:</Label>
          <Input
            type="textarea"
            id="qradarXml"
            rows="10"
            value={this.state.qradarText}
            onChange={this.handleChange}
          />
        </FormGroup>
      </div>
    );
  }
}

export default AddQradar; 