import React from 'react';
import { Row, Col } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import BlueRuleTable from '../BlueRuleTable/BlueRuleTable';
import BlueRuleCreation from '../BlueRuleCreation/BlueRuleCreation';
import BlueRuleEdit from '../BlueRuleCreation/BlueRuleEdit';

class BlueRuleSuites extends React.Component {
  render() {
    const match = this.props.match;
    return (
      <Switch>
        <Route 
          path={`${match.path}/create`} 
          exact 
          render={props => (
            <Row>
              <Col sm="12">
                <BlueRuleCreation {...props}/>
              </Col>
            </Row>
          )} 
        />
        <Route 
          path={`${match.path}/edit/:id`} 
          exact 
          render={props => (
            <Row>
              <Col sm="12">
                <BlueRuleEdit {...props}/>
              </Col>
            </Row>
          )} 
        />
        <Route 
          path="" 
          exact 
          render={props => (
            <Row>
              <Col sm="12">
                <BlueRuleTable {...props}/>
              </Col>
            </Row>
          )} 
        />
      </Switch>
    );
  }
}

export default BlueRuleSuites;
