// Import Statements
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Save as SaveIcon } from '@mui/icons-material';
import sdk from 'api-sdk';
import ScenarioCard from '../reportForge/scenarioCard';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ImageModule from 'docxtemplater-image-module-free';
import { saveAs } from 'file-saver';
import englishTemplate from './englishTemplateNoStatus.docx';
import SearchBar from '../../components/inputs/searchBar';
import { debounce } from 'lodash';
import EditScenario from '../EditScenario/EditScenario';
import attackVectors from '../../assets/data/attackVectors.json';


const getAttackVectorColor = (vector) => {
  const match = attackVectors.find(item => item.title === vector);
  return match ? match.hexColor : '#B771E5'; // fallback color
};

// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px',
}));

// Custom Styled Tabs
const CustomTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    backgroundColor: '#A294F9',
    height: '4px',
    borderRadius: '2px',
  },
}));

// Custom Styled Tab
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 120,
    fontWeight: 600,
    fontSize: '1rem',
    marginRight: theme.spacing(1),
    color: '#B771E5',
    '&.Mui-selected': {
      color: '#B771E5',
      boxShadow: 'none', // Remove box shadow on focus
      outline: 'none', // Remove outline on focus
    },
    '&:hover': {
      color: '#7E5CAD',
      opacity: 1,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent', // Remove background color on focus
      boxShadow: 'none', // Remove box shadow on focus
      outline: 'none', // Remove outline on focus
    },
  })
);

// Main Component
const AllScenarios = () => {
  // State Variables
  const [scenarios, setScenarios] = useState([]);
  const [selectedScenarios, setSelectedScenarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setPayloads] = useState([]);
  const [, setScenariosWithImages] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [activeTab, setActiveTab] = useState('full');
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [attackVectorFilter, setAttackVectorFilter] = useState(null);

  // Debounced Search Handler
  const debouncedSetSearchFilter = useMemo(() => debounce(setSearchFilter, 300), []);

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSetSearchFilter.cancel();
    };
  }, [debouncedSetSearchFilter]);

  const attackVectors = useMemo(() => {
    // Build a set of unique attackVectors from the scenario list
    const vectors = new Set();
    scenarios.forEach((scenario) => {
      // ensure scenario.silverBullet is present
      if (scenario.silverBullet && scenario.silverBullet.attackVector) {
        vectors.add(scenario.silverBullet.attackVector);
      }
    });
    return Array.from(vectors);
  }, [scenarios]);

  // Check if a scenario has all required fields filled
  const isScenarioComplete = (scenario) => {
    const requiredFields = [
      'silverBulletId',
      'silverBulletName',
      'scenarioDifficulty',
      'scenarioImpact',
      'tactic',
      'description',
      'status',
    ];

    return requiredFields.every((field) => {
      const value = scenario[field];
      return value !== undefined && value !== null && value !== '';
    });
  };

  // Split scenarios into complete and incomplete
  const [fullScenarios, partialScenarios] = useMemo(() => {
    const full = [];
    const partial = [];

    scenarios.forEach((scenario) => {
      if (isScenarioComplete(scenario)) {
        full.push(scenario);
      } else {
        partial.push(scenario);
      }
    });

    return [full, partial];
  }, [scenarios]);

  // Filter scenarios based on active tab and search
  const filteredScenarios = useMemo(() => {
    // Start with whichever subset is active (full vs partial)
    const currentScenarios = activeTab === 'full' ? fullScenarios : partialScenarios;

    // Filter by search (silverBulletName)
    let result = currentScenarios;
    if (searchFilter) {
      const lowerCaseQuery = searchFilter.toLowerCase();
      result = result.filter((scenario) =>
        scenario.silverBulletName.toLowerCase().includes(lowerCaseQuery)
      );
    }

    // Filter by the selected attackVector if any
    if (attackVectorFilter) {
      result = result.filter(
        (scenario) =>
          scenario.silverBullet && scenario.silverBullet.attackVector === attackVectorFilter
      );
    }

    return result;
  }, [activeTab, fullScenarios, partialScenarios, searchFilter, attackVectorFilter]);


  // Fetch Scenarios on Mount
  useEffect(() => {
    let isMounted = true;

    const fetchAllScenarios = async () => {
      if (!isMounted) return;
      setLoading(true);
      try {
        // IMPORTANT: include silverBullet so we can access attackVector
        const response = await sdk.scenario.get({ include: ['silverBullet'] });

        if (isMounted && response) {
          setScenarios(response);
        }
      } catch (error) {
        console.error('Error fetching scenarios:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllScenarios();
    return () => {
      isMounted = false;
    };
  }, []);


  const handleSearchChange = (e) => {
    const query = e.target.value;
    debouncedSetSearchFilter(query);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const generateReport = async () => {
    if (selectedScenarios.length === 0) {
      alert('Please select at least one scenario to generate the report.');
      return;
    }

    setLoading(true);
    try {
      let allPayloads = [];
      const scenariosForReport = [];

      // Process all selected scenarios
      for (const scenario of selectedScenarios) {
        // Fetch payloads for the current scenario
        const payloadsForScenario = await sdk.silverBullets.getPayloads(scenario.silverBulletId);

        // Take only one payload per scenario - the first one
        const uniquePayload = payloadsForScenario.length > 0 ? payloadsForScenario[0] : null;

        if (uniquePayload) {
          // Fetch run history to get category information
          const allRunHistories = await sdk.runHistory.getAll();
          
          // Filter runs for this payload's silverBulletId
          const relevantRuns = allRunHistories.filter(
            run => run.bulletId === uniquePayload.silverBulletId
          );
          
          // Get the latest run to extract category
          const latestRun = relevantRuns.length > 0
            ? relevantRuns.sort((a, b) => new Date(b.runDate) - new Date(a.runDate))[0]
            : null;
            
          // Get category name
          const categoryName = latestRun && latestRun.categoryName ? latestRun.categoryName : 'No category';
          
          // Process the single payload
          const processedPayload = {
            details: uniquePayload.details || '',
            fileFormat: uniquePayload.fileFormat,
            scenarioName: scenario.silverBulletName,
            silverBulletId: uniquePayload.silverBulletId,
            categoryName
          };

          // Add to cumulative payloads list
          allPayloads = [...allPayloads, processedPayload];

          // Get defense recommendations
          const defRuleResponse = await sdk.silverBullets.getDefRuleIds(scenario.silverBulletId);
          const defRuleIds = defRuleResponse.defRuleIds || [];

          const recommendations = await Promise.all(
            defRuleIds.map(async (defRuleId) => {
              try {
                const defRuleText = await sdk.defRules.getDefRuleText(defRuleId);
                const mitigations = Array.isArray(defRuleText.mitigations)
                  ? defRuleText.mitigations
                  : [defRuleText.mitigations].filter(Boolean);

                let paragraph = 'No paragraph available';
                if (mitigations.length > 0) {
                  try {
                    const paraResponse = await sdk.defRules.getParagraphByTitle(
                      defRuleId,
                      'Details of the attack'
                    );
                    paragraph = paraResponse.paragraph || paragraph;
                  } catch (paraError) {
                    console.error('Paragraph fetch error:', paraError);
                  }
                }

                return {
                  systemName: defRuleText.systemName || 'Unknown System',
                  mitigations,
                  paragraph
                };
              } catch (defRuleError) {
                console.error('Defense rule fetch error:', defRuleError);
                return {
                  systemName: 'Error loading system',
                  mitigations: ['Failed to load mitigation details'],
                  paragraph: 'Error loading defense rule information'
                };
              }
            })
          );

          scenariosForReport.push({
            ...scenario,
            payloads: [processedPayload],
            recommendations: recommendations.filter(r => r.mitigations.length > 0)
          });
        }
      }

      // Add chart images to scenarios
      const scenariosWithImagesData = await Promise.all(
        scenariosForReport.map(async (scenario) => {
          try {
            const { base64Chart } = await sdk.silverBullets.getChartBase64(scenario.silverBulletId);
            return { ...scenario, chartImage: base64Chart };
          } catch (chartError) {
            console.error('Chart fetch error:', chartError);
            return { ...scenario, chartImage: null };
          }
        })
      );

      // Sort payloads alphabetically by scenario name
      allPayloads.sort((a, b) => a.scenarioName.localeCompare(b.scenarioName));

      // Add sequential row numbers
      const numberedPayloads = allPayloads.map((payload, index) => ({
        ...payload,
        rowNumber: index + 1
      }));

      // Update state
      setPayloads(numberedPayloads);
      setScenariosWithImages(scenariosWithImagesData);

      // Generate DOCX report
      const templateResponse = await fetch(englishTemplate);
      const templateArrayBuffer = await templateResponse.arrayBuffer();
      const zip = new PizZip(templateArrayBuffer);

      const doc = new Docxtemplater()
        .attachModule(
          new ImageModule({
            centered: true,
            getImage: (tagValue) =>
              new Uint8Array(
                atob(tagValue)
                  .split('')
                  .map((char) => char.charCodeAt(0))
              ),
            getSize: () => [616, 148],
          })
        )
        .loadZip(zip);

      const formattedDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      doc.setData({
        runNumber: '001',
        date: formattedDate,
        scenarios: scenariosWithImagesData.map((scenario, index) => ({
          scenarioNumber: index + 1,
          scenarioTitle: scenario.silverBulletName,
          description: scenario.description,
          tactic: scenario.tactic,
          attackFlow: [{ imageTag: scenario.chartImage }],
          recommendations: scenario.recommendations,
          isH: scenario.scenarioDifficulty === 'High',
          isM: scenario.scenarioDifficulty === 'Medium',
          isL: scenario.scenarioDifficulty === 'Low',
          ImpactH: scenario.scenarioImpact === 'High',
          ImpactM: scenario.scenarioImpact === 'Medium',
          ImpactL: scenario.scenarioImpact === 'Low',
        })),
        payloads: numberedPayloads,
        firstDefenceRules: 'Not Available'
      });

      doc.render();

      const output = doc.getZip().generate({ type: 'blob' });
      saveAs(output, `Scenario_Report_${formattedDate.replace(/,/g, '')}.docx`);
    } catch (error) {
      console.error('Error generating report:', error);
      alert('Failed to generate report. Please check the console for details.');
    } finally {
      setLoading(false);
    }
  };



  return (
    <StyledContainer maxWidth="lg">
      {/* Header Section */}
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardHeader
              title={
                <Header variant="h5" align="center">
                  Scenario Report
                </Header>
              }
              sx={{
                color: 'primary.contrastText',
                backgroundColor: '#5a4f7c',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            />
            <CardContent>
              <StyledButton
                variant="contained"
                color="success"
                fullWidth
                startIcon={<SaveIcon />}
                onClick={generateReport}
                disabled={selectedScenarios.length === 0 || loading}
              >
                Generate Report ({selectedScenarios.length} selected)
              </StyledButton>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      {/* Search and Tabs Section */}
      <Grid container spacing={4} mt={4}>
        <Grid item xs={12}>
          <Box mb={3}>
            <SearchBar
              fullWidth
              placeholder="Search Scenarios..."
              onChange={handleSearchChange}
              isLoading={loading}
            />
          </Box>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            marginTop: 2, 
            marginBottom: 5,
            backgroundColor: '#343a40',
            padding: 3,
            borderRadius: 2,
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            border: '1px solid #495057'
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                marginBottom: 2,
                color: '#adb5bd',
                fontWeight: 600
              }}
            >
              Attack Vector Filter
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap',
              gap: 1,
              '& > button': {
                minWidth: '120px',
                height: '36px',
                fontSize: '0.875rem',
                textTransform: 'none',
                borderRadius: '18px',
                transition: 'all 0.2s ease-in-out',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: '0 16px',
                fontWeight: 500,
                border: '1px solid',
                '&.MuiButton-outlined': {
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }
              }
            }}>
              {attackVectors.map((vector) => {
                const buttonColor = getAttackVectorColor(vector);
                const isSelected = attackVectorFilter === vector;

                return (
                  <Button
                    key={vector}
                    variant={isSelected ? 'contained' : 'outlined'}
                    onClick={() =>
                      setAttackVectorFilter(prev => (prev === vector ? null : vector))
                    }
                    sx={{
                      color: isSelected ? '#fff' : buttonColor,
                      backgroundColor: isSelected ? buttonColor : 'transparent',
                      borderColor: isSelected ? buttonColor : buttonColor,
                      '&:hover': {
                        backgroundColor: isSelected ? buttonColor : 'transparent',
                        borderColor: buttonColor,
                        transform: 'translateY(-1px)',
                        boxShadow: `0 2px 4px ${buttonColor}40`
                      },
                      '&:active': {
                        transform: 'translateY(0)'
                      },
                      '&.MuiButton-root': {
                        '&:focus': {
                          outline: 'none',
                          boxShadow: 'none'
                        }
                      }
                    }}
                  >
                    {vector}
                  </Button>
                );
              })}
            </Box>
          </Box>

          <CustomTabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            aria-label="Scenario Tabs"
          >
            <CustomTab
              label={`Complete Scenarios (${fullScenarios.length})`}
              value="full"
              id="tab-full"
              aria-controls="tabpanel-full"
            />
            <CustomTab
              label={`Incomplete Scenarios (${partialScenarios.length})`}
              value="partial"
              id="tab-partial"
              aria-controls="tabpanel-partial"
            />
          </CustomTabs>

          {/* Tab Content */}
          {loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <>
              <Box mt={3}>
                {filteredScenarios.length === 0 ? (
                  <Typography variant="h6" align="center" color="textSecondary">
                    No {activeTab === 'full' ? 'complete' : 'incomplete'} scenarios found.
                  </Typography>
                ) : (
                  <Grid container spacing={4}>
                    {filteredScenarios.map((scenario) => (
                      <Grid item xs={12} sm={6} md={4} key={scenario.silverBulletId}>
                        <ScenarioCard
                          scenario={scenario}
                          onEdit={(scenario) => {
                            setSelectedScenario(scenario);
                            setShowEditModal(true);
                          }}
                          isSelected={selectedScenarios.some(
                            (s) => s.silverBulletId === scenario.silverBulletId
                          )}
                          onSelect={(clickedScenario) =>
                            setSelectedScenarios((prev) =>
                              prev.some(
                                (s) => s.silverBulletId === clickedScenario.silverBulletId
                              )
                                ? prev.filter(
                                  (s) => s.silverBulletId !== clickedScenario.silverBulletId
                                )
                                : [...prev, clickedScenario]
                            )
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      {/* Edit Scenario Modal */}
      <Dialog
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
          setSelectedScenario(null);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Edit Scenario</DialogTitle>
        <DialogContent>
          {selectedScenario && (
            <EditScenario
              scenario={selectedScenario}
              onSave={() => {
                const fetchAllScenarios = async () => {
                  setLoading(true);
                  try {
                    const response = await sdk.scenario.get({});
                    setScenarios(response || []);
                  } catch (error) {
                    console.error('Error fetching scenarios:', error);
                  } finally {
                    setLoading(false);
                  }
                };
                fetchAllScenarios();
                setShowEditModal(false);
                setSelectedScenario(null);
              }}
              onClose={() => {
                setShowEditModal(false);
                setSelectedScenario(null);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowEditModal(false);
              setSelectedScenario(null);
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default AllScenarios;
