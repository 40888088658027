// HtmlViewer.js
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import sdk from 'api-sdk';
import './bulletDocument.css';

class HtmlViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            htmlString: '',
            loading: true,
            error: null,
        };

        this.printDoc = this.printDoc.bind(this);
        this.loadDocument = this.loadDocument.bind(this);
    }

    /**
     * Fetches the document based on docName and silverBulletId.
     */
    loadDocument() {
        const { docName, silverBulletId } = this.props;

        // Verify silver bullet ID is provided
        if (!silverBulletId) {
            this.setState({ error: 'Silver Bullet ID is required for this document.', loading: false });
            return;
        }
        
        // Fetch document from silver bullets API
        sdk.silverBullets.getDocument(silverBulletId, docName)
            .then(str => {
                if (!str || !str.trim()) {
                    throw new Error(`Document content for ${docName} is empty.`);
                }

                // Replace placeholder if needed
                if (str.includes('"image-source"')) {
                    return sdk.silverBullets.getChartLink(silverBulletId).then(url => {
                        const updatedStr = str.replace('"image-source"', `"${url}"`);
                        return updatedStr;
                    });
                }
                return str;
            })
            .then(htmlString => this.setState({ htmlString, loading: false }))
            .catch(err => {
                console.error(`Failed to load document: ${docName}`, err);
                this.setState({ error: err.message || 'Failed to load document.', loading: false });
            });
    }


    /**
     * Handles printing of the document.
     */
    printDoc() {
        const { docName } = this.props;
        const content = document.getElementById(`${docName}-doc`).innerHTML;
        const printWindow = window.open('', 'PrintWindow', 'width=800,height=600');
        printWindow.document.open();
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print ${docName.charAt(0).toUpperCase() + docName.slice(1)}</title>
                    <link rel="stylesheet" href="path-to-your-styles.css">
                </head>
                <body>
                    ${content}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    componentDidMount() {
        this.loadDocument();
    }

    componentDidUpdate(prevProps) {
        const { silverBulletId, docName } = this.props;
        const { silverBulletId: prevSilverBulletId, docName: prevDocName } = prevProps;

        // Reload the document if any of the relevant props have changed
        if (
            silverBulletId !== prevSilverBulletId ||
            docName !== prevDocName
        ) {
            this.setState({ htmlString: '', loading: true, error: null }, this.loadDocument);
        }
    }

    /**
     * Determines the appropriate icon based on docName.
     */
    getIconName() {
        const { docName } = this.props;
        switch (docName) {
            case 'description':
                return 'mdi mdi-note-outline';
            case 'intel':
                return 'mdi mdi-eye-outline';
            case 'instructions':
                return 'mdi mdi-clipboard-text';
            default:
                return 'mdi mdi-file-document';
        }
    }

    render() {
        const { docName } = this.props;
        const { htmlString, loading, error } = this.state;

        if (loading) {
            return (
                <div className="text-center my-4">
                    <i className="fas fa-spin fa-spinner fa-2x" />
                </div>
            );
        }

        if (error) {
            return <div className="text-danger text-center my-4">{error}</div>;
        }

        return (
            <div className={`bullet-document ${docName}-document`}>
                <PerfectScrollbar>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div>
                            <h2>
                                <i className={this.getIconName()} /> {docName.charAt(0).toUpperCase() + docName.slice(1)}
                            </h2>
                        </div>
                        <div>
                            <Button
                                size="sm"
                                className="btn-rounded btn-outline-secondary"
                                onClick={this.printDoc}
                            >
                                <i className="mdi mdi-printer" /> Print {docName.charAt(0).toUpperCase() + docName.slice(1)}
                            </Button>
                        </div>
                    </div>
                    <div id={`${docName}-doc`} dangerouslySetInnerHTML={{ __html: htmlString }} />
                </PerfectScrollbar>
            </div>
        );
    }
}

HtmlViewer.propTypes = {
    silverBulletId: PropTypes.string.isRequired, // Required for all documents
    docName: PropTypes.string.isRequired, // 'description', 'intel', 'instructions'
};

export default HtmlViewer;
