// cortexConfig.js

/**
 * cortexConfig is an object describing each "cortex type":
 * - "Custom IOA Rule" (CrowdStrike),
 * - "File Vantage Rules",
 * - "NextGen Siem Correlation rule".
 *
 * For "Custom IOA Rule", we do NOT use subRules anymore. Instead, we'll display
 * a dynamic builder in AddCortex.jsx. So here we basically leave it empty or
 * mark it as dynamic so we know not to show old fields logic.
 */
export const cortexConfig = {
    "Custom IOA Rule": {
      // We won’t define `subRules` or `fields` here, as we handle it dynamically in AddCortex.jsx
      dynamic: true
    },
  
    "File Vantage Rules": {
      subRules: {
        "File Rule": {
          fields: [
            { label: "Rule Path", type: "string" },
            { label: "Depth", type: "string" },
            { label: "Severity", type: "string" },
            { label: "Description (optional)", type: "string" },
            {
              label: "Directory",
              type: "select",
              options: ["All", "Create", "Delete", "Rename", "Permissions", "Attributes"]
            },
            {
              label: "File",
              type: "select",
              options: ["All", "Create", "Write", "Delete", "Rename", "Permissions", "Attributes"]
            },
            {
              label: "Monitor changes in Files and folders",
              type: "select",
              options: ["include all", "include only"]
            },
            {
              label: "Processes",
              type: "select",
              options: ["include all", "include only"]
            },
            { label: "Allow Crowdstrike to capture file content changes", type: "bool" },
            { label: "file names", type: "string" },
            { label: "capture hashes for all written files", type: "bool" }
          ]
        },
        "Registry Rule": {
          fields: [
            { label: "Rule Path", type: "string" },
            { label: "Depth", type: "string" },
            { label: "Severity", type: "string" },
            { label: "Description (optional)", type: "string" },
            {
              label: "Key",
              type: "select",
              options: ["All", "Create", "Delete", "Rename", "Permissions"]
            },
            {
              label: "Value",
              type: "select",
              options: ["All", "Set", "Delete"]
            },
            {
              label: "Monitor changes in Keys and values",
              type: "select",
              options: ["include all", "include only"]
            },
            {
              label: "Processes",
              type: "select",
              options: ["include all", "include only"]
            },
            { label: "Allow Crowdstrike to capture registry content changes", type: "bool" },
            { label: "Registry Values", type: "string" }
          ]
        }
      }
    },
  
    "NextGen Siem Correlation rule": {
      // No subRules here, just a direct fields array
      fields: [
        { label: "Name", type: "string" },
        { label: "Description", type: "string" },
        { label: "Search Query", type: "string" },
        {
          label: "TriggerType",
          type: "select",
          options: ["Verbox", "Summery"]
        },
        {
          label: "Outcome",
          type: "select",
          options: ["Incident", "Detection"]
        },
        {
          label: "Severity",
          type: "select",
          options: ["informational", "low", "medium", "high", "critical"]
        },
        { label: "MITRE ATTACK Tactic&Technique", type: "bool" },
        {
          label: "matrix",
          type: "select",
          options: ["Enterprise", "Mobile"]
        },
        { label: "tactic", type: "string" },
        { label: "Technique", type: "string" }
      ]
    }
  };
  