import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    UncontrolledAlert,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import { Link } from "react-router-dom";
import sdk from "api-sdk";                // <--- Using your existing "api-sdk" index
import categories from "../../assets/data/attackVectors.json";
import platformsData from "../../assets/data/platformsBlueRule.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import classnames from "classnames";
import AddCortex from "../AddCortex/AddCortex";
import AddSysmon from "../AddSysmon/AddSysmon";
import AddSplunk from "../AddSplunk/AddSplunk";  // New component for Splunk input
import AddQradar from "../AddQradar/AddQradar";  // New component for Qradar input
import AddSyslog from "../AddSyslog/AddSyslog";
import PlaybookTab from "../PlaybookTab/PlaybookTab";
import AddCortexPlaybook from "../PlaybookTab/AddCortexPlaybook";
import Select from 'react-select';

// Extract the rule categories from the categories file
const ruleCategories = categories.map(category => category.title);

// Define the status options
const statusOptions = ['Working on it', 'Ready for test', 'Irrelevant'];

const clientOptions = [
    { value: 'Grupo Salinas', label: 'Grupo Salinas' },
    { value: 'Tipico', label: 'Tipico' },
    { value: 'Abrdn', label: 'Abrdn' },
    { value: 'Telefonica', label: 'Telefonica' },
    { value: 'SinExpress', label: 'SinExpress' },
    { value: 'MOJ', label: 'MOJ' },
    { value: 'Mapi', label: 'Mapi' },
    { value: 'RamiChil', label: 'RamiChil' },
    { value: 'Altshuller', label: 'Altshuller' },
    { value: 'Isracard', label: 'Isracard' },
    { value: 'Mataf', label: 'Mataf' },
    { value: 'Jerusalem College', label: 'Jerusalem College' },
    { value: 'Shva', label: 'Shva' },
    { value: 'Pool', label: 'Pool' }
];

class BlueRuleCreation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            creating: true,
            uploadError: undefined,
            ruleCategory: undefined,
            ruleCategoryError: undefined,
            status: undefined,
            statusError: undefined,
            creator: undefined,
            creatorError: undefined,
            ruleName: undefined,
            nameError: undefined,
            ruleDescription: undefined,
            ruleDescriptionError: undefined,
            ruleSummary: undefined,
            ruleSummaryError: undefined,
            mitre: undefined,
            mitreError: undefined,

            // Files
            chartFile: undefined,

            chosenPlatforms: {},

            // We store the DS data here
            cortexData: null,
            sysmonData: null,
            splunkData: null, // New state for Splunk data
            qradarData: null, // New state for Qradar data
            syslogData: "",

            // Playbook data
            sysmonPlaybookData: "",
            cortexPlaybookData: "",
            splunkPlaybookData: "",
            qradarPlaybookData: "",
            syslogPlaybookData: "",

            //tabs
            activeTab: "1",
            silverBullets: [],
            selectedSilverBullet: null,
            silverBulletSearch: "",
            allSilverBullets: [],
            silverBulletInputValue: "",
            selectedClients: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCortexChange = this.handleCortexChange.bind(this);
        this.handleSysmonChange = this.handleSysmonChange.bind(this);
        this.handleSplunkChange = this.handleSplunkChange.bind(this);
        this.handleQradarChange = this.handleQradarChange.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.loadAllSilverBullets = this.loadAllSilverBullets.bind(this);
        this.handleSilverBulletSearchSelect = this.handleSilverBulletSearchSelect.bind(this);
        this.handleSilverBulletSelection = this.handleSilverBulletSelection.bind(this);
        this.handleSyslogChange = this.handleSyslogChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        
        // Playbook handlers
        this.handleSysmonPlaybookChange = this.handleSysmonPlaybookChange.bind(this);
        this.handleCortexPlaybookChange = this.handleCortexPlaybookChange.bind(this);
        this.handleSplunkPlaybookChange = this.handleSplunkPlaybookChange.bind(this);
        this.handleQradarPlaybookChange = this.handleQradarPlaybookChange.bind(this);
        this.handleSyslogPlaybookChange = this.handleSyslogPlaybookChange.bind(this);
    }

    handleCortexChange(cortexData) {
        console.log("Cortex data received:", cortexData);
        // cortexData = { cortexType, cortexSubRule, fieldValues, customIoaData }
        this.setState({ cortexData });
    }

    handleSysmonChange(xmlText) {
        this.setState({ sysmonData: xmlText });
    }

    handleSplunkChange(splunkText) {
        this.setState({ splunkData: splunkText });
    }

    handleQradarChange(qradarText) {
        this.setState({ qradarData: qradarText });
    }

    handleSyslogChange(syslogText) {
        this.setState({ syslogData: syslogText });
    }

    handleCategoryChange = (e) => {
        const selectedCategory = e.target.value.trim();  // Trim spaces and new lines
        this.setState({ ruleCategory: selectedCategory, ruleCategoryError: undefined });
    };

    loadAllSilverBullets() {
        this.setState({ creating: true });
        Promise.all([
            sdk.silverBullets.getAll(),
            sdk.blueRules.get({ fields: ['id', 'name', 'silverBulletId'] })
        ])
        .then(([bullets, blueRules]) => {
            // Create a map of silverBulletId to blueRule name
            const associatedBullets = {};
            blueRules.forEach(rule => {
                if (rule.silverBulletId) {
                    associatedBullets[rule.silverBulletId] = rule.name;
                }
            });

            // Add warning message to bullets that are already associated
            const bulletsWithWarnings = bullets.map(bullet => ({
                ...bullet,
                warning: associatedBullets[bullet.id] ? 
                    `This Silver Bullet is already associated with Blue Rule: ${associatedBullets[bullet.id]}` : 
                    null
            }));

            this.setState({ 
                allSilverBullets: bulletsWithWarnings, 
                silverBullets: bulletsWithWarnings,
                creating: false 
            });
        })
        .catch(error => {
            console.error("Failed to fetch SilverBullets:", error);
            toast.error("Failed to fetch SilverBullets.");
            this.setState({ creating: false });
        });
    }

    handleSilverBulletSearchSelect = (e) => {
        const searchValue = e.target.value;
        this.setState({ silverBulletInputValue: searchValue });
        
        // Find if the input matches any bullet exactly
        const matchedBullet = this.state.allSilverBullets.find(
            bullet => bullet.name.toLowerCase() === searchValue.toLowerCase()
        );
        
        if (matchedBullet) {
            if (matchedBullet.warning) {
                toast.warning(matchedBullet.warning);
                this.setState({ 
                    selectedSilverBullet: null,
                    silverBulletInputValue: "" // Reset the input value
                });
            } else {
                this.setState({ selectedSilverBullet: matchedBullet.id });
            }
        } else {
            // If no exact match, filter the list but don't set a selected bullet
            this.setState({ selectedSilverBullet: null });
        }
    }

    // This handles when a user selects an option from the datalist
    handleSilverBulletSelection = (e) => {
        const selectedValue = e.target.value;
        const selectedBullet = this.state.allSilverBullets.find(
            bullet => bullet.name === selectedValue
        );
        
        if (selectedBullet) {
            if (selectedBullet.warning) {
                toast.warning(selectedBullet.warning);
                this.setState({ 
                    selectedSilverBullet: null,
                    silverBulletInputValue: "" // Reset the input value
                });
            } else {
                this.setState({ 
                    selectedSilverBullet: selectedBullet.id,
                    silverBulletInputValue: selectedValue
                });
            }
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    validateForm() {
        const errors = {};

        if (!this.state.ruleName) {
            errors.nameError = "Rule Name required";
        }

        if (!this.state.ruleCategory) {
            errors.ruleCategoryError = "Rule Category required";
        }

        if (!this.state.status) {
            errors.statusError = "Status required";
        }

        if (!this.state.creator) {
            errors.creatorError = "Creator required";
        }

        if (!this.state.ruleDescription) {
            errors.ruleDescriptionError = "Rule Description required";
        }

        if (!this.state.ruleSummary) {
            errors.ruleSummaryError = "Rule Summary required";
        }

        return {
            isValid: Object.keys(errors).length === 0,
            errors
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        const {isValid, errors} = this.validateForm();
        if (!isValid) {
            this.setState(errors);
            return;
        }

        const blueRule = {
            name: this.state.ruleName,
            ruleCategory: this.state.ruleCategory,
            ruleDescription: this.state.ruleDescription,
            ruleSummary: this.state.ruleSummary,
            status: this.state.status || 'Working on it',
            creator: this.state.creator,
            mitre: this.state.mitre,
            silverBulletId: this.state.selectedSilverBullet ? this.state.selectedSilverBullet : null
        };

        this.setState({ creating: true });

        sdk.blueRules.create(blueRule)
            .then(createdRule => {
                toast.success("Blue Rule Created Successfully");
                console.log('Created Blue Rule: ', createdRule);

                // We'll gather all promises in an array for Promise.all
                const promises = [];

                // 1) Upload chart if present
                if (this.state.chartFile) {
                    promises.push(sdk.blueRules.uploadFile(this.state.chartFile, createdRule.id, "chart")
                        .catch(error => {
                            console.error("Error uploading chart:", error);
                            toast.error("Error uploading chart");
                        }));
                }

                // 2) Save platform choices if any
                if (this.state.chosenPlatforms && Object.keys(this.state.chosenPlatforms).length > 0) {
                    const platforms = {
                        ...this.state.chosenPlatforms,
                        blueRuleId: createdRule.id
                    };
                    promises.push(sdk.blueRulesPlatforms.create(platforms)
                        .catch(error => {
                            console.error("Error saving platforms:", error);
                            toast.error("Error saving platforms");
                        }));
                }

                // 3) Save Cortex data if present
                if (this.state.cortexData) {
                    const cortexPromises = [];
                    const { cortexType, cortexSubRule, fieldValues, customIoaData } = this.state.cortexData;

                    if (cortexType === "Custom IOA Rule") {
                        // Process dynamic fields from customIoaData
                        if (customIoaData && Array.isArray(customIoaData.dynamicFields)) {
                            customIoaData.dynamicFields.forEach(row => {
                                // Only process rows with a selected field
                                if (row.field) {
                                    const cortexRow = {
                                        fields: row.field,
                                        string_value: typeof row.value === "string" ? row.value : null,
                                        bool_value: typeof row.value === "boolean" ? row.value : null,
                                        rule_type: cortexType,
                                        rule_id: createdRule.id
                                    };
                                    cortexPromises.push(sdk.cortex.create(cortexRow)
                                        .catch(error => {
                                            console.error("Error creating cortex entry:", error);
                                            toast.error(`Error saving CrowdStrike field: ${row.field}`);
                                        }));
                                }
                            });
                            if (customIoaData.testingString && customIoaData.testingString.trim() !== "") {
                                const testingRow = {
                                    fields: "Testing String",
                                    string_value: customIoaData.testingString,
                                    bool_value: null,
                                    rule_type: cortexType,
                                    rule_id: createdRule.id
                                };
                                cortexPromises.push(sdk.cortex.create(testingRow)
                                    .catch(error => {
                                        console.error("Error creating testing string entry:", error);
                                        toast.error("Error saving Testing String field");
                                    }));
                            }
                        }
                    } else {
                        // Process the data for other rule types
                        const entries = Object.entries(fieldValues || {});
                        if (entries.length > 0) {
                            entries.forEach(([label, val]) => {
                                const isBool = typeof val === "boolean";
                                const cortexRow = {
                                    fields: label,
                                    string_value: isBool ? null : val,
                                    bool_value: isBool ? val : null,
                                    rule_type: cortexSubRule || cortexType,
                                    rule_id: createdRule.id
                                };
                                cortexPromises.push(sdk.cortex.create(cortexRow)
                                    .catch(error => {
                                        console.error(`Error creating cortex entry for ${label}:`, error);
                                        toast.error(`Error saving CrowdStrike field: ${label}`);
                                    }));
                            });
                        }
                    }

                    // Add all cortex promises to the main promises array
                    promises.push(Promise.all(cortexPromises));
                }

                // 4) Save Sysmon data if present
                if (this.state.sysmonData) {
                    promises.push(sdk.sysmon.create({
                        xmlText: this.state.sysmonData,
                        rule_id: createdRule.id
                    }).catch(error => {
                        console.error("Error creating sysmon:", error);
                        toast.error("Error saving sysmon data");
                    }));
                }

                // 5) Save Splunk data if present
                if (this.state.splunkData) {
                    promises.push(sdk.splunk.create({
                        xmlText: this.state.splunkData,
                        rule_id: createdRule.id
                    }).catch(error => {
                        console.error("Error creating splunk:", error);
                        toast.error("Error saving splunk data");
                    }));
                }

                // 6) Save Qradar data if present
                if (this.state.qradarData) {
                    promises.push(sdk.qradar.create({
                        xmlText: this.state.qradarData,
                        rule_id: createdRule.id
                    }).catch(error => {
                        console.error("Error creating qradar:", error);
                        toast.error("Error saving qradar data");
                    }));
                }

                // 7) Save Syslog data if present
                if (this.state.syslogData) {
                    promises.push(sdk.syslog.create({
                        xmlText: this.state.syslogData,
                        rule_id: createdRule.id
                    }).catch(error => {
                        console.error("Error creating syslog:", error);
                        toast.error("Error saving syslog data");
                    }));
                }

                // 8) Save client relationships if any selected
                if (this.state.selectedClients && this.state.selectedClients.length > 0) {
                    const clientNames = this.state.selectedClients.map(client => client.value);
                    promises.push(sdk.blueRuleClients.createMultiple(createdRule.id, clientNames)
                        .catch(error => {
                            console.error("Error creating client relationships:", error);
                            toast.error("Error saving client relationships");
                        }));
                }

                // 9) Save Playbook data if present
                // Sysmon Playbook
                if (this.state.sysmonPlaybookData) {
                    promises.push(sdk.sysmonPlaybook.create({
                        playbookText: this.state.sysmonPlaybookData,
                        rule_id: createdRule.id
                    }).catch(error => {
                        console.error("Error creating Sysmon playbook:", error);
                        toast.error("Error saving Sysmon playbook");
                    }));
                }

                // Cortex Playbook
                if (this.state.cortexPlaybookData) {
                    promises.push(this.createCortexPlaybookEntries(createdRule.id)
                        .catch(error => {
                            console.error("Error creating Cortex playbook entries:", error);
                            toast.error("Error saving CrowdStrike playbook");
                        })
                    );
                }

                // Splunk Playbook
                if (this.state.splunkPlaybookData) {
                    promises.push(sdk.splunkPlaybook.create({
                        playbookText: this.state.splunkPlaybookData,
                        rule_id: createdRule.id
                    }).catch(error => {
                        console.error("Error creating Splunk playbook:", error);
                        toast.error("Error saving Splunk playbook");
                    }));
                }

                // Qradar Playbook
                if (this.state.qradarPlaybookData) {
                    promises.push(sdk.qradarPlaybook.create({
                        playbookText: this.state.qradarPlaybookData,
                        rule_id: createdRule.id
                    }).catch(error => {
                        console.error("Error creating Qradar playbook:", error);
                        toast.error("Error saving Qradar playbook");
                    }));
                }

                // Syslog Playbook
                if (this.state.syslogPlaybookData) {
                    promises.push(sdk.syslogPlaybook.create({
                        playbookText: this.state.syslogPlaybookData,
                        rule_id: createdRule.id
                    }).catch(error => {
                        console.error("Error creating Syslog playbook:", error);
                        toast.error("Error saving Syslog playbook");
                    }));
                }

                return Promise.all(promises);
            })
            .then(() => {
                setTimeout(() => {
                    this.props.history.push("/blue-rule-management");
                }, 2000);
            })
            .catch(error => {
                console.error("Error creating Blue Rule:", error);
                this.setState({
                    creating: false,
                    uploadError: error.toString()
                });
                toast.error("Error creating Blue Rule: " + error.toString());
            });
    }

    // Helper method to create cortex playbook entries
    createCortexPlaybookEntries(ruleId) {
        const { cortexPlaybookData } = this.state;
        
        if (!cortexPlaybookData) {
            return Promise.resolve();
        }

        return sdk.cortexPlaybook.create({
            playbookText: cortexPlaybookData,
            rule_id: ruleId
        }).catch(error => {
            console.error("Error creating cortex playbook:", error);
            toast.error("Error saving CrowdStrike playbook");
        });
    }

    componentDidMount() {
        this.setState({ creating: false });
        this.loadAllSilverBullets();
    }

    handleClientChange(selectedOptions) {
        this.setState({ selectedClients: selectedOptions || [] });
    }

    // Playbook handlers
    handleSysmonPlaybookChange(playbookText) {
        this.setState({ sysmonPlaybookData: playbookText });
    }

    handleCortexPlaybookChange(playbookText) {
        this.setState({ cortexPlaybookData: playbookText });
    }

    handleSplunkPlaybookChange(playbookText) {
        this.setState({ splunkPlaybookData: playbookText });
    }

    handleQradarPlaybookChange(playbookText) {
        this.setState({ qradarPlaybookData: playbookText });
    }

    handleSyslogPlaybookChange(playbookText) {
        this.setState({ syslogPlaybookData: playbookText });
    }

    render() {
        const platforms = platformsData;

        return (
            <div>
                <ToastContainer />
                <Form onSubmit={this.handleSubmit}>
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            Create a Blue Rule
                            <span className="float-right">
                                <Link to={`/blue-rule-management`} onClick={e => e.stopPropagation()}>
                                    <Button className="btn-outline-danger" size="sm">
                                        <i className="fas fa-times" />
                                    </Button>
                                </Link>
                            </span>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Rule Name</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.nameError}
                                                        onChange={e =>
                                                            this.setState({ ruleName: e.target.value, nameError: undefined })
                                                        }
                                                    />
                                                    <FormFeedback>
                                                        {this.state.nameError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.statusError}
                                                        onChange={e => this.setState({ status: e.target.value, statusError: undefined })}
                                                        defaultValue="Select Status"
                                                    >
                                                        <option key="status-placeholder" default disabled>Select Status</option>
                                                        {statusOptions.map(status => (
                                                            <option key={status} value={status}>{status}</option>
                                                        ))}
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.statusError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.ruleCategoryError}
                                                        onChange={this.handleCategoryChange}  // Call function to fetch Silver Bullets
                                                        defaultValue="Select Rule Category"
                                                    >
                                                        <option key="ruleCategory-placeholder" default disabled>Select Rule Category</option>
                                                        {ruleCategories.map(category => (
                                                            <option key={category + "-option"} value={category}>{category}</option>
                                                        ))}
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.ruleCategoryError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Silver Bullet</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Type to search Silver Bullets..."
                                                        list="silverBulletOptions"
                                                        disabled={this.state.creating || !this.state.allSilverBullets.length}
                                                        value={this.state.silverBulletInputValue}
                                                        onChange={this.handleSilverBulletSearchSelect}
                                                        onBlur={this.handleSilverBulletSelection}
                                                    />
                                                    <datalist id="silverBulletOptions">
                                                        {this.state.allSilverBullets.map((bullet) => (
                                                            <option key={bullet.id} value={bullet.name} />
                                                        ))}
                                                    </datalist>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <CardTitle className="bg-light border-bottom p-3 mt-3">
                                                Creator
                                            </CardTitle>
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    className="form-control"
                                                    value={this.state.creator}
                                                    onChange={e => this.setState({ creator: e.target.value, creatorError: undefined })}
                                                    invalid={!!this.state.creatorError}
                                                >
                                                    <option value="">Select a Creator</option>
                                                    <option value="Michael Reder">Michael Reder</option>
                                                    <option value="Noa Elias">Noa Elias</option>
                                                    <option value="Yoav Laugie">Yoav Laugie</option>
                                                    <option value="Dylan Fleury">Dylan Fleury</option>
                                                    <option value="Ury Yudkin">Ury Yudkin</option>
                                                    <option value="Danel Yehuda">Danel Yehuda</option>
                                                    <option value="Evyatar Guilad">Evyatar Guilad</option>
                                                </Input>
                                                <FormFeedback>
                                                    {this.state.creatorError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-3">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>MITRE ATT&CK</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="T1234, T5678..."
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.mitreError}
                                                        onChange={e => this.setState({ mitre: e.target.value, mitreError: undefined })}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.mitreError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-3">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Attack Description</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="textarea"
                                                        rows="4"
                                                        placeholder="Rule description..."
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.ruleDescriptionError}
                                                        onChange={e => this.setState({ ruleDescription: e.target.value, ruleDescriptionError: undefined })}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.ruleDescriptionError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Rule Summary</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="textarea"
                                                        rows="3"
                                                        placeholder="Rule summary..."
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.ruleSummaryError}
                                                        onChange={e => this.setState({ ruleSummary: e.target.value, ruleSummaryError: undefined })}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.ruleSummaryError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6">
                                    <CardTitle className="bg-light border-bottom p-3">
                                        Select the relevant Platforms
                                    </CardTitle>
                                    <FormGroup>
                                        {platforms.map(platform => (
                                            <div className="form-check form-check-inline" key={platform.name}>
                                                <CustomInput
                                                    type="checkbox"
                                                    id={platform.name}
                                                    label={platform.name}
                                                    onChange={e => {
                                                        const chosenPlatforms = { ...this.state.chosenPlatforms };
                                                        chosenPlatforms[platform.propName] = e.target.checked;
                                                        this.setState({ chosenPlatforms });
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </FormGroup>
                                    
                                    <CardTitle className="bg-light border-bottom p-3 mt-4">
                                        Run In (Clients)
                                    </CardTitle>
                                    <FormGroup>
                                        <Select
                                            isMulti
                                            name="clients"
                                            options={clientOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select clients where this rule is implemented"
                                            value={this.state.selectedClients}
                                            onChange={this.handleClientChange}
                                            isDisabled={this.state.creating}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mt-3 ml-1">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "1" })}
                                            onClick={() => this.toggleTab("1")}
                                        >
                                            Sysmon
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "2" })}
                                            onClick={() => this.toggleTab("2")}
                                        >
                                            CrowdStrike
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "3" })}
                                            onClick={() => this.toggleTab("3")}
                                        >
                                            Splunk
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "4" })}
                                            onClick={() => this.toggleTab("4")}
                                        >
                                            Qradar
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "5" })}
                                            onClick={() => this.toggleTab("5")}
                                        >
                                            Syslog
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Row>
                            <Row className="mt-3 ml-1">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "6" })}
                                            onClick={() => this.toggleTab("6")}
                                        >
                                            Sysmon Playbook
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "7" })}
                                            onClick={() => this.toggleTab("7")}
                                        >
                                            CrowdStrike Playbook
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "8" })}
                                            onClick={() => this.toggleTab("8")}
                                        >
                                            Splunk Playbook
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "9" })}
                                            onClick={() => this.toggleTab("9")}
                                        >
                                            Qradar Playbook
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "10" })}
                                            onClick={() => this.toggleTab("10")}
                                        >
                                            Syslog Playbook
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Row>
                            <Row className="mt-3">
                                <Col md="12">
                                    <div style={{ display: this.state.activeTab === "1" ? "block" : "none" }}>
                                        <AddSysmon
                                            xmlText={this.state.sysmonData}
                                            onSysmonChange={this.handleSysmonChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "2" ? "block" : "none" }}>
                                        <AddCortex
                                            fieldData={this.state.cortexData}
                                            onChange={this.handleCortexChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "3" ? "block" : "none" }}>
                                        <AddSplunk
                                            splunkText={this.state.splunkData}
                                            onSplunkChange={this.handleSplunkChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "4" ? "block" : "none" }}>
                                        <AddQradar
                                            qradarText={this.state.qradarData}
                                            onQradarChange={this.handleQradarChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "5" ? "block" : "none" }}>
                                        <AddSyslog
                                            syslogText={this.state.syslogData}
                                            onSyslogChange={this.handleSyslogChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "6" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Sysmon Playbook</h5>
                                        </div>
                                        <PlaybookTab
                                            title="Sysmon"
                                            playbookText={this.state.sysmonPlaybookData}
                                            onPlaybookChange={this.handleSysmonPlaybookChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "7" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">CrowdStrike Playbook</h5>
                                        </div>
                                        <AddCortexPlaybook
                                            fieldData={this.state.cortexPlaybookData}
                                            onChange={this.handleCortexPlaybookChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "8" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Splunk Playbook</h5>
                                        </div>
                                        <PlaybookTab
                                            title="Splunk"
                                            playbookText={this.state.splunkPlaybookData}
                                            onPlaybookChange={this.handleSplunkPlaybookChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "9" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Qradar Playbook</h5>
                                        </div>
                                        <PlaybookTab
                                            title="Qradar"
                                            playbookText={this.state.qradarPlaybookData}
                                            onPlaybookChange={this.handleQradarPlaybookChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "10" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Syslog Playbook</h5>
                                        </div>
                                        <PlaybookTab
                                            title="Syslog"
                                            playbookText={this.state.syslogPlaybookData}
                                            onPlaybookChange={this.handleSyslogPlaybookChange} />
                                    </div>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Chart png file</InputGroupText>
                                    </InputGroupAddon>
                                    <div className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            id="chartFileInput"
                                            disabled={this.state.creating}
                                            onChange={(e) => this.setState({ chartFile: e.target.files[0] })}
                                        />
                                        <label
                                            className="custom-file-label"
                                            htmlFor="chartFileInput"
                                        >
                                            {this.state.chartFile ? this.state.chartFile.name : "Choose file"}
                                        </label>
                                    </div>
                                </InputGroup>
                                {this.state.chartFile && (
                                    <div className="alert alert-info mt-2 d-flex align-items-center">
                                        <i className="fas fa-info-circle mr-2" style={{ fontSize: '1.5rem', color: '#0056b3' }}></i>
                                        <div>
                                            <h5 className="alert-heading mb-1">Chart Selected</h5>
                                            <p className="mb-0">
                                                <strong>{this.state.chartFile.name}</strong> will be uploaded when you submit this form.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    {this.state.uploadError ? (
                        <UncontrolledAlert className="mt-2 mb-0" color="danger">
                            {this.state.uploadError}
                        </UncontrolledAlert>
                    ) : null}

                    <Row className="mt-2">
                        <Col xl={{ size: 2, offset: 5 }} lg={{ size: 4, offset: 4 }} md="12">
                            <FormGroup>
                                <Button type="submit" color="secondary" size="lg" block disabled={this.state.creating}>
                                    {!this.state.creating ? "Submit" : <i className="fas fa-spin fa-spinner" />}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default BlueRuleCreation;
