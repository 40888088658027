import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
  Badge,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DeleteButton from '../../components/buttons/deleteButton';
import sdk from 'api-sdk';
import '../../views/silverBulletManagement/SearchBar.css';
import { toast } from 'react-toastify';

// Add custom styles
const styles = {
  searchFilterContainer: {
    backgroundColor: '#343a40',
    borderBottom: '1px solid #495057',
    padding: '1rem',
  },
  searchWrapper: {
    boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
  },
  statusFilterWrapper: {
    boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
  },
  inputGroup: {
    backgroundColor: 'transparent',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  inputGroupText: {
    border: '1px solid #495057',
    backgroundColor: '#495057',
    color: '#adb5bd',
    borderRight: 'none',
  },
  input: {
    border: '1px solid #495057',
    padding: '0.375rem 0.75rem',
    backgroundColor: '#495057',
    color: '#fff',
    borderLeft: 'none',
  },
  select: {
    border: '1px solid #495057',
    padding: '0.375rem 0.75rem',
    cursor: 'pointer',
    backgroundColor: '#495057',
    color: '#fff',
    borderLeft: 'none',
  }
};

// Status badge color mapping
const statusColors = {
  'Working on it': 'warning',
  'Ready for test': 'info',
  'Completed': 'success',
  'Irrelevant': 'secondary'
};

// Creator options
const creatorOptions = [
  'All Users', 
  'Michael Reder', 
  'Noa Elias', 
  'Yoav Laugie', 
  'Dylan Fleury', 
  'Ury Yudkin', 
  'Danel Yehuda',
  'Evyatar Guilad'
];

class BlueRuleTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      blueRules: null,
      search: null,
      statusFilter: 'All Status', // Default to show all statuses
      creatorFilter: 'All Users', // Default to show all creators
    };

    this.getBlueRules = this.getBlueRules.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.searchFunction = this.searchFunction.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.setStatusFilter = this.setStatusFilter.bind(this);
    this.setCreatorFilter = this.setCreatorFilter.bind(this);
  }

  getBlueRules() {
    sdk.blueRules.get().then(blueRules => {
      this.setState({ blueRules });
    });
  }

  handleDelete(id, name) {
    sdk.blueRules.delete(id).then(() => {
      this.getBlueRules(); // Refresh the list of blue rules
      toast.success(`Blue Rule "${name}" deleted successfully`); // Show success toast
    }).catch(error => {
      console.error("Error deleting blue rule:", error);
      toast.error("Error deleting blue rule"); // Show error toast in case of failure
    });
  }

  componentDidMount() {
    this.getBlueRules();
  }

  searchFunction(blueRule) {
    // Check if it passes the status filter
    if (this.state.statusFilter !== 'All Status' && blueRule.status !== this.state.statusFilter) {
      return false;
    }
    
    // Check if it passes the creator filter
    if (this.state.creatorFilter !== 'All Users' && blueRule.creator !== this.state.creatorFilter) {
      return false;
    }
    
    // Then check if it passes the search text filter
    if (!this.state.search) return true;
    return blueRule.name.toLowerCase().includes(this.state.search.toLowerCase());
  }

  setSearch(searchTerm) {
    this.setState({ search : searchTerm });
    return searchTerm;
  }

  setStatusFilter(status) {
    this.setState({ statusFilter: status });
  }

  setCreatorFilter(creator) {
    this.setState({ creatorFilter: creator });
  }

  render() {
    const currentBlueRules = this.state.blueRules ? this.state.blueRules.filter(br => this.searchFunction(br)) : null;
    const statusOptions = ['All Status', 'Working on it', 'Ready for test', 'Completed', 'Irrelevant'];

    return (
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          Blue Rule Management
          <span className="float-right">
            <Link to={`blue-rule-management/create`} onClick={e => e.stopPropagation()}>
              <Button className='btn-outline-secondary' size='sm'>
                Create Blue Rule
              </Button>
            </Link>
          </span>
        </CardTitle>
        <CardBody className='p-0'>
          <div style={styles.searchFilterContainer}>
            <Row className="align-items-center">
              <Col md={6}>
                <div style={styles.searchWrapper}>
                  <InputGroup style={styles.inputGroup}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={styles.inputGroupText}>
                        <i className="fas fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Search rules..."
                      style={styles.input}
                      onChange={(e) => this.setSearch(e.target.value)}
                      value={this.state.search || ""}
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col md={3}>
                <div style={styles.statusFilterWrapper}>
                  <InputGroup style={styles.inputGroup}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={styles.inputGroupText}>
                        <i className="fas fa-filter"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      style={styles.select}
                      value={this.state.statusFilter}
                      onChange={(e) => this.setStatusFilter(e.target.value)}
                    >
                      {statusOptions.map(status => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </div>
              </Col>
              <Col md={3}>
                <div style={styles.statusFilterWrapper}>
                  <InputGroup style={styles.inputGroup}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={styles.inputGroupText}>
                        <i className="fas fa-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="select"
                      style={styles.select}
                      value={this.state.creatorFilter}
                      onChange={(e) => this.setCreatorFilter(e.target.value)}
                    >
                      {creatorOptions.map(creator => (
                        <option key={creator} value={creator}>
                          {creator}
                        </option>
                      ))}
                    </Input>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </div>
          {this.state.blueRules?
            <Table hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Rule Category</th>
                  <th>Status</th>
                  <th>Creator</th>
                  <th>Created</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.blueRules.length === 0?
                  <tr key='no-bluerule'>
                    <td colSpan='8'>
                      <h3>
                        No Blue Rules
                      </h3>
                    </td>
                  </tr>
              : null}
              {
                currentBlueRules && currentBlueRules.map((blueRule) => (
                  <tr key={blueRule.id}>
                    <td>{blueRule.id}</td>
                    <td>{blueRule.name}</td>
                    <td>{blueRule.ruleCategory}</td>
                    <td>
                      {blueRule.status ? (
                        <Badge color={statusColors[blueRule.status] || 'secondary'} 
                               style={{ fontSize: '0.9rem', padding: '6px 10px' }}>
                          {blueRule.status}
                        </Badge>
                      ) : (
                        <Badge color="light" style={{ fontSize: '0.9rem', padding: '6px 10px' }}>No Status</Badge>
                      )}
                    </td>
                    <td>{blueRule.creator || 'Not assigned'}</td>
                    <td>{(new Date(Date.parse(blueRule.createdAt))).toLocaleString('en-il')}</td>
                    <td>
                      <Link to={`blue-rule-management/edit/${blueRule.id}`} onClick={e => e.stopPropagation()}>
                        <Button className='btn-outline-secondary'>
                          <i className='mdi mdi-pencil'/>
                        </Button>
                      </Link>
                    </td>
                    <td>
                      <DeleteButton item='blue rule' deleteFunc={() => {this.handleDelete(blueRule.id, blueRule.name)}}/>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          :
            <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
          }
        </CardBody>
      </Card>
    );
  }
}

export default BlueRuleTable;
