import React from "react";
import { FormGroup, Label, Input, Alert } from "reactstrap";
import * as xml2js from "xml2js";

class AddSysmon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      xmlText: props.xmlText || "",
      isValidXml: null,
      prevPropsXmlText: props.xmlText || "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.validateXml = this.validateXml.bind(this);
  }

  componentDidMount() {
    // If initial XML exists, validate it
    if (this.state.xmlText) {
      this.validateXml(this.state.xmlText);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Only update state from props if the prop actually changed
    if (nextProps.xmlText !== prevState.prevPropsXmlText) {
      const xmlText = nextProps.xmlText || "";
      return {
        xmlText,
        prevPropsXmlText: nextProps.xmlText,
        isValidXml: null, // Reset validity when prop changes
      };
    }
    return null; // No state update needed
  }

  componentDidUpdate(prevProps, prevState) {
    // If xmlText was updated from props and is different from previous state
    if (this.state.xmlText !== prevState.xmlText && 
        this.state.xmlText === this.state.prevPropsXmlText) {
      if (this.state.xmlText) {
        this.validateXml(this.state.xmlText);
      }
    }
  }

  validateXml(xmlText) {
    xml2js.parseString(xmlText, (err) => {
      const isValidXml = !err;
      this.setState({ isValidXml });
    });
  }

  handleChange(e) {
    const xmlText = e.target.value;
    this.setState({ xmlText, isValidXml: null }, () => {
      // Automatically validate the XML after the state update
      this.validateXml(xmlText);
      
      // Always pass the XML to the parent component
      if (this.props.onSysmonChange) {
        this.props.onSysmonChange(xmlText);
      }
    });
  }

  render() {
    return (
      <div>
        <FormGroup>
          <Label for="sysmonXml">Enter Sysmon XML:</Label>
          <Input
            type="textarea"
            id="sysmonXml"
            rows="10"
            value={this.state.xmlText}
            onChange={this.handleChange}
          />
        </FormGroup>
        {this.state.isValidXml !== null && (
          <Alert color={this.state.isValidXml ? "success" : "danger"} className="mt-3">
            {this.state.isValidXml ? "Valid XML" : "Invalid XML"}
          </Alert>
        )}
      </div>
    );
  }
}

export default AddSysmon;
