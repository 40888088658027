import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

class AddSplunk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      splunkText: props.splunkText || "",
      prevPropsSplunkText: props.splunkText || ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Only update state from props if the prop actually changed
    if (nextProps.splunkText !== prevState.prevPropsSplunkText) {
      return {
        splunkText: nextProps.splunkText || "",
        prevPropsSplunkText: nextProps.splunkText
      };
    }
    return null; // No state update needed
  }

  handleChange(e) {
    const splunkText = e.target.value;
    this.setState({ splunkText }, () => {
      if (this.props.onSplunkChange) {
        this.props.onSplunkChange(splunkText);
      }
    });
  }

  render() {
    return (
      <div>
        <FormGroup>
          <Label for="splunkXml">Enter Splunk:</Label>
          <Input
            type="textarea"
            id="splunkXml"
            rows="10"
            value={this.state.splunkText}
            onChange={this.handleChange}
          />
        </FormGroup>
      </div>
    );
  }
}

export default AddSplunk;
