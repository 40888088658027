import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

/**
 * A component for CortexPlaybook that extends the functionality of AddCortex
 * but is specifically designed for playbook documentation.
 * The structure mirrors AddCortex but is adapted for documentation purposes.
 */

function AddCortexPlaybook({ fieldData, onChange }) {
  const handleChange = (e) => {
    const playbookText = e.target.value;
    if (onChange) {
      onChange(playbookText);
    }
  };

  return (
    <div>
      <div className="alert alert-info mb-4">
        <h5><i className="fas fa-book mr-2"></i>CrowdStrike Playbook</h5>
        <p className="mb-0">Add detailed implementation steps, verification methods, and documentation for this CrowdStrike detection rule.</p>
      </div>
      
      <FormGroup>
        <Label for="cortexPlaybookText">Playbook Instructions:</Label>
        <Input
          type="textarea"
          id="cortexPlaybookText"
          rows="15"
          value={fieldData || ""}
          onChange={handleChange}
          placeholder="Enter detailed instructions or procedural steps for implementing this detection..."
          style={{ fontFamily: "monospace" }}
        />
      </FormGroup>
    </div>
  );
}

export default AddCortexPlaybook; 