import React from "react";
import {
  Card,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
  ButtonGroup,
  Button,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrosoft, faLinux, faAws } from "@fortawesome/free-brands-svg-icons";
import Icon from "@mdi/react";
import { mdiMicrosoftAzure, mdiGoogleCloud } from "@mdi/js";
import sdk from "api-sdk";
import BlueRule from "../BlueRule"; // Import the detailed BlueRule component
import "./BlueRuleMenu.css";
import Select from "react-select";

// Style commented out as it's not used
// const fixOverflowStyle = {
//   ".react-select__menu-list": {
//     overflowY: "auto",
//     overflowX: "hidden"
//   },
//   ".react-select__value-container": {
//     overflow: "visible"
//   }
// };

const Platforms = [
  { name: "Windows", key: "windows", platform: <FontAwesomeIcon icon={faMicrosoft} /> },
  { name: "AWS", key: "aws", platform: <FontAwesomeIcon icon={faAws} /> },
  { name: "Azure", key: "azure", platform: <Icon path={mdiMicrosoftAzure} size={2} /> },
  { name: "GCP", key: "gcp", platform: <Icon path={mdiGoogleCloud} size={2} /> },
  { name: "Linux", key: "linux", platform: <FontAwesomeIcon icon={faLinux} /> },
];

// Define available rule types for filtering
const RuleTypes = [
  { name: "Sysmon", key: "sysmon" },
  { name: "CrowdStrike", key: "cortex" },
  { name: "Splunk", key: "splunk" },
  { name: "Qradar", key: "qradar" },
  { name: "Syslog", key: "syslog" },
];

// Define status options
const StatusOptions = [
  { name: "Working on it", key: "Working on it", color: "warning" },
  { name: "Ready for test", key: "Ready for test", color: "info" },
  { name: "Completed", key: "Completed", color: "success" },
  { name: "Irrelevant", key: "Irrelevant", color: "secondary" },
];

const Creators = [
  "Michael Reder",
  "Noa Elias",
  "Yoav Laugie",
  "Dylan Fleury",
  "Ury Yudkin",
  "Danel Yehuda",
];

const clientOptions = [
  'Grupo Salinas', 'Tipico', 'Abrdn', 'Telefonica', 'SinExpress', 
  'MOJ', 'Mapi', 'RamiChil', 'Altshuller', 'Isracard', 
  'Mataf', 'Jerusalem College', 'Shva', 'Pool'
];

class BlueRuleMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      blueRules: [],
      expandedCards: {},
      contentHeight: {},
      selectedRule: null, // Track the selected rule for detailed view
      platformFilter: "", // Track the selected platform filter
      creatorFilter: "",  // Track the selected creator filter
      ruleTypeFilter: "", // Track the selected rule type filter
      statusFilter: "",   // Track the selected status filter
      categoryFilter: "", // Track the selected category filter
      categories: [], // Will hold unique categories from all rules
      rulesWithClients: {}, // Map rule IDs to their client lists
      notRunInClients: [], // List of client filters for "NOT RUN IN"
      clientsDropdownOpen: false, // For dropdown toggle
      silverBulletClients: {}, // Map silverBulletId to client lists
    };
    this.togglePlatformFilter = this.togglePlatformFilter.bind(this);
    this.handleCreatorChange = this.handleCreatorChange.bind(this);
    this.toggleRuleTypeFilter = this.toggleRuleTypeFilter.bind(this);
    this.toggleStatusFilter = this.toggleStatusFilter.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.toggleClientsDropdown = this.toggleClientsDropdown.bind(this);
    this.handleNotRunInChange = this.handleNotRunInChange.bind(this);
  }

  async componentDidMount() {
    try {
      // Add class to body to prevent scrollbars
      document.body.classList.add('no-horizontal-scroll');
      
      const blueRules = await sdk.blueRules.get({ 
        include: [
          "blueRulesPlatforms",
          "sysmon",
          "cortex",
          "splunk",
          "qradar",
          "syslog",
          "sysmonPlaybooks",
          "cortexPlaybooks",
          "splunkPlaybooks",
          "qradarPlaybooks",
          "syslogPlaybooks"
        ] 
      });
      
      // Debug logging for playbook data
      console.log("Blue Rules with playbooks:", blueRules.map(rule => ({
        id: rule.id,
        name: rule.name,
        sysmonPlaybooks: rule.sysmonPlaybooks,
        cortexPlaybooks: rule.cortexPlaybooks,
        splunkPlaybooks: rule.splunkPlaybooks,
        qradarPlaybooks: rule.qradarPlaybooks,
        syslogPlaybooks: rule.syslogPlaybooks
      })));
      
      // Extract unique categories from all rules
      const uniqueCategories = [...new Set(blueRules.map(rule => rule.ruleCategory).filter(Boolean))].sort();
      
      console.log("Available categories:", uniqueCategories); // Debug log to verify categories
      
      // Load client information for all rules
      const rulesWithClients = {};
      await this.loadRuleClientData(rulesWithClients);
      
      this.setState({ 
        blueRules, 
        loading: false,
        categories: uniqueCategories,
        rulesWithClients
      });
    } catch (error) {
      console.error("Error fetching BlueRules:", error);
      this.setState({ loading: false });
    }
  }

  componentDidUpdate() {
    this.measureContentHeights();
  }

  componentWillUnmount() {
    // Remove class when component unmounts
    document.body.classList.remove('no-horizontal-scroll');
  }

  measureContentHeights = () => {
    const { contentHeight } = this.state;
    this.state.blueRules.forEach((rule) => {
      const element = document.getElementById(`description-${rule.id}`);
      if (element) {
        const scrollHeight = element.scrollHeight;
        if (contentHeight[rule.id] !== scrollHeight) {
          this.setState((prevState) => ({
            contentHeight: {
              ...prevState.contentHeight,
              [rule.id]: scrollHeight,
            },
          }));
        }
      }
    });
  };

  toggleExpandCard = (id) => {
    this.setState((prevState) => ({
      expandedCards: {
        ...prevState.expandedCards,
        [id]: !prevState.expandedCards[id],
      },
    }));
  };

  renderPlatforms = (platforms) => {
    
    const activePlatforms = Object.keys(platforms[0])
      .filter((key) => platforms[0][key] === true && key !== "blueRuleId" && key !== "id")
      .map((key) => {
        const platformObj = Platforms.find((p) => p.key === key);
        return platformObj ? platformObj.platform : null;
      })
      .filter((icon) => icon !== null);

    return activePlatforms.map((icon, index) => (
      <div key={index} style={{ marginLeft: 8, fontSize: "35px" }}>
        {icon}
      </div>
    ));
  };

  getStatusColor = (status) => {
    const statusOption = StatusOptions.find(option => option.key === status);
    return statusOption ? statusOption.color : "light";
  };

  handleCardClick = (rule) => {
    this.setState({ selectedRule: rule }); // Set the selected rule for detailed view
  };

  closeBlueRule = () => {
    this.setState({ selectedRule: null }); // Reset the selected rule
  };

  togglePlatformFilter(platformKey) {
    this.setState((prevState) => ({
      platformFilter: prevState.platformFilter === platformKey ? "" : platformKey,
    }));
  }

  toggleRuleTypeFilter(ruleTypeKey) {
    this.setState((prevState) => ({
      ruleTypeFilter: prevState.ruleTypeFilter === ruleTypeKey ? "" : ruleTypeKey,
    }));
  }

  toggleStatusFilter(statusKey) {
    this.setState((prevState) => ({
      statusFilter: prevState.statusFilter === statusKey ? "" : statusKey,
    }));
  }

  handleCreatorChange(event) {
    this.setState({ creatorFilter: event.target.value });
  }

  handleCategoryChange(event) {
    this.setState({ categoryFilter: event.target.value });
  }

  filterByPlatform(rule) {
    if (!this.state.platformFilter) return true; // If no platform filter selected, show all
    const platforms = rule.blueRulesPlatforms[0];
    return platforms && platforms[this.state.platformFilter] === true;
  }

  filterByCreator(rule) {
    if (!this.state.creatorFilter) return true; // If no creator filter selected, show all
    return rule.creator === this.state.creatorFilter;
  }

  filterByRuleType(rule) {
    if (!this.state.ruleTypeFilter) return true; // If no rule type filter selected, show all
    const ruleType = this.state.ruleTypeFilter;
    return rule[ruleType] && rule[ruleType].length > 0;
  }

  filterByCategory(rule) {
    if (!this.state.categoryFilter) return true; // If no category filter selected, show all
    return rule.ruleCategory === this.state.categoryFilter;
  }

  filterByStatus(rule) {
    if (!this.state.statusFilter) return true; // If no status filter selected, show all
    return rule.status === this.state.statusFilter;
  }

  filterByNotRunInClients(rule) {
    if (!this.state.notRunInClients || this.state.notRunInClients.length === 0) return true;
    
    const clients = this.state.rulesWithClients[rule.id] || [];
    
    // Rule should be included if it is NOT implemented in ANY of the selected clients
    return this.state.notRunInClients.every(client => !clients.includes(client));
  }

  toggleClientsDropdown() {
    this.setState(prevState => ({
      clientsDropdownOpen: !prevState.clientsDropdownOpen
    }));
  }

  handleNotRunInChange(selectedOptions) {
    this.setState({ notRunInClients: selectedOptions ? selectedOptions.map(option => option.value) : [] });
  }

  async loadRuleClientData(rulesWithClients) {
    try {
      const [clientRelations, blueRules] = await Promise.all([
        sdk.blueRuleClients.get(),
        sdk.blueRules.get({ fields: ['id', 'silverBulletId'] })
      ]);
      
      // Create a mapping of rule_id to array of client names
      clientRelations.forEach(relation => {
        if (!rulesWithClients[relation.rule_id]) {
          rulesWithClients[relation.rule_id] = [];
        }
        rulesWithClients[relation.rule_id].push(relation.client_name);
      });

      // Create a mapping of silverBulletId to array of client names
      const silverBulletClients = {};
      blueRules.forEach(rule => {
        if (rule.silverBulletId && rulesWithClients[rule.id]) {
          if (!silverBulletClients[rule.silverBulletId]) {
            silverBulletClients[rule.silverBulletId] = new Set();
          }
          rulesWithClients[rule.id].forEach(client => {
            silverBulletClients[rule.silverBulletId].add(client);
          });
        }
      });

      // Convert Sets to Arrays for the silverBulletClients
      Object.keys(silverBulletClients).forEach(key => {
        silverBulletClients[key] = Array.from(silverBulletClients[key]);
      });
      
      this.setState({ 
        rulesWithClients,
        silverBulletClients // Add this to state for filtering
      });

      return rulesWithClients;
    } catch (error) {
      console.error("Error loading rule client data:", error);
      return {};
    }
  }

  render() {
    const { 
      loading, 
      blueRules, 
      expandedCards, 
      contentHeight, 
      selectedRule, 
      platformFilter, 
      creatorFilter,
      ruleTypeFilter,
      statusFilter,
      categoryFilter,
      categories,
      notRunInClients,
    } = this.state;

    const filteredBlueRules = blueRules.filter((rule) => 
      this.filterByPlatform(rule) && 
      this.filterByCreator(rule) && 
      this.filterByRuleType(rule) &&
      this.filterByCategory(rule) &&
      this.filterByStatus(rule) &&
      this.filterByNotRunInClients(rule)
    );

    return (
      <div 
        className="blue-rules-container"
        style={{ 
          display: "flex", 
          height: "100%", // Changed from 100vh to 100%
          overflow: "hidden",
          position: "relative",
          width: "100%"
        }}
      >
        {selectedRule && (
          <div
            style={{
              flex: 1,
              overflowY: "auto",
            }}
          >
            {/* Render the selected BlueRule */}
            <BlueRule rule={selectedRule} closeFunc={this.closeBlueRule} />
          </div>
        )}
        <div
          className="blue-rules-content"
          style={{
            flex: selectedRule ? 1 : 2,
            overflowY: "auto",
            overflowX: "hidden",
            padding: "20px",
            position: "relative",
            maxWidth: "100%",
            height: "100%",
            maxHeight: "100%"
          }}
        >
          {/* Platform Filter Buttons */}
          <div className="filter-container mb-3" style={{ marginBottom: "20px" }}>
            <h3 className="font-light pt-1 mr-1" style={{ color: "white" }}>
              Platforms
            </h3>
            <ButtonGroup>
              {Platforms.map((platform) => (
                <Button
                  key={platform.key}
                  color="primary"
                  style={{ color: "white" }}
                  active={platformFilter === platform.key}
                  onClick={() => this.togglePlatformFilter(platform.key)}
                >
                  {platform.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>

          {/* Rule Type Filter Buttons */}
          <div className="filter-container mb-3" style={{ marginBottom: "20px" }}>
            <h3 className="font-light pt-1 mr-1" style={{ color: "white" }}>
              Rule Types
            </h3>
            <ButtonGroup>
              {RuleTypes.map((ruleType) => (
                <Button
                  key={ruleType.key}
                  color="info"
                  style={{ color: "white" }}
                  active={ruleTypeFilter === ruleType.key}
                  onClick={() => this.toggleRuleTypeFilter(ruleType.key)}
                >
                  {ruleType.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>

          {/* Status Filter Buttons */}
          <div className="filter-container mb-3" style={{ marginBottom: "20px" }}>
            <h3 className="font-light pt-1 mr-1" style={{ color: "white" }}>
              Status
            </h3>
            <ButtonGroup>
              {StatusOptions.map((status) => (
                <Button
                  key={status.key}
                  color={status.color}
                  style={{ color: "white" }}
                  active={statusFilter === status.key}
                  onClick={() => this.toggleStatusFilter(status.key)}
                >
                  {status.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>

          {/* Not Run In Filter Dropdown - Completely redesigned */}
          <div className="custom-filter-container mb-4" style={{ 
            marginBottom: "20px",
            position: "relative"
          }}>
            <h3 className="font-light pt-1 mb-3" style={{ color: "white" }}>
              Not Run In
            </h3>
            <div className="client-filter-wrapper" style={{
              position: "relative",
              width: "100%",
              maxWidth: "550px"
            }}>
              <Select
                isMulti
                name="notRunInClients"
                options={clientOptions.map(client => ({ value: client, label: client }))}
                className="client-select"
                classNamePrefix="client-select"
                placeholder="Select clients to filter rules NOT implemented in"
                value={notRunInClients.map(client => ({ value: client, label: client }))}
                onChange={this.handleNotRunInChange}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#34383f",
                    borderColor: "#6c757d",
                    borderRadius: "6px",
                    boxShadow: "none",
                    "&:hover": {
                      borderColor: "#5cb85c",
                    },
                    padding: "2px",
                    minHeight: "45px",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#5a6268",
                    borderRadius: "4px",
                    margin: "3px",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                    fontSize: "0.9rem",
                    padding: "2px 6px",
                  }),
                  multiValueRemove: (provided) => ({
                    ...provided,
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#dc3545",
                      color: "white",
                    },
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#444",
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    padding: "5px",
                    maxHeight: "200px",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? "#5cb85c" : "#444",
                    color: "white",
                    cursor: "pointer",
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: "#5cb85c",
                    },
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#adb5bd",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                }}
              />
            </div>
            {notRunInClients.length > 0 && (
              <div className="mt-2">
                <button 
                  className="btn btn-sm btn-outline-danger"
                  onClick={() => this.setState({ notRunInClients: [] })}
                >
                  <i className="fas fa-times mr-1"></i> Clear Selection
                </button>
              </div>
            )}
          </div>

          {/* Filters row with Creator and Category */}
          <div className="filter-row d-flex" style={{ marginBottom: "20px", gap: "20px" }}>
            {/* Creator Filter Dropdown */}
            <div className="filter-container" style={{ flex: 1 }}>
              <h3 style={{ color: "white", marginBottom: "10px" }}>
                Creator:
              </h3>
              <select
                className="form-control"
                value={creatorFilter}
                onChange={this.handleCreatorChange}
              >
                <option value="">All Creators</option>
                {Creators.map((creator) => (
                  <option key={creator} value={creator}>
                    {creator}
                  </option>
                ))}
              </select>
              {creatorFilter && (
                <div className="mt-2 text-white">
                  <small>Active filter: <span className="badge badge-primary">{creatorFilter}</span></small>
                </div>
              )}
            </div>

            {/* Category Filter Dropdown */}
            <div className="filter-container" style={{ flex: 1 }}>
              <h3 style={{ color: "white", marginBottom: "10px" }}>
                Category:
              </h3>
              <select
                className="form-control"
                value={categoryFilter}
                onChange={this.handleCategoryChange}
              >
                <option value="">All Categories</option>
                {categories && categories.length > 0 ? (
                  categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))
                ) : (
                  <option disabled>No categories found</option>
                )}
              </select>
              {categoryFilter && (
                <div className="mt-2 text-white">
                  <small>Active filter: <span className="badge badge-primary">{categoryFilter}</span></small>
                </div>
              )}
            </div>
          </div>

          {/* Active Filters section */}
          {(platformFilter || ruleTypeFilter || statusFilter || creatorFilter || categoryFilter || notRunInClients.length > 0) && (
            <div className="active-filters mb-3 p-2" style={{ backgroundColor: "rgba(255,255,255,0.1)", borderRadius: "5px" }}>
              <h4 style={{ color: "white" }}>Active Filters:</h4>
              <div className="d-flex flex-wrap">
                {platformFilter && (
                  <span className="badge badge-primary m-1 p-2">
                    Platform: {(() => {
                      const platform = Platforms.find(p => p.key === platformFilter);
                      return platform ? platform.name : platformFilter;
                    })()}
                    <button 
                      className="btn btn-sm ml-2 p-0" 
                      style={{ background: "none", border: "none", color: "white" }}
                      onClick={() => this.togglePlatformFilter(platformFilter)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </span>
                )}
                {ruleTypeFilter && (
                  <span className="badge badge-info m-1 p-2">
                    Type: {(() => {
                      const ruleType = RuleTypes.find(t => t.key === ruleTypeFilter);
                      return ruleType ? ruleType.name : ruleTypeFilter;
                    })()}
                    <button 
                      className="btn btn-sm ml-2 p-0" 
                      style={{ background: "none", border: "none", color: "white" }}
                      onClick={() => this.toggleRuleTypeFilter(ruleTypeFilter)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </span>
                )}
                {statusFilter && (
                  <span className={`badge badge-${this.getStatusColor(statusFilter)} m-1 p-2`}>
                    Status: {statusFilter}
                    <button 
                      className="btn btn-sm ml-2 p-0" 
                      style={{ background: "none", border: "none", color: "white" }}
                      onClick={() => this.toggleStatusFilter(statusFilter)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </span>
                )}
                {creatorFilter && (
                  <span className="badge badge-success m-1 p-2">
                    Creator: {creatorFilter}
                    <button 
                      className="btn btn-sm ml-2 p-0" 
                      style={{ background: "none", border: "none", color: "white" }}
                      onClick={() => this.setState({ creatorFilter: "" })}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </span>
                )}
                {categoryFilter && (
                  <span className="badge badge-warning m-1 p-2">
                    Category: {categoryFilter}
                    <button 
                      className="btn btn-sm ml-2 p-0" 
                      style={{ background: "none", border: "none", color: "white" }}
                      onClick={() => this.setState({ categoryFilter: "" })}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </span>
                )}
                {notRunInClients.length > 0 && (
                  <span className="badge badge-danger m-1 p-2" style={{ 
                    borderRadius: "4px",
                    fontSize: "0.9rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    backgroundColor: "#dc3545"
                  }}>
                    <span style={{ fontWeight: "bold" }}>Not Run In:</span> {notRunInClients.join(', ')}
                    <button 
                      className="btn btn-sm p-0 ml-2" 
                      style={{ 
                        background: "none", 
                        border: "none", 
                        color: "white", 
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(255,255,255,0.2)"
                      }}
                      onClick={() => this.setState({ notRunInClients: [] })}
                    >
                      <i className="fas fa-times" style={{ fontSize: "10px" }}></i>
                    </button>
                  </span>
                )}
                <button 
                  className="btn btn-sm btn-outline-light ml-auto"
                  onClick={() => this.setState({ 
                    platformFilter: "", 
                    ruleTypeFilter: "", 
                    statusFilter: "",
                    creatorFilter: "", 
                    categoryFilter: "",
                    notRunInClients: []
                  })}
                >
                  Clear All Filters
                </button>
              </div>
            </div>
          )}

          {loading ? (
            <div className="text-center w-100">
              <i className="fas fa-spin fa-spinner" style={{ fontSize: "24px" }} />
            </div>
          ) : filteredBlueRules.length === 0 ? (
            <h1 className="text-center w-100">No Blue Rules</h1>
          ) : (
            <Row>
              {filteredBlueRules.map((rule) => {
                return (
                  <Col lg="6" key={rule.id} className="mb-4">
                    <Card
                      style={{
                        boxShadow: "2px 3px 1px rgba(0,0,0,0.2)",
                        backgroundColor: "#424953",
                        color: "white",
                        position: "relative",
                        cursor: "pointer",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column"
                      }}
                      onClick={() => this.handleCardClick(rule)}
                    >
                      <div className="card-content" style={{ padding: "15px" }}>
                        <div className="card-header">
                          <div>
                            <CardTitle tag="h3" style={{ marginBottom: "10px" }}>{rule.name}</CardTitle>
                            <CardSubtitle tag="h5">
                              Category: {rule.ruleCategory}
                            </CardSubtitle>
                            <p style={{ margin: 0 }}>Creator: {rule.creator}</p>
                            {rule.status && (
                              <Badge color={this.getStatusColor(rule.status)} style={{ marginTop: "8px", fontSize: "0.85rem" }}>
                                {rule.status}
                              </Badge>
                            )}
                          </div>
                          <div style={{ display: "flex", flexWrap: "nowrap" }}>
                            {this.renderPlatforms(rule.blueRulesPlatforms)}
                          </div>
                        </div>
                        
                        <div
                          id={`description-${rule.id}`}
                          className="card-description"
                          style={{
                            maxHeight: expandedCards[rule.id] ? "none" : "50px",
                            overflow: "hidden",
                            transition: "max-height 0.5s ease",
                          }}
                        >
                          {rule.description}
                        </div>

                        {/* Clients Section */}
                        <div className="card-clients">
                          <small style={{ color: "#adb5bd", marginBottom: "0.5rem", display: "block" }}>
                            <i className="fas fa-building mr-1"></i> Implemented in:
                          </small>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {this.state.rulesWithClients[rule.id] && this.state.rulesWithClients[rule.id].length > 0 ? (
                              this.state.rulesWithClients[rule.id].map((client, index) => (
                                <span key={index} className="client-badge">
                                  {client}
                                </span>
                              ))
                            ) : (
                              <span className="client-badge" style={{ backgroundColor: "#6c757d" }}>
                                No clients specified
                              </span>
                            )}
                          </div>
                        </div>

                        {contentHeight[rule.id] && contentHeight[rule.id] > 50 && (
                          <div
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              padding: "5px",
                              marginTop: "10px"
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.toggleExpandCard(rule.id);
                            }}
                          >
                            <i
                              className={`fas fa-chevron-${
                                expandedCards[rule.id] ? "up" : "down"
                              }`}
                            ></i>
                          </div>
                        )}
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default BlueRuleMenu;
