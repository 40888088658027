import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, IconButton } from '@mui/material';
import { FaEdit } from 'react-icons/fa';

const RunDateCell = ({ history, updateHistory, isEditable }) => {
    const [open, setOpen] = useState(false);
    // Store the date in a format compatible with <input type="date" />
    const initialDate = history.runDate ? new Date(history.runDate).toISOString().split('T')[0] : '';
    const [runDate, setRunDate] = useState(initialDate);

    const handleOpen = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleSave = async () => {
        // Convert to a standard date string or keep as is
        const updatedHistory = { ...history, runDate };
        await updateHistory(updatedHistory);
        handleClose();
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-GB');
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{formatDate(history.runDate)}</span>
            {isEditable && (
                <IconButton
                    size="small"
                    onClick={handleOpen}
                    style={{ marginLeft: '8px', color: 'white' }}
                >
                    <FaEdit />
                </IconButton>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Run Date</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select a new run date below:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Run Date"
                        type="date"
                        fullWidth
                        value={runDate}
                        onChange={(e) => setRunDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        onKeyDown={(e) => {
                            // optional: allow "Enter" to save
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSave();
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave} variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RunDateCell;
