import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, IconButton } from '@mui/material';
import { FaEdit } from 'react-icons/fa';

const RunNumberCell = ({ history, updateHistory, isEditable }) => {
  const [open, setOpen] = useState(false);
  const [runNumber, setRunNumber] = useState(history.runNumber || '');

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    // Convert to integer if desired
    const updatedHistory = { ...history, runNumber: runNumber.trim() };
    await updateHistory(updatedHistory);
    handleClose();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{history.runNumber}</span>
      {isEditable && (
        <IconButton
          size="small"
          onClick={handleOpen}
          style={{ marginLeft: '8px', color: 'white' }}
        >
          <FaEdit />
        </IconButton>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Run Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the new run number below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Run Number"
            type="number"
            fullWidth
            value={runNumber}
            onChange={(e) => setRunNumber(e.target.value)}
            onKeyDown={(e) => {
              // optional: allow "Enter" to save
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSave();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RunNumberCell;
