import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

class AddSyslog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      syslogText: props.syslogText || "",
      prevPropsSyslogText: props.syslogText || ""
    };

    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Only update state from props if the prop actually changed
    if (nextProps.syslogText !== prevState.prevPropsSyslogText) {
      return {
        syslogText: nextProps.syslogText || "",
        prevPropsSyslogText: nextProps.syslogText
      };
    }
    return null; // No state update needed
  }

  handleChange(e) {
    const syslogText = e.target.value;
    this.setState({ syslogText }, () => {
      if (this.props.onSyslogChange) {
        this.props.onSyslogChange(syslogText);
      }
    });
  }

  render() {
    return (
      <div>
        <FormGroup>
          <Label for="syslogXml">Enter Syslog:</Label>
          <Input
            type="textarea"
            id="syslogXml"
            rows="10"
            value={this.state.syslogText}
            onChange={this.handleChange}
          />
        </FormGroup>
      </div>
    );
  }
}

export default AddSyslog; 