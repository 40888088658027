import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    UncontrolledAlert,
    Nav,
    NavItem,
    NavLink,
    Alert
} from "reactstrap";
import { Link } from "react-router-dom";
import sdk from "api-sdk";
import userService from "../../services/user";
import categories from "../../assets/data/attackVectors.json";
import platformsData from "../../assets/data/platformsBlueRule.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import classnames from "classnames";
import AddCortex from "../AddCortex/AddCortex";
import AddSysmon from "../AddSysmon/AddSysmon";
import AddSplunk from "../AddSplunk/AddSplunk";
import AddQradar from "../AddQradar/AddQradar";
import AddSyslog from "../AddSyslog/AddSyslog";
import PlaybookTab from "../PlaybookTab/PlaybookTab";
import AddCortexPlaybook from "../PlaybookTab/AddCortexPlaybook";
import Select from 'react-select';

// Extract the rule categories from the categories file
const ruleCategories = categories.map(category => category.title);

const clientOptions = [
    { value: 'Grupo Salinas', label: 'Grupo Salinas' },
    { value: 'Tipico', label: 'Tipico' },
    { value: 'Abrdn', label: 'Abrdn' },
    { value: 'Telefonica', label: 'Telefonica' },
    { value: 'SinExpress', label: 'SinExpress' },
    { value: 'MOJ', label: 'MOJ' },
    { value: 'Mapi', label: 'Mapi' },
    { value: 'RamiChil', label: 'RamiChil' },
    { value: 'Altshuller', label: 'Altshuller' },
    { value: 'Isracard', label: 'Isracard' },
    { value: 'Mataf', label: 'Mataf' },
    { value: 'Jerusalem College', label: 'Jerusalem College' },
    { value: 'Shva', label: 'Shva' },
    { value: 'Pool', label: 'Pool' }
];

class BlueRuleEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            updating: false,
            uploadError: undefined,
            ruleCategory: undefined,
            ruleCategoryError: undefined,
            status: undefined,
            statusError: undefined,
            creator: undefined,
            creatorError: undefined,
            ruleName: undefined,
            nameError: undefined,
            ruleDescription: undefined,
            ruleDescriptionError: undefined,
            ruleSummary: undefined,
            ruleSummaryError: undefined,
            mitre: undefined,
            mitreError: undefined,
            adminFeedback: "",
            
            // Files
            chartFile: undefined,
            currentChartFileName: undefined,

            chosenPlatforms: {},

            // We store the DS data here
            cortexData: null,
            sysmonData: null,
            splunkData: null,
            qradarData: null,
            syslogData: "",

            // Playbook data
            sysmonPlaybookData: "",
            cortexPlaybookData: "",
            splunkPlaybookData: "",
            qradarPlaybookData: "",
            syslogPlaybookData: "",

            // tabs
            activeTab: "1",
            silverBullets: [],
            selectedSilverBullet: null,
            silverBulletSearch: "",
            allSilverBullets: [],
            silverBulletInputValue: "",
            
            // Original rule data for reference
            originalRule: null,
            selectedClients: [],
            
            // User role info
            isBlueTeamAdmin: false,
            approvalLoading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCortexChange = this.handleCortexChange.bind(this);
        this.handleSysmonChange = this.handleSysmonChange.bind(this);
        this.handleSplunkChange = this.handleSplunkChange.bind(this);
        this.handleQradarChange = this.handleQradarChange.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.loadBlueRuleData = this.loadBlueRuleData.bind(this);
        this.loadAllSilverBullets = this.loadAllSilverBullets.bind(this);
        this.handleSilverBulletSearchSelect = this.handleSilverBulletSearchSelect.bind(this);
        this.handleSilverBulletSelection = this.handleSilverBulletSelection.bind(this);
        this.handleSyslogChange = this.handleSyslogChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.handleApproveRule = this.handleApproveRule.bind(this);
        this.checkUserRole = this.checkUserRole.bind(this);
        
        // Playbook handlers
        this.handleSysmonPlaybookChange = this.handleSysmonPlaybookChange.bind(this);
        this.handleCortexPlaybookChange = this.handleCortexPlaybookChange.bind(this);
        this.handleSplunkPlaybookChange = this.handleSplunkPlaybookChange.bind(this);
        this.handleQradarPlaybookChange = this.handleQradarPlaybookChange.bind(this);
        this.handleSyslogPlaybookChange = this.handleSyslogPlaybookChange.bind(this);
    }

    handleCortexChange(cortexData) {
        this.setState({ cortexData });
    }

    clearCortexData = () => {
        if (window.confirm("Are you sure you want to clear all CrowdStrike data? This will remove it from the Blue Rule.")) {
            this.setState({ cortexData: null });
        }
    }

    handleSysmonChange(xmlText) {
        this.setState({ sysmonData: xmlText && xmlText.trim() !== "" ? xmlText : null });
    }

    clearSysmonData = () => {
        if (window.confirm("Are you sure you want to clear all Sysmon data? This will remove it from the Blue Rule.")) {
            this.setState({ sysmonData: null });
        }
    }

    handleSplunkChange(splunkText) {
        this.setState({ splunkData: splunkText && splunkText.trim() !== "" ? splunkText : null });
    }

    clearSplunkData = () => {
        if (window.confirm("Are you sure you want to clear all Splunk data? This will remove it from the Blue Rule.")) {
            this.setState({ splunkData: null });
        }
    }

    handleQradarChange(qradarText) {
        this.setState({ qradarData: qradarText && qradarText.trim() !== "" ? qradarText : null });
    }

    clearQradarData = () => {
        if (window.confirm("Are you sure you want to clear all Qradar data? This will remove it from the Blue Rule.")) {
            this.setState({ qradarData: null });
        }
    }

    handleSyslogChange(syslogText) {
        this.setState({ syslogData: syslogText });
    }

    handleCategoryChange = (e) => {
        const selectedCategory = e.target.value.trim();
        this.setState({ ruleCategory: selectedCategory, ruleCategoryError: undefined });
    };

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    validateForm() {
        const errors = {};

        if (!this.state.ruleName) {
            errors.nameError = "Rule Name required";
        }

        if (!this.state.ruleCategory) {
            errors.ruleCategoryError = "Rule Category required";
        }

        if (!this.state.status) {
            errors.statusError = "Status required";
        }

        if (!this.state.creator) {
            errors.creatorError = "Creator required";
        }

        if (!this.state.ruleDescription) {
            errors.ruleDescriptionError = "Rule Description required";
        }

        if (!this.state.ruleSummary) {
            errors.ruleSummaryError = "Rule Summary required";
        }

        return {
            isValid: Object.keys(errors).length === 0,
            errors
        };
    }

    loadAllSilverBullets() {
        this.setState({ creating: true });
        Promise.all([
            sdk.silverBullets.getAll(),
            sdk.blueRules.get({ fields: ['id', 'name', 'silverBulletId'] })
        ])
        .then(([bullets, blueRules]) => {
            // Create a map of silverBulletId to blueRule name
            const associatedBullets = {};
            blueRules.forEach(rule => {
                if (rule && rule.silverBulletId && rule.id !== (this.state.rule && this.state.rule.id)) {
                    associatedBullets[rule.silverBulletId] = rule.name;
                }
            });

            // Add warning message to bullets that are already associated
            const bulletsWithWarnings = bullets.filter(bullet => bullet && bullet.id).map(bullet => ({
                ...bullet,
                warning: associatedBullets[bullet.id] ? 
                    `This Silver Bullet is already associated with Blue Rule: ${associatedBullets[bullet.id]}` : 
                    null
            }));

            this.setState({ 
                allSilverBullets: bulletsWithWarnings, 
                silverBullets: bulletsWithWarnings,
                creating: false 
            });
        })
        .catch(error => {
            console.error("Failed to fetch SilverBullets:", error);
            toast.error("Failed to fetch SilverBullets.");
            this.setState({ 
                creating: false,
                allSilverBullets: [],
                silverBullets: []
            });
        });
    }

    handleSilverBulletSearchSelect = (e) => {
        const searchValue = e.target.value;
        this.setState({ silverBulletInputValue: searchValue });
        
        // Find if the input matches any bullet exactly
        const matchedBullet = this.state.allSilverBullets.find(
            bullet => bullet.name.toLowerCase() === searchValue.toLowerCase()
        );
        
        if (matchedBullet) {
            if (matchedBullet.warning) {
                toast.warning(matchedBullet.warning);
                this.setState({ 
                    selectedSilverBullet: null,
                    silverBulletInputValue: "" // Reset the input value
                });
            } else {
                this.setState({ selectedSilverBullet: matchedBullet.id });
            }
        } else {
            // If no exact match, filter the list but don't set a selected bullet
            this.setState({ selectedSilverBullet: null });
        }
    }

    // This handles when a user selects an option from the datalist
    handleSilverBulletSelection = (e) => {
        const selectedValue = e.target.value;
        const selectedBullet = this.state.allSilverBullets.find(
            bullet => bullet.name === selectedValue
        );
        
        if (selectedBullet) {
            if (selectedBullet.warning) {
                toast.warning(selectedBullet.warning);
                this.setState({ 
                    selectedSilverBullet: null,
                    silverBulletInputValue: "" // Reset the input value
                });
            } else {
                this.setState({ 
                    selectedSilverBullet: selectedBullet.id,
                    silverBulletInputValue: selectedValue
                });
            }
        }
    }

    handleClientChange(selectedOptions) {
        this.setState({ selectedClients: selectedOptions || [] });
    }

    async loadBlueRuleData() {
        try {
            const ruleId = this.props.match.params.id;
            // Get the blue rule with related data
            const rule = await sdk.blueRules.getById(ruleId, {
                include: ["blueRulesPlatforms", "sysmon", "cortex", "splunk", "qradar"]
            });
            console.log('Rule data: ', rule);
            console.log('Chart URL in API response:', {
                hasChartUrl: !!rule.chartUrl,
                chartUrl: rule.chartUrl,
                rule: JSON.stringify(rule)
            });
            
            // Check if chart exists for this rule
            try {
                const chartResponse = await sdk.blueRules.getChartBase64(ruleId);
                if (chartResponse && chartResponse.base64Chart) {
                    // Store the base64 data directly
                    rule.chartBase64 = chartResponse.base64Chart;
                    // Also set the URL for direct API access
                    const baseUrl = window.location.origin;
                    rule.chartUrl = `${baseUrl}/api/eye-of-the-enemy/BlueRules/${ruleId}/chart`;
                    console.log("Chart found:", {
                        hasBase64: !!rule.chartBase64,
                        chartUrl: rule.chartUrl
                    });
                }
            } catch (error) {
                console.error("No chart found for this rule:", error);
                rule.chartUrl = null;
                rule.chartBase64 = null;
            }
            
            // First load all silver bullets
            try {
                const bullets = await sdk.silverBullets.getAll();
                this.setState({ 
                    allSilverBullets: bullets, 
                    silverBullets: bullets
                });

                // After bullets are loaded, find the bullet name if there's a selected silver bullet ID
                if (rule.silverBulletId) {
                    const selectedBullet = bullets.find(bullet => bullet.id === rule.silverBulletId);
                    if (selectedBullet) {
                        this.setState({ silverBulletInputValue: selectedBullet.name });
                    }
                }
            } catch (error) {
                console.error("Failed to fetch SilverBullets:", error);
                toast.error("Failed to fetch SilverBullets.");
            }

            // Setup chosen platforms
            const chosenPlatforms = {};
            if (rule.blueRulesPlatforms && rule.blueRulesPlatforms.length > 0) {
                // Get the first platform entry (assuming there's only one per rule)
                const platformData = rule.blueRulesPlatforms[0];
                console.log('Found platform data in API response:', platformData);
                
                // Set each platform property
                platformsData.forEach(platform => {
                    chosenPlatforms[platform.propName] = platformData[platform.propName] === true;
                });
                console.log('Initialized chosenPlatforms:', chosenPlatforms);
            } else {
                console.log('No platform data found in API response');
            }

            // Process cortex data if available
            let cortexData = null;
            if (rule.cortex && rule.cortex.length > 0) {
                // Group cortex entries by rule_type
                const cortexByType = rule.cortex.reduce((acc, entry) => {
                    if (!acc[entry.rule_type]) {
                        acc[entry.rule_type] = [];
                    }
                    acc[entry.rule_type].push(entry);
                    return acc;
                }, {});

                // Use the first entry's rule_type as cortexType
                const cortexType = Object.keys(cortexByType)[0];
                const fieldValues = {};

                // Process each entry based on cortexType
                if (cortexType === "Custom IOA Rule") {
                    // Handle custom IOA separately
                    const dynamicFields = cortexByType[cortexType]
                        .filter(entry => entry.fields !== "Testing String")
                        .map(entry => ({
                            field: entry.fields,
                            value: entry.string_value || entry.bool_value
                        }));

                    const testingStringEntry = cortexByType[cortexType].find(entry => entry.fields === "Testing String");
                    
                    cortexData = {
                        cortexType,
                        customIoaData: {
                            dynamicFields,
                            testingString: testingStringEntry ? testingStringEntry.string_value : ""
                        }
                    };
                } else {
                    // Handle other cortex types
                    cortexByType[cortexType].forEach(entry => {
                        fieldValues[entry.fields] = entry.string_value !== null ? entry.string_value : entry.bool_value;
                    });

                    cortexData = {
                        cortexType,
                        cortexSubRule: rule.cortex[0].rule_type,
                        fieldValues
                    };
                }
            }

            // Get the data for each tab
            const sysmonData = rule.sysmon && rule.sysmon.length > 0 ? rule.sysmon[0].xmlText : null;
            const splunkData = rule.splunk && rule.splunk.length > 0 ? rule.splunk[0].xmlText : null;
            const qradarData = rule.qradar && rule.qradar.length > 0 ? rule.qradar[0].xmlText : null;

            // Load syslog data if it exists
            sdk.syslog.get({ where: { rule_id: ruleId }})
                .then(syslogData => {
                    if (syslogData && syslogData.length > 0) {
                        this.setState({ syslogData: syslogData[0].xmlText });
                    }
                })
                .catch(error => {
                    console.error("Error loading syslog data:", error);
                });

            // Load client data if it exists
            sdk.blueRuleClients.getByRuleId(ruleId)
                .then(clients => {
                    if (clients && clients.length > 0) {
                        const selectedClients = clients.map(client => ({
                            value: client.client_name,
                            label: client.client_name
                        }));
                        this.setState({ selectedClients });
                    }
                })
                .catch(error => {
                    console.error("Error loading client data:", error);
                });

            // Load playbook data for each tab
            try {
                // Load Sysmon Playbook
                const sysmonPlaybooks = await sdk.sysmonPlaybook.get({ where: { rule_id: ruleId } });
                if (sysmonPlaybooks && sysmonPlaybooks.length > 0) {
                    this.setState({ sysmonPlaybookData: sysmonPlaybooks[0].playbookText });
                }

                // Load Cortex Playbook
                const cortexPlaybooks = await sdk.cortexPlaybook.get({ where: { rule_id: ruleId } });
                if (cortexPlaybooks && cortexPlaybooks.length > 0) {
                    // Group cortex playbook entries by rule_type
                    const playbookByType = cortexPlaybooks.reduce((acc, entry) => {
                        if (!acc[entry.rule_type]) {
                            acc[entry.rule_type] = [];
                        }
                        acc[entry.rule_type].push(entry);
                        return acc;
                    }, {});

                    if (Object.keys(playbookByType).length > 0) {
                        // Use the first entry's rule_type as cortexType
                        const cortexType = Object.keys(playbookByType)[0];
                        const fieldValues = {};

                        // Process each entry based on cortexType
                        if (cortexType === "Custom IOA Rule") {
                            // Handle custom IOA separately
                            const dynamicFields = playbookByType[cortexType]
                                .filter(entry => entry.fields !== "Testing String")
                                .map(entry => ({
                                    field: entry.fields,
                                    value: entry.string_value || entry.bool_value
                                }));

                            const testingStringEntry = playbookByType[cortexType].find(entry => entry.fields === "Testing String");
                            
                            this.setState({
                                cortexPlaybookData: {
                                    cortexType,
                                    customIoaData: {
                                        dynamicFields,
                                        testingString: testingStringEntry ? testingStringEntry.string_value : ""
                                    }
                                }
                            });
                        } else {
                            // Handle other cortex types
                            playbookByType[cortexType].forEach(entry => {
                                fieldValues[entry.fields] = entry.string_value !== null ? entry.string_value : entry.bool_value;
                            });

                            this.setState({
                                cortexPlaybookData: {
                                    cortexType,
                                    cortexSubRule: cortexPlaybooks[0].rule_type,
                                    fieldValues
                                }
                            });
                        }
                    }
                }

                // Load Splunk Playbook
                const splunkPlaybooks = await sdk.splunkPlaybook.get({ where: { rule_id: ruleId } });
                if (splunkPlaybooks && splunkPlaybooks.length > 0) {
                    this.setState({ splunkPlaybookData: splunkPlaybooks[0].playbookText });
                }

                // Load Qradar Playbook
                const qradarPlaybooks = await sdk.qradarPlaybook.get({ where: { rule_id: ruleId } });
                if (qradarPlaybooks && qradarPlaybooks.length > 0) {
                    this.setState({ qradarPlaybookData: qradarPlaybooks[0].playbookText });
                }

                // Load Syslog Playbook
                const syslogPlaybooks = await sdk.syslogPlaybook.get({ where: { rule_id: ruleId } });
                if (syslogPlaybooks && syslogPlaybooks.length > 0) {
                    this.setState({ syslogPlaybookData: syslogPlaybooks[0].playbookText });
                }
            } catch (error) {
                console.error("Error loading playbook data:", error);
                toast.error("Failed to load some playbook data.");
            }

            // Update state with the retrieved data
            this.setState({
                originalRule: rule,
                ruleName: rule.name,
                ruleCategory: rule.ruleCategory,
                status: rule.status,
                creator: rule.creator,
                ruleDescription: rule.ruleDescription,
                ruleSummary: rule.ruleSummary,
                mitre: rule.mitre,
                selectedSilverBullet: rule.silverBulletId,
                chosenPlatforms,
                cortexData,
                sysmonData,
                splunkData,
                qradarData,
                adminFeedback: rule.adminFeedback || "", 
                loading: false
            });

        } catch (error) {
            toast.error("Failed to load Blue Rule data.");
            this.setState({ 
                loading: false,
                uploadError: error.toString() 
            });
            console.error("Error loading blue rule:", error);
        }
    }

    componentDidMount() {
        this.loadBlueRuleData();
        this.loadAllSilverBullets();
        this.checkUserRole();
        
        // Set up a timer to log chart URL debug info after data is loaded
        setTimeout(() => {
            console.log("Chart URL debug (after loading):", {
                originalRule: this.state.originalRule,
                chartUrl: this.state.originalRule ? this.state.originalRule.chartUrl : null,
                hasChartFile: !!this.state.chartFile,
                chartFileName: this.state.chartFile ? this.state.chartFile.name : null
            });
        }, 2000);
    }

    // Add a method to check if the user is a blue team admin
    checkUserRole() {
        const userRoles = userService.getRoles();
        const isBlueTeamAdmin = userRoles.includes('blue_team_admin') || userRoles.includes('root');
        this.setState({ isBlueTeamAdmin });
    }

    // Add a method to handle approving the rule
    handleApproveRule() {
        const { id } = this.props.match.params;
        this.setState({ approvalLoading: true });
        
        // Clear admin feedback when approving the rule
        const updateData = {
            status: 'Completed',
            adminFeedback: "" // Clear feedback when rule is approved
        };
        
        sdk.blueRules.update(id, updateData)
            .then(() => {
                toast.success("Blue Rule approved successfully!");
                this.setState({ 
                    status: 'Completed',
                    adminFeedback: "", // Also clear in state
                    approvalLoading: false 
                });
            })
            .catch(err => {
                console.error("Failed to approve BlueRule:", err);
                toast.error("Failed to approve BlueRule");
                this.setState({ approvalLoading: false });
            });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { isValid, errors } = this.validateForm();
        if (!isValid) {
            this.setState(errors);
            return;
        }

        // Check if a non-admin user is trying to edit a Completed rule
        const isCompleted = this.state.originalRule && this.state.originalRule.status === 'Completed';
        const isPlaybookUpdate = this.state.activeTab >= 6; // Check if we're updating a playbook tab

        if (isCompleted && !this.state.isBlueTeamAdmin && !isPlaybookUpdate) {
            this.setState({
                uploadError: "Only Blue Team Admins can edit rules that have been marked as Completed",
                updating: false
            });
            toast.error("Only Blue Team Admins can edit rules that have been marked as Completed");
            return;
        }

        const ruleId = this.props.match.params.id;

        // Build the updated BlueRule object
        const updatedBlueRule = {
            name: this.state.ruleName,
            ruleCategory: this.state.ruleCategory,
            ruleDescription: this.state.ruleDescription,
            ruleSummary: this.state.ruleSummary,
            status: this.state.status,
            creator: this.state.creator,
            mitre: this.state.mitre,
            silverBulletId: this.state.selectedSilverBullet ? this.state.selectedSilverBullet : null,
            adminFeedback: this.state.isBlueTeamAdmin ? this.state.adminFeedback : this.state.originalRule.adminFeedback
        };

        this.setState({ updating: true });

        // If it's a playbook update and user is not admin, only process playbook updates
        if (isCompleted && !this.state.isBlueTeamAdmin && isPlaybookUpdate) {
            // Only process playbook updates
            const promises = [];

            // Sysmon Playbook
            const sysmonPlaybookPromise = sdk.sysmonPlaybook.get({ where: { rule_id: ruleId } })
                .then(playbooks => {
                    if (playbooks && playbooks.length > 0) {
                        if (this.state.sysmonPlaybookData) {
                            return sdk.sysmonPlaybook.update(playbooks[0].id, {
                                playbookText: this.state.sysmonPlaybookData
                            });
                        } else {
                            return sdk.sysmonPlaybook.delete(playbooks[0].id);
                        }
                    } else if (this.state.sysmonPlaybookData) {
                        return sdk.sysmonPlaybook.create({
                            playbookText: this.state.sysmonPlaybookData,
                            rule_id: ruleId
                        });
                    }
                    return Promise.resolve();
                });
            promises.push(sysmonPlaybookPromise);

            // Cortex Playbook
            const cortexPlaybookPromise = sdk.cortexPlaybook.get({ where: { rule_id: ruleId } })
                .then(playbooks => {
                    if (playbooks && playbooks.length > 0) {
                        return Promise.all(playbooks.map(entry => sdk.cortexPlaybook.delete(entry.id)))
                            .then(() => {
                                if (this.state.cortexPlaybookData) {
                                    return this.createCortexPlaybookEntries(ruleId);
                                }
                                return Promise.resolve();
                            });
                    } else if (this.state.cortexPlaybookData) {
                        return this.createCortexPlaybookEntries(ruleId);
                    }
                    return Promise.resolve();
                });
            promises.push(cortexPlaybookPromise);

            // Splunk Playbook
            const splunkPlaybookPromise = sdk.splunkPlaybook.get({ where: { rule_id: ruleId } })
                .then(playbooks => {
                    if (playbooks && playbooks.length > 0) {
                        if (this.state.splunkPlaybookData) {
                            return sdk.splunkPlaybook.update(playbooks[0].id, {
                                playbookText: this.state.splunkPlaybookData
                            });
                        } else {
                            return sdk.splunkPlaybook.delete(playbooks[0].id);
                        }
                    } else if (this.state.splunkPlaybookData) {
                        return sdk.splunkPlaybook.create({
                            playbookText: this.state.splunkPlaybookData,
                            rule_id: ruleId
                        });
                    }
                    return Promise.resolve();
                });
            promises.push(splunkPlaybookPromise);

            // Qradar Playbook
            const qradarPlaybookPromise = sdk.qradarPlaybook.get({ where: { rule_id: ruleId } })
                .then(playbooks => {
                    if (playbooks && playbooks.length > 0) {
                        if (this.state.qradarPlaybookData) {
                            return sdk.qradarPlaybook.update(playbooks[0].id, {
                                playbookText: this.state.qradarPlaybookData
                            });
                        } else {
                            return sdk.qradarPlaybook.delete(playbooks[0].id);
                        }
                    } else if (this.state.qradarPlaybookData) {
                        return sdk.qradarPlaybook.create({
                            playbookText: this.state.qradarPlaybookData,
                            rule_id: ruleId
                        });
                    }
                    return Promise.resolve();
                });
            promises.push(qradarPlaybookPromise);

            // Syslog Playbook
            const syslogPlaybookPromise = sdk.syslogPlaybook.get({ where: { rule_id: ruleId } })
                .then(playbooks => {
                    if (playbooks && playbooks.length > 0) {
                        if (this.state.syslogPlaybookData) {
                            return sdk.syslogPlaybook.update(playbooks[0].id, {
                                playbookText: this.state.syslogPlaybookData
                            });
                        } else {
                            return sdk.syslogPlaybook.delete(playbooks[0].id);
                        }
                    } else if (this.state.syslogPlaybookData) {
                        return sdk.syslogPlaybook.create({
                            playbookText: this.state.syslogPlaybookData,
                            rule_id: ruleId
                        });
                    }
                    return Promise.resolve();
                });
            promises.push(syslogPlaybookPromise);

            // Execute only playbook updates
            Promise.all(promises)
                .then(() => {
                    toast.success("Playbook Updated Successfully");
                    setTimeout(() => {
                        this.props.history.push("/blue-rule-management");
                    }, 2000);
                })
                .catch(e => {
                    console.error("Error updating playbooks:", e);
                    this.setState({
                        updating: false,
                        uploadError: e.toString()
                    });
                    toast.error("Error updating playbooks: " + e.toString());
                });
        } else {
            // Original full update logic for admin users or non-completed rules
            // Debug: Log the cortexData to verify it's being captured correctly
            console.log("CrowdStrike data being updated:", this.state.cortexData);

            this.setState({ updating: true });

            // Debug state before submitting
            console.log('State before API calls:', {
                ruleName: this.state.ruleName,
                ruleCategory: this.state.ruleCategory,
                status: this.state.status,
                chosenPlatforms: this.state.chosenPlatforms
            });

            // 1) Update the BlueRule
            sdk.blueRules.update(ruleId, updatedBlueRule)
                .then(() => {
                    // We'll gather all promises in an array for Promise.all
                    const promises = [];

                    // 2) Upload chart if present
                    if (this.state.chartFile) {
                        promises.push(
                            sdk.blueRules.uploadFile(this.state.chartFile, ruleId, "chart")
                                .catch(error => {
                                    console.error("Error uploading chart:", error);
                                    toast.error("Error uploading chart");
                                })
                        );
                    }
                    // 3) Update chosen platforms
                    console.log('Before platforms check. chosenPlatforms:', this.state.chosenPlatforms);
                    console.log('Is chosenPlatforms truthy:', !!this.state.chosenPlatforms);
                    console.log('Is chosenPlatforms an object:', typeof this.state.chosenPlatforms === 'object');
                    console.log('Object.keys(chosenPlatforms).length:', Object.keys(this.state.chosenPlatforms || {}).length);
                    
                    if (this.state.chosenPlatforms && Object.keys(this.state.chosenPlatforms).length > 0) {
                        console.log('Entering platforms update section');
                        console.log('Current chosenPlatforms state:', this.state.chosenPlatforms);
                        
                        const updatedChosenPlatforms = {
                            ...this.state.chosenPlatforms,
                            blueRuleId: ruleId
                        };
                        
                        console.log('Sending platforms data to API:', updatedChosenPlatforms);
                        
                        // Check if we already have platforms data
                        if (this.state.originalRule.blueRulesPlatforms && 
                            this.state.originalRule.blueRulesPlatforms.length > 0) {
                            // Update existing platforms record
                            const platformId = this.state.originalRule.blueRulesPlatforms[0].id;
                            console.log(`Updating existing platforms with ID: ${platformId}`);
                            promises.push(
                                sdk.blueRulesPlatforms.update(platformId, updatedChosenPlatforms)
                                    .catch(error => {
                                        console.error("Error updating platforms:", error);
                                        toast.error("Error updating platforms");
                                    })
                            );
                        } else {
                            // Create new platforms record
                            console.log("Creating new platforms record");
                            promises.push(
                                sdk.blueRulesPlatforms.create(updatedChosenPlatforms)
                                    .catch(error => {
                                        console.error("Error creating platforms:", error);
                                        toast.error("Error creating platforms");
                                    })
                            );
                        }
                    }

                    // 4) Handle cortex data
                    if (this.state.cortexData) {
                        // First, check if we already have cortex entries we need to clean up
                        let cleanupPromise = Promise.resolve();
                        if (this.state.originalRule.cortex && this.state.originalRule.cortex.length > 0) {
                            // Instead of using delete endpoint, we'll manually delete each entry
                            const deletePromises = this.state.originalRule.cortex.map(entry => {
                                return sdk.cortex.delete(entry.id)
                                    .catch(error => {
                                        console.error(`Error deleting cortex entry ${entry.id}:`, error);
                                    });
                            });
                            cleanupPromise = Promise.all(deletePromises);
                        }

                        // After cleanup (or immediately if no cleanup needed), create new entries
                        promises.push(
                            cleanupPromise.then(() => {
                                const cortexPromises = [];
                                const { cortexType, cortexSubRule, fieldValues, customIoaData } = this.state.cortexData;
                                console.log("Processing CrowdStrike update data:", { cortexType, cortexSubRule, fieldValues, customIoaData });

                                if (cortexType === "Custom IOA Rule") {
                                    // Process dynamic fields from customIoaData
                                    if (customIoaData && Array.isArray(customIoaData.dynamicFields)) {
                                        customIoaData.dynamicFields.forEach(row => {
                                            // Only process rows with a selected field
                                            if (row.field) {
                                                const cortexRow = {
                                                    fields: row.field,
                                                    string_value: typeof row.value === "string" ? row.value : null,
                                                    bool_value: typeof row.value === "boolean" ? row.value : null,
                                                    rule_type: cortexType,
                                                    rule_id: ruleId
                                                };
                                                console.log("Creating cortex entry:", cortexRow);
                                                cortexPromises.push(
                                                    sdk.cortex.create(cortexRow)
                                                        .catch(error => {
                                                            console.error("Error creating cortex entry:", error);
                                                            toast.error(`Error updating CrowdStrike field: ${row.field}`);
                                                        })
                                                );
                                            }
                                        });
                                        if (customIoaData.testingString && customIoaData.testingString.trim() !== "") {
                                            const testingRow = {
                                                fields: "Testing String",
                                                string_value: customIoaData.testingString,
                                                bool_value: null,
                                                rule_type: cortexType,
                                                rule_id: ruleId
                                            };
                                            console.log("Creating testing string entry:", testingRow);
                                            cortexPromises.push(
                                                sdk.cortex.create(testingRow)
                                                    .catch(error => {
                                                        console.error("Error creating testing string entry:", error);
                                                        toast.error("Error updating Testing String field");
                                                    })
                                            );
                                        }
                                    }
                                } else {
                                    // Process the data for other rule types
                                    const entries = Object.entries(fieldValues || {});
                                    if (entries.length > 0) {
                                        entries.forEach(([label, val]) => {
                                            const isBool = typeof val === "boolean";
                                            const cortexRow = {
                                                fields: label,
                                                string_value: isBool ? null : val,
                                                bool_value: isBool ? val : null,
                                                rule_type: cortexSubRule || cortexType,
                                                rule_id: ruleId
                                            };
                                            console.log("Creating cortex entry for other type:", cortexRow);
                                            cortexPromises.push(
                                                sdk.cortex.create(cortexRow)
                                                    .catch(error => {
                                                        console.error(`Error creating cortex entry for ${label}:`, error);
                                                        toast.error(`Error updating CrowdStrike field: ${label}`);
                                                    })
                                            );
                                        });
                                    }
                                }
                                
                                return Promise.all(cortexPromises);
                            }).catch(error => {
                                console.error("Error in cortex cleanup/create process:", error);
                                toast.error("Error updating CrowdStrike data");
                            })
                        );
                    } else if (this.state.originalRule.cortex && this.state.originalRule.cortex.length > 0) {
                        // If cortexData is null but we have existing data, delete all cortex entries
                        const deletePromises = this.state.originalRule.cortex.map(entry => {
                            return sdk.cortex.delete(entry.id)
                                .catch(error => {
                                    console.error(`Error deleting cortex entry ${entry.id}:`, error);
                                });
                        });
                        promises.push(Promise.all(deletePromises));
                    }

                    // 5) Update sysmon data
                    if (this.state.sysmonData !== null) {
                        const sysmonData = {
                            xmlText: this.state.sysmonData,
                            rule_id: ruleId
                        };
                        
                        // If we have existing sysmon data, try to update it
                        if (this.state.originalRule.sysmon && this.state.originalRule.sysmon.length > 0) {
                            promises.push(
                                sdk.sysmon.update(this.state.originalRule.sysmon[0].id, sysmonData)
                                    .catch(error => {
                                        console.error("Error updating sysmon:", error);
                                        // Try create as fallback
                                        return sdk.sysmon.create(sysmonData)
                                            .catch(createError => {
                                                console.error("Error in sysmon create fallback:", createError);
                                                toast.error("Error updating sysmon data");
                                            });
                                    })
                            );
                        } else if (this.state.sysmonData) {
                            // Otherwise create new
                            promises.push(
                                sdk.sysmon.create(sysmonData)
                                    .catch(error => {
                                        console.error("Error creating sysmon:", error);
                                        toast.error("Error creating sysmon data");
                                    })
                            );
                        }
                    } else if (this.state.originalRule.sysmon && this.state.originalRule.sysmon.length > 0) {
                        // If sysmonData is null but we have existing data, delete it
                        promises.push(
                            sdk.sysmon.delete(this.state.originalRule.sysmon[0].id)
                                .catch(error => {
                                    console.error("Error deleting sysmon:", error);
                                    toast.error("Error deleting sysmon data");
                                })
                        );
                    }

                    // 6) Update splunk data
                    if (this.state.splunkData !== null) {
                        const splunkData = {
                            xmlText: this.state.splunkData,
                            rule_id: ruleId
                        };
                        
                        // If we have existing splunk data, try to update it
                        if (this.state.originalRule.splunk && this.state.originalRule.splunk.length > 0) {
                            promises.push(
                                sdk.splunk.update(this.state.originalRule.splunk[0].id, splunkData)
                                    .catch(error => {
                                        console.error("Error updating splunk:", error);
                                        // Try create as fallback
                                        return sdk.splunk.create(splunkData)
                                            .catch(createError => {
                                                console.error("Error in splunk create fallback:", createError);
                                                toast.error("Error updating splunk data");
                                            });
                                    })
                            );
                        } else if (this.state.splunkData) {
                            // Otherwise create new
                            promises.push(
                                sdk.splunk.create(splunkData)
                                    .catch(error => {
                                        console.error("Error creating splunk:", error);
                                        toast.error("Error creating splunk data");
                                    })
                            );
                        }
                    } else if (this.state.originalRule.splunk && this.state.originalRule.splunk.length > 0) {
                        // If splunkData is null but we have existing data, delete it
                        promises.push(
                            sdk.splunk.delete(this.state.originalRule.splunk[0].id)
                                .catch(error => {
                                    console.error("Error deleting splunk:", error);
                                    toast.error("Error deleting splunk data");
                                })
                        );
                    }

                    // 7) Update qradar data
                    if (this.state.qradarData !== null) {
                        const qradarData = {
                            xmlText: this.state.qradarData,
                            rule_id: ruleId
                        };
                        
                        // If we have existing qradar data, try to update it
                        if (this.state.originalRule.qradar && this.state.originalRule.qradar.length > 0) {
                            promises.push(
                                sdk.qradar.update(this.state.originalRule.qradar[0].id, qradarData)
                                    .catch(error => {
                                        console.error("Error updating qradar:", error);
                                        // Try create as fallback
                                        return sdk.qradar.create(qradarData)
                                            .catch(createError => {
                                                console.error("Error in qradar create fallback:", createError);
                                                toast.error("Error updating qradar data");
                                            });
                                    })
                            );
                        } else if (this.state.qradarData) {
                            // Otherwise create new
                            promises.push(
                                sdk.qradar.create(qradarData)
                                    .catch(error => {
                                        console.error("Error creating qradar:", error);
                                        toast.error("Error creating qradar data");
                                    })
                            );
                        }
                    } else if (this.state.originalRule.qradar && this.state.originalRule.qradar.length > 0) {
                        // If qradarData is null but we have existing data, delete it
                        promises.push(
                            sdk.qradar.delete(this.state.originalRule.qradar[0].id)
                                .catch(error => {
                                    console.error("Error deleting qradar:", error);
                                    toast.error("Error deleting qradar data");
                                })
                        );
                    }

                    // 8) Update syslog data
                    sdk.syslog.get({ where: { rule_id: ruleId }})
                        .then(existingSyslog => {
                            if (existingSyslog && existingSyslog.length > 0) {
                                // Update existing syslog
                                if (this.state.syslogData) {
                                    promises.push(
                                        sdk.syslog.update(existingSyslog[0].id, {
                                            xmlText: this.state.syslogData
                                        })
                                        .catch(error => {
                                            console.error("Error updating syslog data:", error);
                                            toast.error("Error updating Syslog data");
                                        })
                                    );
                                } else {
                                    // Delete existing syslog if we have no data now
                                    promises.push(
                                        sdk.syslog.delete(existingSyslog[0].id)
                                        .catch(error => {
                                            console.error("Error deleting syslog data:", error);
                                            toast.error("Error deleting Syslog data");
                                        })
                                    );
                                }
                            } else if (this.state.syslogData) {
                                // Create new syslog entry
                                promises.push(
                                    sdk.syslog.create({
                                        xmlText: this.state.syslogData,
                                        rule_id: ruleId
                                    })
                                    .catch(error => {
                                        console.error("Error creating syslog data:", error);
                                        toast.error("Error creating Syslog data");
                                    })
                                );
                            }
                            
                            return Promise.all(promises);
                        })
                        .catch(error => {
                            console.error("Error handling syslog data:", error);
                            return Promise.all(promises);
                        });

                    // 9) Handle Playbook data

                    // Sysmon Playbook
                    const sysmonPlaybookPromise = sdk.sysmonPlaybook.get({ where: { rule_id: ruleId } })
                        .then(playbooks => {
                            if (playbooks && playbooks.length > 0) {
                                if (this.state.sysmonPlaybookData) {
                                    // Update existing playbook
                                    return sdk.sysmonPlaybook.update(playbooks[0].id, {
                                        playbookText: this.state.sysmonPlaybookData
                                    });
                                } else {
                                    // Delete playbook if no data
                                    return sdk.sysmonPlaybook.delete(playbooks[0].id);
                                }
                            } else if (this.state.sysmonPlaybookData) {
                                // Create new playbook
                                return sdk.sysmonPlaybook.create({
                                    playbookText: this.state.sysmonPlaybookData,
                                    rule_id: ruleId
                                });
                            }
                            return Promise.resolve();
                        })
                        .catch(error => {
                            console.error("Error handling Sysmon playbook:", error);
                            return Promise.resolve();
                        });
                    promises.push(sysmonPlaybookPromise);

                    // Cortex Playbook
                    const cortexPlaybookPromise = sdk.cortexPlaybook.get({ where: { rule_id: ruleId } })
                        .then(playbooks => {
                            if (playbooks && playbooks.length > 0) {
                                // If there's playbook data, first delete existing entries
                                return Promise.all(playbooks.map(entry => sdk.cortexPlaybook.delete(entry.id)))
                                    .then(() => {
                                        // Then if we have new data, create new entries
                                        if (this.state.cortexPlaybookData) {
                                            return this.createCortexPlaybookEntries(ruleId);
                                        }
                                        return Promise.resolve();
                                    });
                            } else if (this.state.cortexPlaybookData) {
                                // Create new entries if we have data but no existing entries
                                return this.createCortexPlaybookEntries(ruleId);
                            }
                            return Promise.resolve();
                        })
                        .catch(error => {
                            console.error("Error handling Cortex playbook:", error);
                            return Promise.resolve();
                        });
                    promises.push(cortexPlaybookPromise);

                    // Splunk Playbook
                    const splunkPlaybookPromise = sdk.splunkPlaybook.get({ where: { rule_id: ruleId } })
                        .then(playbooks => {
                            if (playbooks && playbooks.length > 0) {
                                if (this.state.splunkPlaybookData) {
                                    // Update existing playbook
                                    return sdk.splunkPlaybook.update(playbooks[0].id, {
                                        playbookText: this.state.splunkPlaybookData
                                    });
                                } else {
                                    // Delete playbook if no data
                                    return sdk.splunkPlaybook.delete(playbooks[0].id);
                                }
                            } else if (this.state.splunkPlaybookData) {
                                // Create new playbook
                                return sdk.splunkPlaybook.create({
                                    playbookText: this.state.splunkPlaybookData,
                                    rule_id: ruleId
                                });
                            }
                            return Promise.resolve();
                        })
                        .catch(error => {
                            console.error("Error handling Splunk playbook:", error);
                            return Promise.resolve();
                        });
                    promises.push(splunkPlaybookPromise);

                    // Qradar Playbook
                    const qradarPlaybookPromise = sdk.qradarPlaybook.get({ where: { rule_id: ruleId } })
                        .then(playbooks => {
                            if (playbooks && playbooks.length > 0) {
                                if (this.state.qradarPlaybookData) {
                                    // Update existing playbook
                                    return sdk.qradarPlaybook.update(playbooks[0].id, {
                                        playbookText: this.state.qradarPlaybookData
                                    });
                                } else {
                                    // Delete playbook if no data
                                    return sdk.qradarPlaybook.delete(playbooks[0].id);
                                }
                            } else if (this.state.qradarPlaybookData) {
                                // Create new playbook
                                return sdk.qradarPlaybook.create({
                                    playbookText: this.state.qradarPlaybookData,
                                    rule_id: ruleId
                                });
                            }
                            return Promise.resolve();
                        })
                        .catch(error => {
                            console.error("Error handling Qradar playbook:", error);
                            return Promise.resolve();
                        });
                    promises.push(qradarPlaybookPromise);

                    // Syslog Playbook
                    const syslogPlaybookPromise = sdk.syslogPlaybook.get({ where: { rule_id: ruleId } })
                        .then(playbooks => {
                            if (playbooks && playbooks.length > 0) {
                                if (this.state.syslogPlaybookData) {
                                    // Update existing playbook
                                    return sdk.syslogPlaybook.update(playbooks[0].id, {
                                        playbookText: this.state.syslogPlaybookData
                                    });
                                } else {
                                    // Delete playbook if no data
                                    return sdk.syslogPlaybook.delete(playbooks[0].id);
                                }
                            } else if (this.state.syslogPlaybookData) {
                                // Create new playbook
                                return sdk.syslogPlaybook.create({
                                    playbookText: this.state.syslogPlaybookData,
                                    rule_id: ruleId
                                });
                            }
                            return Promise.resolve();
                        })
                        .catch(error => {
                            console.error("Error handling Syslog playbook:", error);
                            return Promise.resolve();
                        });
                    promises.push(syslogPlaybookPromise);

                    // 10) Update client relationships
                    const clientUpdatePromise = sdk.blueRuleClients.deleteByRuleId(ruleId)
                        .then(() => {
                            console.log("Successfully deleted existing client relationships");
                            // Create new client relationships if any were selected
                            if (this.state.selectedClients && this.state.selectedClients.length > 0) {
                                const clientNames = this.state.selectedClients.map(client => client.value);
                                return sdk.blueRuleClients.createMultiple(ruleId, clientNames);
                            }
                            return Promise.resolve();
                        })
                        .catch(error => {
                            console.error("Error handling client relationships:", error);
                            toast.error("Error updating client relationships");
                            return Promise.resolve();
                        });

                    promises.push(clientUpdatePromise);

                    return Promise.all(promises);
                })
                .then(() => {
                    toast.success("Blue Rule Updated Successfully");
                    // Redirect after short delay
                    setTimeout(() => {
                        this.props.history.push("/blue-rule-management");
                    }, 2000);
                })
                .catch(e => {
                    console.error("Error updating Blue Rule:", e);
                    this.setState({
                        updating: false,
                        uploadError: e.toString()
                    });
                    toast.error("Error updating Blue Rule: " + e.toString());
                });
        }
    }

    // Helper method to create cortex playbook entries
    createCortexPlaybookEntries(ruleId) {
        const { cortexPlaybookData } = this.state;
        
        if (!cortexPlaybookData) {
            return Promise.resolve();
        }

        return sdk.cortexPlaybook.create({
            playbookText: cortexPlaybookData,
            rule_id: ruleId
        }).catch(error => {
            console.error("Error creating cortex playbook:", error);
            toast.error("Error saving CrowdStrike playbook");
        });
    }

    // Playbook handlers
    handleSysmonPlaybookChange(playbookText) {
        this.setState({ sysmonPlaybookData: playbookText });
    }

    handleCortexPlaybookChange(playbookText) {
        this.setState({ cortexPlaybookData: playbookText });
    }

    handleSplunkPlaybookChange(playbookText) {
        this.setState({ splunkPlaybookData: playbookText });
    }

    handleQradarPlaybookChange(playbookText) {
        this.setState({ qradarPlaybookData: playbookText });
    }

    handleSyslogPlaybookChange(playbookText) {
        this.setState({ syslogPlaybookData: playbookText });
    }

    // Add the approval button UI in the render method
    renderApprovalButton() {
        const { status, isBlueTeamAdmin, approvalLoading } = this.state;
        
        if (isBlueTeamAdmin && status === 'Ready for test') {
            return (
                <div className="mb-3">
                    <Alert color="info">
                        <h5>Blue Team Admin Action Required</h5>
                        <p>This rule is ready for testing. Please review it and approve if everything is correct.</p>
                        <Button 
                            color="success" 
                            onClick={this.handleApproveRule} 
                            disabled={approvalLoading}
                        >
                            {approvalLoading ? 'Approving...' : 'Approve Rule'}
                        </Button>
                    </Alert>
                </div>
            );
        }
        return null;
    }

    render() {
        const platforms = platformsData;
        const { loading, updating } = this.state;
        
        // Check if the rule is completed and the user is not an admin
        const isCompleted = this.state.originalRule && this.state.originalRule.status === 'Completed';
        const isPlaybookTab = parseInt(this.state.activeTab) >= 6; // Check if current tab is a playbook tab
        const isDisabled = updating || (isCompleted && !this.state.isBlueTeamAdmin && !isPlaybookTab);

        if (loading) {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                    <i className="fas fa-spinner fa-spin fa-3x" />
                </div>
            );
        }

        return (
            <div>
                <ToastContainer />
                {isCompleted && !this.state.isBlueTeamAdmin && (
                    <Alert color="warning" className="mb-3">
                        <h5><i className="fas fa-lock mr-2"></i>This rule is marked as Completed</h5>
                        <p className="mb-0">
                            Only Blue Team Admins can edit the main rule configuration. However, you can still edit the playbook fields to update the playbook content.
                        </p>
                    </Alert>
                )}
                <Form onSubmit={this.handleSubmit}>
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            Edit Blue Rule
                            <span className="float-right">
                                <Link to={`/blue-rule-management`} onClick={e => e.stopPropagation()}>
                                    <Button className="btn-outline-danger" size="sm">
                                        <i className="fas fa-times" />
                                    </Button>
                                </Link>
                            </span>
                        </CardTitle>
                        <CardBody>
                            {this.renderApprovalButton()}
                            
                            {/* Admin Feedback Box - Only visible for admins if not completed, or for everyone if feedback exists */}
                            {((this.state.isBlueTeamAdmin && this.state.status !== 'Completed') || 
                              (!this.state.isBlueTeamAdmin && this.state.adminFeedback)) && (
                                <Row className="mb-4">
                                    <Col md="12">
                                        <Card className="border" style={{ borderColor: '#007bff', boxShadow: '0 2px 10px rgba(0, 123, 255, 0.1)' }}>
                                            <CardBody className="p-3">
                                                <div className="d-flex align-items-center mb-3">
                                                    <i className="fas fa-comment-dots text-primary mr-2" style={{ fontSize: '1.25rem' }}></i>
                                                    <h5 className="mb-0 text-primary">Blue Team Admin Feedback</h5>
                                                </div>
                                                {this.state.isBlueTeamAdmin && this.state.status !== 'Completed' ? (
                                                    <FormGroup className="mb-0">
                                                        <Input
                                                            type="textarea"
                                                            rows="4"
                                                            className="border"
                                                            placeholder="Provide feedback for rule improvements here..."
                                                            value={this.state.adminFeedback || ""}
                                                            onChange={(e) => this.setState({ adminFeedback: e.target.value })}
                                                        />
                                                        <small className="text-muted mt-1 d-block">
                                                            Specify what changes are needed for this rule to be approved.
                                                        </small>
                                                    </FormGroup>
                                                ) : (
                                                    <div className="p-3 bg-light rounded">
                                                        {this.state.adminFeedback ? (
                                                            <div>
                                                                <div className="font-weight-bold mb-2">Required changes:</div>
                                                                <div style={{ whiteSpace: 'pre-wrap' }}>{this.state.adminFeedback}</div>
                                                            </div>
                                                        ) : (
                                                            <em className="text-muted">No feedback provided yet.</em>
                                                        )}
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                            
                            <Row>
                                <Col md="6">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Rule Name</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        disabled={isDisabled}
                                                        invalid={!!this.state.nameError}
                                                        value={this.state.ruleName || ""}
                                                        onChange={e =>
                                                            this.setState({ ruleName: e.target.value, nameError: undefined })
                                                        }
                                                    />
                                                    <FormFeedback>
                                                        {this.state.nameError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        disabled={isDisabled}
                                                        invalid={!!this.state.statusError}
                                                        value={this.state.status || ""}
                                                        onChange={e => this.setState({ status: e.target.value, statusError: undefined })}
                                                        className={isDisabled ? "bg-light text-muted" : ""}
                                                    >
                                                        <option value="" disabled>Select Status</option>
                                                        <option value="Working on it">Working on it</option>
                                                        <option value="Ready for test">Ready for test</option>
                                                        <option value="Irrelevant">Irrelevant</option>
                                                        {isCompleted && (
                                                            <option value="Completed">Completed</option>
                                                        )}
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.statusError}
                                                    </FormFeedback>
                                                </InputGroup>
                                                {isCompleted && !this.state.isBlueTeamAdmin && !isPlaybookTab ? (
                                                    <small className="text-warning mt-1 d-block">
                                                        <i className="fas fa-lock mr-1"></i>
                                                        This rule has been marked as Completed by a Blue Team Admin. Only admins can edit it.
                                                    </small>
                                                ) : this.state.status === 'Completed' && (
                                                    <small className="text-success mt-1 d-block">
                                                        <i className="fas fa-info-circle mr-1"></i>
                                                        Completed status indicates the rule has been reviewed and approved.
                                                    </small>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        disabled={isDisabled}
                                                        invalid={!!this.state.ruleCategoryError}
                                                        value={this.state.ruleCategory || ""}
                                                        onChange={this.handleCategoryChange}
                                                    >
                                                        <option value="" disabled>Select Rule Category</option>
                                                        {ruleCategories.map(category => (
                                                            <option key={category + "-option"} value={category}>{category}</option>
                                                        ))}
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.ruleCategoryError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Silver Bullet</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="Type to search Silver Bullets..."
                                                        list="silverBulletOptions"
                                                        disabled={isDisabled || !this.state.allSilverBullets.length}
                                                        value={this.state.silverBulletInputValue}
                                                        onChange={this.handleSilverBulletSearchSelect}
                                                        onBlur={this.handleSilverBulletSelection}
                                                    />
                                                    <datalist id="silverBulletOptions">
                                                        {this.state.allSilverBullets.map((bullet) => (
                                                            <option key={bullet.id} value={bullet.name} />
                                                        ))}
                                                    </datalist>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <CardTitle className="bg-light border-bottom p-3 mt-3">
                                                Creator
                                            </CardTitle>
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    className="form-control"
                                                    value={this.state.creator || ""}
                                                    onChange={e => this.setState({ creator: e.target.value, creatorError: undefined })}
                                                    invalid={!!this.state.creatorError}
                                                    disabled={isDisabled}
                                                >
                                                    <option value="">Select a Creator</option>
                                                    <option value="Michael Reder">Michael Reder</option>
                                                    <option value="Noa Elias">Noa Elias</option>
                                                    <option value="Yoav Laugie">Yoav Laugie</option>
                                                    <option value="Dylan Fleury">Dylan Fleury</option>
                                                    <option value="Ury Yudkin">Ury Yudkin</option>
                                                    <option value="Danel Yehuda">Danel Yehuda</option>
                                                    <option value="Evyatar Guilad">Evyatar Guilad</option>
                                                </Input>
                                                <FormFeedback>
                                                    {this.state.creatorError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-3">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>MITRE ATT&CK</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        placeholder="T1234, T5678..."
                                                        disabled={isDisabled}
                                                        invalid={!!this.state.mitreError}
                                                        value={this.state.mitre || ""}
                                                        onChange={e => this.setState({ mitre: e.target.value, mitreError: undefined })}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.mitreError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-3">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Attack Description</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="textarea"
                                                        rows="4"
                                                        placeholder="Rule description..."
                                                        disabled={isDisabled}
                                                        invalid={!!this.state.ruleDescriptionError}
                                                        value={this.state.ruleDescription || ""}
                                                        onChange={e => this.setState({ ruleDescription: e.target.value, ruleDescriptionError: undefined })}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.ruleDescriptionError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Rule Summary</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="textarea"
                                                        rows="3"
                                                        placeholder="Rule summary..."
                                                        disabled={isDisabled}
                                                        invalid={!!this.state.ruleSummaryError}
                                                        value={this.state.ruleSummary || ""}
                                                        onChange={e => this.setState({ ruleSummary: e.target.value, ruleSummaryError: undefined })}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.ruleSummaryError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6">
                                    <CardTitle className="bg-light border-bottom p-3">
                                        Select the relevant Platforms
                                    </CardTitle>
                                    <FormGroup>
                                        {platforms.map(platform => (
                                            <div className="form-check form-check-inline" key={platform.name}>
                                                <CustomInput
                                                    type="checkbox"
                                                    id={platform.name}
                                                    label={platform.name}
                                                    checked={this.state.chosenPlatforms[platform.propName] || false}
                                                    disabled={isDisabled}
                                                    onChange={e => {
                                                        const chosenPlatforms = { ...this.state.chosenPlatforms };
                                                        chosenPlatforms[platform.propName] = e.target.checked;
                                                        console.log(`Platform ${platform.name} changed to ${e.target.checked}`);
                                                        console.log('Updated chosenPlatforms:', chosenPlatforms);
                                                        this.setState({ chosenPlatforms });
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </FormGroup>
                                    
                                    <CardTitle className="bg-light border-bottom p-3 mt-4">
                                        Run In (Clients)
                                    </CardTitle>
                                    <FormGroup>
                                        <Select
                                            isMulti
                                            name="clients"
                                            options={clientOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select clients where this rule is implemented"
                                            value={this.state.selectedClients}
                                            onChange={this.handleClientChange}
                                            isDisabled={isDisabled}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="mt-3 ml-1">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "1" })}
                                            onClick={() => this.toggleTab("1")}
                                        >
                                            Sysmon
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "2" })}
                                            onClick={() => this.toggleTab("2")}
                                        >
                                            CrowdStrike
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "3" })}
                                            onClick={() => this.toggleTab("3")}
                                        >
                                            Splunk
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "4" })}
                                            onClick={() => this.toggleTab("4")}
                                        >
                                            Qradar
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "5" })}
                                            onClick={() => this.toggleTab("5")}
                                        >
                                            Syslog
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Row>
                            <Row className="mt-3 ml-1">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "6" })}
                                            onClick={() => this.toggleTab("6")}
                                        >
                                            Sysmon Playbook
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "7" })}
                                            onClick={() => this.toggleTab("7")}
                                        >
                                            CrowdStrike Playbook
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "8" })}
                                            onClick={() => this.toggleTab("8")}
                                        >
                                            Splunk Playbook
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "9" })}
                                            onClick={() => this.toggleTab("9")}
                                        >
                                            Qradar Playbook
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === "10" })}
                                            onClick={() => this.toggleTab("10")}
                                        >
                                            Syslog Playbook
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Row>
                            <Row className="mt-3">
                                <Col md="12">
                                    <div style={{ display: this.state.activeTab === "1" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Sysmon Configuration</h5>
                                            {this.state.sysmonData !== null && (
                                                <Button 
                                                    color="danger" 
                                                    size="sm" 
                                                    outline
                                                    disabled={isDisabled}
                                                    onClick={this.clearSysmonData}
                                                >
                                                    <i className="fas fa-trash-alt mr-1"></i> Clear Sysmon Data
                                                </Button>
                                            )}
                                        </div>
                                        {this.state.sysmonData === null && (
                                            <div className="alert alert-light text-center my-3">
                                                <i className="fas fa-info-circle mr-2"></i>
                                                No Sysmon configuration for this rule. Add content below to create it.
                                            </div>
                                        )}
                                        <AddSysmon
                                            xmlText={this.state.sysmonData}
                                            onSysmonChange={this.handleSysmonChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "2" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">CrowdStrike Configuration</h5>
                                            {this.state.cortexData !== null && (
                                                <Button 
                                                    color="danger" 
                                                    size="sm" 
                                                    outline
                                                    disabled={isDisabled}
                                                    onClick={this.clearCortexData}
                                                >
                                                    <i className="fas fa-trash-alt mr-1"></i> Clear CrowdStrike Data
                                                </Button>
                                            )}
                                        </div>
                                        {this.state.cortexData === null && (
                                            <div className="alert alert-light text-center my-3">
                                                <i className="fas fa-info-circle mr-2"></i>
                                                No CrowdStrike configuration for this rule. Add content below to create it.
                                            </div>
                                        )}
                                        <AddCortex
                                            fieldData={this.state.cortexData}
                                            onChange={this.handleCortexChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "3" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Splunk Configuration</h5>
                                            {this.state.splunkData !== null && (
                                                <Button 
                                                    color="danger" 
                                                    size="sm" 
                                                    outline
                                                    disabled={isDisabled}
                                                    onClick={this.clearSplunkData}
                                                >
                                                    <i className="fas fa-trash-alt mr-1"></i> Clear Splunk Data
                                                </Button>
                                            )}
                                        </div>
                                        {this.state.splunkData === null && (
                                            <div className="alert alert-light text-center my-3">
                                                <i className="fas fa-info-circle mr-2"></i>
                                                No Splunk configuration for this rule. Add content below to create it.
                                            </div>
                                        )}
                                        <AddSplunk
                                            splunkText={this.state.splunkData}
                                            onSplunkChange={this.handleSplunkChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "4" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Qradar Configuration</h5>
                                            {this.state.qradarData !== null && (
                                                <Button 
                                                    color="danger" 
                                                    size="sm" 
                                                    outline
                                                    disabled={isDisabled}
                                                    onClick={this.clearQradarData}
                                                >
                                                    <i className="fas fa-trash-alt mr-1"></i> Clear Qradar Data
                                                </Button>
                                            )}
                                        </div>
                                        {this.state.qradarData === null && (
                                            <div className="alert alert-light text-center my-3">
                                                <i className="fas fa-info-circle mr-2"></i>
                                                No Qradar configuration for this rule. Add content below to create it.
                                            </div>
                                        )}
                                        <AddQradar
                                            qradarText={this.state.qradarData}
                                            onQradarChange={this.handleQradarChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "5" ? "block" : "none" }}>
                                        <AddSyslog
                                            syslogText={this.state.syslogData}
                                            onSyslogChange={this.handleSyslogChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "6" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Sysmon Playbook</h5>
                                        </div>
                                        <PlaybookTab
                                            title="Sysmon"
                                            playbookText={this.state.sysmonPlaybookData}
                                            onPlaybookChange={this.handleSysmonPlaybookChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "7" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">CrowdStrike Playbook</h5>
                                        </div>
                                        <AddCortexPlaybook
                                            fieldData={this.state.cortexPlaybookData}
                                            onChange={this.handleCortexPlaybookChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "8" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Splunk Playbook</h5>
                                        </div>
                                        <PlaybookTab
                                            title="Splunk"
                                            playbookText={this.state.splunkPlaybookData}
                                            onPlaybookChange={this.handleSplunkPlaybookChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "9" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Qradar Playbook</h5>
                                        </div>
                                        <PlaybookTab
                                            title="Qradar"
                                            playbookText={this.state.qradarPlaybookData}
                                            onPlaybookChange={this.handleQradarPlaybookChange} />
                                    </div>
                                    <div style={{ display: this.state.activeTab === "10" ? "block" : "none" }}>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="m-0">Syslog Playbook</h5>
                                        </div>
                                        <PlaybookTab
                                            title="Syslog"
                                            playbookText={this.state.syslogPlaybookData}
                                            onPlaybookChange={this.handleSyslogPlaybookChange} />
                                    </div>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Chart png file</InputGroupText>
                                    </InputGroupAddon>
                                    <div className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            id="chartFileInput"
                                            disabled={isDisabled}
                                            onChange={(e) => this.setState({ chartFile: e.target.files[0] })}
                                        />
                                        <label
                                            className="custom-file-label"
                                            htmlFor="chartFileInput"
                                        >
                                            {this.state.chartFile 
                                                ? this.state.chartFile.name 
                                                : (this.state.originalRule && this.state.originalRule.chartUrl 
                                                    ? "Current chart file exists" 
                                                    : "Choose file")}
                                        </label>
                                    </div>
                                </InputGroup>
                                
                                {this.state.originalRule && this.state.originalRule.chartUrl && !this.state.chartFile && (
                                    <div className="mt-2">
                                        <div className="alert alert-info d-flex align-items-center" style={{ borderWidth: '2px' }}>
                                            <i className="fas fa-image mr-2" style={{ fontSize: '1.5rem', color: '#0056b3' }}></i>
                                            <div>
                                                <h5 className="alert-heading mb-1">Chart Image Already Exists</h5>
                                                <p className="mb-0">
                                                    This rule already has an uploaded chart image. 
                                                    To replace it, upload a new file above. Otherwise, the current chart will be kept.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-center mt-2 mb-3">
                                            <img 
                                                src={this.state.originalRule.chartBase64 
                                                    ? `data:image/png;base64,${this.state.originalRule.chartBase64}` 
                                                    : this.state.originalRule.chartUrl} 
                                                alt="Current Chart" 
                                                className="img-fluid border rounded shadow-sm"
                                                style={{ maxHeight: '250px' }}
                                                onError={(e) => {
                                                    console.error("Failed to load chart image:", e);
                                                    e.target.onerror = null;
                                                    e.target.src = "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22200%22%20height%3D%22200%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20200%20200%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_188f8d22b3a%20text%20%7B%20fill%3A%23AAAAAA%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_188f8d22b3a%22%3E%3Crect%20width%3D%22200%22%20height%3D%22200%22%20fill%3D%22%23EEEEEE%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2272.5%22%20y%3D%22104.8%22%3EChart%20Image%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E";
                                                    // Add a failure message below the image
                                                    const errorMsg = document.createElement('div');
                                                    errorMsg.className = 'text-danger mt-2';
                                                    errorMsg.innerHTML = '<i class="fas fa-exclamation-circle"></i> Failed to load chart image';
                                                    e.target.parentNode.appendChild(errorMsg);
                                                }}
                                            />
                                            <div className="mt-2">
                                                <Button
                                                    color="outline-secondary"
                                                    size="sm"
                                                    onClick={() => {
                                                        if (this.state.originalRule.chartBase64) {
                                                            // Create a downloadable blob from the base64 data
                                                            const byteCharacters = atob(this.state.originalRule.chartBase64);
                                                            const byteNumbers = new Array(byteCharacters.length);
                                                            for (let i = 0; i < byteCharacters.length; i++) {
                                                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                                                            }
                                                            const byteArray = new Uint8Array(byteNumbers);
                                                            const blob = new Blob([byteArray], { type: 'image/png' });
                                                            
                                                            // Create a download link and trigger it
                                                            const link = document.createElement('a');
                                                            link.href = URL.createObjectURL(blob);
                                                            link.download = `${this.state.ruleName || 'chart'}.png`;
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            
                                                            // Clean up
                                                            setTimeout(() => {
                                                                document.body.removeChild(link);
                                                                URL.revokeObjectURL(link.href);
                                                            }, 100);
                                                        } else if (this.state.originalRule.chartUrl) {
                                                            // If only URL is available, open in new tab
                                                            window.open(this.state.originalRule.chartUrl, '_blank');
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-download mr-1"></i> Download Chart
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.chartFile && (
                                    <div className="alert alert-warning mt-2">
                                        <i className="fas fa-exclamation-triangle mr-2"></i>
                                        <strong>New chart selected:</strong> {this.state.chartFile.name} will replace the existing chart upon save.
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    {this.state.uploadError ? (
                        <UncontrolledAlert className="mt-2 mb-0" color="danger">
                            {this.state.uploadError}
                        </UncontrolledAlert>
                    ) : null}

                    <Row className="mt-2">
                        <Col xl={{ size: 2, offset: 5 }} lg={{ size: 4, offset: 4 }} md="12">
                            <FormGroup>
                                <Button type="submit" color="secondary" size="lg" block disabled={isDisabled}>
                                    {!updating ? "Update" : <i className="fas fa-spin fa-spinner" />}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default BlueRuleEdit; 