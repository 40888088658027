// ...
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sdk from 'api-sdk';
import './CompaniesTable.css';

// Define defenseRulesOptions and categoryColorMap
const defenceRulesOptions = [
  'Sysmon',
  'CrowdStrike Falcon',
  'Microsoft Defender',
  'Carbon Black',
  'SentinelOne',
  'Cynet',
  'Tanium',
  'Symantec',
  'FireEye',
  'McAfee',
  'Bitdefender GravityZone',
  'Palo Alto Cortex',
  'Azure Sentinel',
  'Aws Athena',
  'GPO',
];

const categoryColorMap = {
  'Anti Forensics': '#6610f2',
  'C&C': '#9400D3',
  Collection: '#007BFF',
  Compile: '#4FC3F7',
  'Credential Access': '#20C997',
  'Data Exfiltration': '#28A745',
  'Defense Evasion': '#93C432',
  Discovery: '#67882F',
  Execution: '#FFC107',
  Impact: '#FD7E14',
  'Initial Access': '#F4A460',
  'Lateral Movement': '#FA8072',
  Misconfiguration: '#DC3545',
  Persistence: '#E83E8C',
  'Privilege Escalation': '#811840',
};

const RunHistoryForCompaniesTable = ({ isOpen, toggle, onSaveRunHistory, runHistory }) => {
  // State variables
  const [selectedCompany, setSelectedCompany] = useState('');
  const [runDate, setRunDate] = useState('');
  const [notes, setNotes] = useState('');
  const [status, setStatus] = useState('');
  const [companies, setCompanies] = useState([]);
  const [bullets, setBullets] = useState([]);
  const [selectedBullet, setSelectedBullet] = useState('');
  const [selectedBulletId, setSelectedBulletId] = useState('');
  const [category, setCategory] = useState(''); // Separate state for Category
  const [runNumber, setRunNumber] = useState('');
  const [selectedDefenseRule, setSelectedDefenseRule] = useState('');
  const [filteredBullets, setFilteredBullets] = useState([]);
  const [bulletSearch, setBulletSearch] = useState(''); // State for search input
  const [errors, setErrors] = useState({});

  // Effect to handle opening the modal and populating fields
  useEffect(() => {
    if (isOpen) {
      if (runHistory) {
        setSelectedCompany(runHistory.companyName || '');
        setRunDate(runHistory.runDate ? runHistory.runDate.split('T')[0] : '');
        setNotes(runHistory.notes || '');
        setStatus(runHistory.status || '');
        setSelectedDefenseRule(runHistory.defenceRules || '');
        setRunNumber(runHistory.runNumber || '');
        setSelectedBullet(runHistory.bulletName || '');
        setSelectedBulletId(runHistory.bulletId || '');
        setBulletSearch(runHistory.bulletName || '');
        setCategory(runHistory.category || ''); // Assuming runHistory has a category field
      } else {
        // Reset fields when creating a new run history
        setSelectedCompany('');
        setRunDate('');
        setNotes('');
        setStatus('');
        setSelectedDefenseRule('');
        setRunNumber('');
        setSelectedBullet('');
        setSelectedBulletId('');
        setBulletSearch('');
        setCategory('');
        setErrors({});
      }
    }
  }, [runHistory, isOpen]);

  // Effect to fetch companies and bullets when component mounts
  useEffect(() => {
    const fetchCompaniesAndBullets = async () => {
      try {
        const [companiesResponse, bulletsResponse] = await Promise.all([
          sdk.companies.getAll(),
          sdk.silverBullets.get(),
        ]);
        setCompanies(companiesResponse);
        setBullets(bulletsResponse);
        setFilteredBullets(bulletsResponse); // Initialize with all bullets
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch companies or bullets.');
      }
    };
    fetchCompaniesAndBullets();
  }, []);

  // Effect to ensure the selected bullet is in the filteredBullets when editing
  useEffect(() => {
    if (runHistory && bullets.length > 0) {
      const bullet = bullets.find(b => b.name === runHistory.bulletName);
      if (bullet && !filteredBullets.some(b => b.id === bullet.id)) {
        setFilteredBullets(prev => [...prev, bullet]);
      }
    }
  }, [runHistory, bullets, filteredBullets]);

  // Form validation
  const validate = () => {
    const newErrors = {};

    if (!selectedCompany) newErrors.selectedCompany = 'Company is required.';
    if (!status) newErrors.status = 'Status is required.';
    if (!runDate) newErrors.runDate = 'Run date is required.';
    if (!runNumber) newErrors.runNumber = 'Run number is required.';
    if (!selectedBullet) newErrors.selectedBullet = 'Bullet selection is required.';
    if (!selectedDefenseRule) newErrors.selectedDefenseRule = 'Defense rule is required.';
    if (!category) newErrors.category = 'Category is required.'; // Ensure category is required

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      toast.error('Please fix the errors in the form.');
      return;
    }

    const updatedRunHistory = {
      companyName: selectedCompany,
      runDate,
      notes,
      status,
      runNumber,
      defenceRules: selectedDefenseRule,
      bulletName: selectedBullet,
      bulletId: selectedBulletId,
      category, // Include category in the run history
    };

    try {
      await onSaveRunHistory(updatedRunHistory, runHistory ? runHistory.id : null);
      toggle(); // Close the modal after successful save
    } catch (error) {
      console.error('Error saving run history:', error);
      toast.error('Failed to save run history.');
    }
  };

  // Handle search input change
  const handleBulletSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setBulletSearch(e.target.value); // Update the search input's state

    if (searchValue === '') {
      setFilteredBullets(bullets); // Show all bullets if search is empty
      return;
    }

    const newFilteredBullets = bullets.filter((bullet) =>
      bullet.name.toLowerCase().includes(searchValue)
    );
    setFilteredBullets(newFilteredBullets);
  };

  // Handle bullet selection from the dropdown
  const handleBulletSelect = (e) => {
    const bulletName = e.target.value;
    const bullet = bullets.find((b) => b.name === bulletName);
    setSelectedBullet(bulletName);

    if (bullet) {
      setSelectedBulletId(bullet.id);
      setBulletSearch(bullet.name); 
      // ** Set category to the bullet's attackVector **
      setCategory(bullet.attackVector || '');
    } else {
      setSelectedBulletId('');
      setBulletSearch('');
      setCategory('');
    }
  };

  // Handle bullet input blur to ensure correct selection
  const handleBulletBlur = () => {
    const bullet = bullets.find((b) => b.name === selectedBullet);
    if (bullet) {
      setSelectedBullet(bullet.name);
      setSelectedBulletId(bullet.id);
      setBulletSearch(bullet.name); 
      // ** Also set the category if blur confirms a correct bullet selection **
      setCategory(bullet.attackVector || '');

      // Ensure the selected bullet is in the filteredBullets
      if (!filteredBullets.some(b => b.id === bullet.id)) {
        setFilteredBullets(prev => [...prev, bullet]);
      }
    } else {
      // If no matching bullet, clear bullet ID and category
      setSelectedBulletId('');
      setBulletSearch('');
      setCategory('');
    }
  };

  // Function to get CSS class based on status
  const getStatusClass = (status) => {
    switch (status) {
      case 'Pass':
        return 'status-pass';
      case 'Fail':
        return 'status-fail';
      case 'No defense':
        return 'status-no-defense';
      case 'Detected':
        return 'status-detected';
      case 'No POC':
        return 'status-no-poc';
      case 'No Working':
        return 'status-no-working';
      case 'Declined':
        return 'status-declined';
      default:
        return 'status-select';
    }
  };

  // Function to get color based on status
  const getColorByStatus = (status) => {
    switch (status) {
      case 'Pass':
        return 'green';
      case 'Fail':
        return 'red';
      case 'No defense':
        return 'orange';
      case 'Detected':
        return 'blue';
      case 'No POC':
        return 'purple';
      case 'No Working':
        return 'brown';
      case 'Declined':
        return 'darksalmon';
      default:
        return 'inherit';
    }
  };

  // Function to get color for category
  const getCategoryColor = (category) => {
    return categoryColorMap[category] || 'inherit';
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {runHistory ? 'Edit Run History' : 'Create New Run'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit} noValidate>
            {/* Company Selection */}
            <FormGroup>
              <Label for="companySelect">Company</Label>
              <Input
                type="select"
                name="company"
                id="companySelect"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                className={errors.selectedCompany ? 'is-invalid' : ''}
              >
                <option value="">Select a company</option>
                {companies.map((company) => (
                  <option key={company.companyID} value={company.companyName}>
                    {company.companyName}
                  </option>
                ))}
              </Input>
              {errors.selectedCompany && (
                <div className="invalid-feedback">{errors.selectedCompany}</div>
              )}
            </FormGroup>

            {/* Bullet Selection with Search */}
            <FormGroup>
              <Label for="bulletSearch">Bullet Name</Label>
              <Input
                type="text"
                name="bulletSearch"
                id="bulletSearch"
                value={bulletSearch}
                onChange={handleBulletSearch}
                onBlur={handleBulletBlur}
                placeholder="Search Bullets..."
                className={errors.selectedBullet ? 'is-invalid' : ''}
              />
              {errors.selectedBullet && (
                <div className="invalid-feedback">{errors.selectedBullet}</div>
              )}
              <Input
                type="select"
                name="bulletSelect"
                id="bulletSelect"
                value={selectedBullet}
                onChange={handleBulletSelect}
                size="10"
                style={{ marginTop: '10px' }}
                className={errors.selectedBullet ? 'is-invalid' : ''}
              >
                <option value="">Select a bullet</option>
                {filteredBullets.map((bullet) => (
                  <option
                    style={{ color: 'black' }}
                    key={bullet.id}
                    value={bullet.name}
                  >
                    {bullet.name}
                  </option>
                ))}
              </Input>
            </FormGroup>

            {/* Category Selection */}
            <FormGroup>
              <Label for="categorySelect">Category</Label>
              <Input
                type="select"
                name="category"
                id="categorySelect"
                value={category}
                disabled         
                onChange={(e) => setCategory(e.target.value)}
                style={{ color: getCategoryColor(category) }}
                className={errors.category ? 'is-invalid' : ''}
              >
                <option value="">Select Category</option>
                {Object.keys(categoryColorMap).map((cat, index) => (
                  <option
                    key={index}
                    value={cat}
                    style={{ color: categoryColorMap[cat] }}
                  >
                    {cat}
                  </option>
                ))}
              </Input>
              {errors.category && (
                <div className="invalid-feedback">{errors.category}</div>
              )}
            </FormGroup>

            {/* Defense Rule Selection */}
            <FormGroup>
              <Label for="defenseRuleSelect">Defense Rule</Label>
              <Input
                type="select"
                name="defenseRule"
                id="defenseRuleSelect"
                value={selectedDefenseRule}
                onChange={(e) => setSelectedDefenseRule(e.target.value)}
                className={errors.selectedDefenseRule ? 'is-invalid' : ''}
              >
                <option value="">Select Defense Rule</option>
                {defenceRulesOptions.map((rule, index) => (
                  <option key={index} value={rule}>
                    {rule}
                  </option>
                ))}
              </Input>
              {errors.selectedDefenseRule && (
                <div className="invalid-feedback">
                  {errors.selectedDefenseRule}
                </div>
              )}
            </FormGroup>

            {/* Status Selection */}
            <FormGroup>
              <Label for="statusSelect">Status</Label>
              <Input
                type="select"
                name="status"
                id="statusSelect"
                style={{ color: getColorByStatus(status) }}
                className={`${getStatusClass(status)} ${
                  errors.status ? 'is-invalid' : ''
                }`}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" className="status-select">
                  Select Status
                </option>
                <option value="Pass" className="status-pass">
                  Pass
                </option>
                <option value="Fail" className="status-fail">
                  Fail
                </option>
                <option value="No defense" className="status-no-defense">
                  No defense
                </option>
                <option value="Detected" className="status-detected">
                  Detected
                </option>
                <option value="No POC" className="status-no-poc">
                  No POC
                </option>
                <option value="No Working" className="status-no-working">
                  No Working
                </option>
                <option value="Declined" className="status-declined">
                  Declined
                </option>
              </Input>
              {errors.status && (
                <div className="invalid-feedback">{errors.status}</div>
              )}
            </FormGroup>

            {/* Run Date */}
            <FormGroup>
              <Label for="runDate">Run Date</Label>
              <Input
                type="date"
                name="runDate"
                id="runDate"
                value={runDate}
                onChange={(e) => setRunDate(e.target.value)}
                className={errors.runDate ? 'is-invalid' : ''}
              />
              {errors.runDate && (
                <div className="invalid-feedback">{errors.runDate}</div>
              )}
            </FormGroup>

            {/* Run Number */}
            <FormGroup>
              <Label for="runNumber">Run Number</Label>
              <Input
                type="number"
                name="runNumber"
                id="runNumber"
                value={runNumber}
                onChange={(e) => setRunNumber(e.target.value)}
                className={errors.runNumber ? 'is-invalid' : ''}
              />
              {errors.runNumber && (
                <div className="invalid-feedback">{errors.runNumber}</div>
              )}
            </FormGroup>

            {/* Notes */}
            <FormGroup>
              <Label for="notes">Notes</Label>
              <Input
                type="textarea"
                name="notes"
                id="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            {runHistory ? 'Update Run' : 'Create Run'}
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RunHistoryForCompaniesTable;
