import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText
} from 'reactstrap';
import ReactSelect from 'react-select'; // <-- Import from react-select
import mammoth from 'mammoth';
import { v4 as uuidv4 } from 'uuid';
import sdk from 'api-sdk';
import { toast } from 'react-toastify';

const customStyles = {
  // Control the styles of the "selected" or currently visible value
  singleValue: (provided) => ({
    ...provided,
    color: '#000', // set to black or your desired color
  }),
  // Control the styles of the dropdown options list
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#eee' : '#fff',
    color: '#000', // ensure text is visible against the background
  }),
  // (Optional) Control the styles of the placeholder text
  placeholder: (provided) => ({
    ...provided,
    color: '#666',
  }),
};

const SigmaRuleParser = () => {
  const [scenarios, setScenarios] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [silverBullets, setSilverBullets] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [existingScenarios, setExistingScenarios] = useState([]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [bulletsResponse, scenariosResponse] = await Promise.all([
          sdk.silverBullets.get(),
          sdk.scenario.get(),
        ]);
        setSilverBullets(bulletsResponse);
        setExistingScenarios(scenariosResponse);
      } catch (error) {
        console.error('Initial data load error:', error);
        toast.error('Failed to load initial data');
      }
    };
    fetchInitialData();
  }, []);

  const extractScenarios = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = Array.from(doc.body.children);

    const scenarios = [];
    let currentScenario = null;
    let currentSection = null;

    elements.forEach((element) => {
      const tagName = element.tagName.toLowerCase();
      const text = element.textContent.trim();

      // Detect new scenario (handle headers)
      if (tagName === 'h1') {
        if (currentScenario) scenarios.push(currentScenario);
        currentScenario = {
          id: uuidv4(),
          name: text.replace(/(# |\[•\])/g, '').trim(),
          description: '',
          attackFlowImage: null,
          detectionRule: '',
          selectedSilverBullet: null,
        };

        currentSection = null;
        return;
      }

      if (!currentScenario) return;

      // Handle section headers
      if (tagName === 'p') {
        const sectionMatch = text.match(/^(Description|Attack Flow|Detection Rule)$/i);
        if (sectionMatch) {
          currentSection = sectionMatch[1].toLowerCase().replace(' ', '');
          return;
        }
      }

      // Handle content based on current section
      switch (currentSection) {
        case 'description':
          if (tagName === 'p' && !text.match(/\[.*?\]/)) {
            currentScenario.description += text + '\n';
          }
          break;

        case 'attackflow':
          // Check for nested <img> within the current element
          const imgs = element.querySelectorAll('img');
          if (imgs.length > 0 && !currentScenario.attackFlowImage) {
            currentScenario.attackFlowImage = imgs[0].getAttribute('src');
          }
          break;

        case 'detectionrule':
          if (tagName === 'pre') {
            currentScenario.detectionRule = element.textContent.trim();
          } else if (tagName === 'p' && text.startsWith('title:')) {
            currentScenario.detectionRule = element.textContent.trim();
          }
          break;

        default:
          if (tagName === 'p' && text && !text.match(/\[.*?\]/)) {
            currentScenario.description += text + '\n';
          }
      }
    });

    if (currentScenario) scenarios.push(currentScenario);

    // Clean up descriptions
    return scenarios.map((scenario) => ({
      ...scenario,
      description: scenario.description
        .replace(/\n+/g, '\n')
        .replace(/\[Description\]\s*/i, '')
        .trim(),
      detectionRule: scenario.detectionRule.trim(),
    }));
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsLoading(true);
    setError('');
    setScenarios([]);

    try {
      const result = await mammoth.convertToHtml(
        { arrayBuffer: await file.arrayBuffer() },
        {
          convertImage: mammoth.images.imgElement((image) => {
            return image.read('base64').then((content) => ({
              src: `data:${image.contentType};base64,${content}`,
              contentType: image.contentType,
            }));
          }),
        }
      );

      const extractedScenarios = extractScenarios(result.value);
      setScenarios(
        extractedScenarios.map((scenario) => ({
          ...scenario,
          // Try to auto-match by name (optional):
          selectedSilverBullet: silverBullets.find((b) => b.name === scenario.name) || null,
        }))
      );
    } catch (err) {
      setError('Error parsing Sigma rule document');
      console.error('DOCX parsing error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSilverBulletSelect = (scenarioId, silverBulletId) => {
    const bulletId = parseInt(silverBulletId, 10);
    setScenarios((prev) =>
      prev.map((scenario) => {
        if (scenario.id === scenarioId) {
          const selectedSilverBullet = silverBullets.find((b) => b.id === bulletId);
          return {
            ...scenario,
            selectedSilverBullet,
            name: selectedSilverBullet ? selectedSilverBullet.name : scenario.name,
          };
        }
        return scenario;
      })
    );
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const createScenariosFromDoc = async () => {
    setIsCreating(true);
    try {
      for (const scenario of scenarios) {
        // Only process scenarios that have a SilverBullet mapping
        if (!scenario.selectedSilverBullet) continue;

        const exists = existingScenarios.some(
          (s) => s.silverBulletId === scenario.selectedSilverBullet.id
        );

        if (exists) {
          toast.info(`Skipping "${scenario.name}" - already exists`);
          continue;
        }

        const scenarioData = {
          silverBulletId: scenario.selectedSilverBullet.id,
          silverBulletName: scenario.selectedSilverBullet.name,
          description: scenario.description,
          status: 'Pending',
        };

        await sdk.scenario.create(scenarioData);

        if (scenario.attackFlowImage) {
          const imageFile = dataURLtoFile(
            scenario.attackFlowImage,
            `${scenario.name}_lightchart.png`
          );
          await sdk.silverBullets.uploadFile(
            imageFile,
            scenario.selectedSilverBullet.id,
            'lightchart'
          );
        }

        toast.success(`Created scenario "${scenario.name}" successfully!`);
      }
    } catch (error) {
      const errorMessage =
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message;
      toast.error(errorMessage || 'Error creating scenarios');
    } finally {
      setIsCreating(false);
    }
  };

  // Compute the number of ready scenarios (those with a SilverBullet mapping)
  const readyCount = scenarios.filter((s) => s.selectedSilverBullet !== null).length;

  const notMappedScenarios = scenarios.filter(
    (scenario) => !scenario.selectedSilverBullet
  );

  const readyScenarios = scenarios.filter(
    (scenario) =>
      scenario.selectedSilverBullet &&
      !existingScenarios.some((s) => s.silverBulletId === scenario.selectedSilverBullet.id)
  );

  const existingDocScenarios = scenarios.filter(
    (scenario) =>
      scenario.selectedSilverBullet &&
      existingScenarios.some((s) => s.silverBulletId === scenario.selectedSilverBullet.id)
  );


  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow-sm border-0">
            <CardBody>
              <CardTitle tag="h4" className="text-center mb-4">
                Sigma Rule Importer
              </CardTitle>

              {/* File Upload */}
              <FormGroup>
                <Label for="sigmaUpload">Upload Sigma Rules DOCX</Label>
                <Input
                  type="file"
                  id="sigmaUpload"
                  accept=".docx"
                  onChange={handleFileUpload}
                  disabled={isLoading}
                />
                {error && <div className="text-danger mt-2">{error}</div>}
              </FormGroup>

               {/* Scenario Summary Section */}
               {scenarios.length > 0 && (
                <Row className="mb-4">
                  <Col md={4}>
                    <Card className="border-danger mb-3">
                      <CardBody>
                        <CardTitle tag="h5" className="text-danger">
                          Not Mapped ({notMappedScenarios.length})
                        </CardTitle>
                        {notMappedScenarios.length > 0 ? (
                          <ul className="list-unstyled mb-0">
                            {notMappedScenarios.map((scenario) => (
                              <li key={scenario.id} className="text-truncate">
                                ✗ {scenario.name}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-muted">All scenarios mapped</div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={4}>
                    <Card className="border-success mb-3">
                      <CardBody>
                        <CardTitle tag="h5" className="text-success">
                          Ready to Import ({readyScenarios.length})
                        </CardTitle>
                        {readyScenarios.length > 0 ? (
                          <ul className="list-unstyled mb-0">
                            {readyScenarios.map((scenario) => (
                              <li key={scenario.id} className="text-truncate">
                                ✓ {scenario.selectedSilverBullet.name}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-muted">No scenarios ready</div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={4}>
                    <Card className="border-warning mb-3">
                      <CardBody>
                        <CardTitle tag="h5" className="text-warning">
                          Existing ({existingDocScenarios.length})
                        </CardTitle>
                        {existingDocScenarios.length > 0 ? (
                          <ul className="list-unstyled mb-0">
                            {existingDocScenarios.map((scenario) => (
                              <li key={scenario.id} className="text-truncate">
                                ⚠ {scenario.selectedSilverBullet.name}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-muted">No existing scenarios</div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}

              {/* Scenario List */}
              {scenarios.length > 0 && (
                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Button
                      color="success"
                      onClick={createScenariosFromDoc}
                      disabled={isCreating || readyCount === 0}
                    >
                      {isCreating ? 'Creating...' : 'Import All Scenarios'}
                    </Button>
                    <div className="text-muted">
                      {readyCount} of {scenarios.length} scenarios ready
                    </div>
                  </div>

                  <Row className="g-0">
                    {scenarios.map((scenario) => {
                      const exists = existingScenarios.some(
                        (s) =>
                          scenario.selectedSilverBullet &&
                          s.silverBulletId === scenario.selectedSilverBullet.id
                      );

                      // Prepare the options for ReactSelect
                      const silverBulletOptions = silverBullets.map((bullet) => ({
                        value: bullet.id,
                        label: bullet.name,
                      }));

                      // If scenario already has a bullet selected
                      const selectedOption = scenario.selectedSilverBullet
                        ? {
                            value: scenario.selectedSilverBullet.id,
                            label: scenario.selectedSilverBullet.name,
                          }
                        : null;

                      return (
                        <Card key={scenario.id} className="mb-3">
                          <CardBody className="py-3">
                            <div
                              className={`mb-3 ${
                                exists
                                  ? 'text-warning'
                                  : scenario.selectedSilverBullet
                                  ? 'text-success'
                                  : 'text-danger'
                              }`}
                            >
                              <strong>
                                {exists
                                  ? '⚠ Exists'
                                  : scenario.selectedSilverBullet
                                  ? '✓ Ready'
                                  : '✗ Needs mapping'}
                              </strong>
                            </div>

                            {/* SilverBullet search + select */}
                            {!exists && (
                              <FormGroup>
                                <Label for={`silverBulletSelect-${scenario.id}`}>
                                  Map to SilverBullet
                                </Label>
                                <ReactSelect
                                  id={`silverBulletSelect-${scenario.id}`}
                                  placeholder="Search or select a SilverBullet..."
                                  value={selectedOption}
                                  options={silverBulletOptions}
                                  onChange={(option) =>
                                    handleSilverBulletSelect(scenario.id, option.value)
                                  }
                                  styles={customStyles}
                                  isClearable
                                />
                              </FormGroup>
                            )}

                            <CardTitle tag="h5">
                              {scenario.selectedSilverBullet
                                ? scenario.selectedSilverBullet.name
                                : scenario.name}
                            </CardTitle>

                            <CardText>
                              <strong>Description:</strong>
                              <div className="text-muted mt-1">
                                {scenario.description}
                              </div>
                            </CardText>

                            {scenario.attackFlowImage && (
                              <div className="mt-3">
                                <strong>Attack Flow Diagram:</strong>
                                <img
                                  src={scenario.attackFlowImage}
                                  alt="Attack flow"
                                  className="img-fluid mt-2 border rounded"
                                  style={{ maxHeight: '300px' }}
                                />
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      );
                    })}
                  </Row>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SigmaRuleParser;
