// SDK interface for BlueRuleClients
import { request } from './client';
var model = 'eye-of-the-enemy/BlueRuleClients';
var blueRuleClients = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  create: function create(data) {
    return request('post', "/".concat(model), data);
  },
  update: function update(id, data) {
    return request('patch', "/".concat(model, "/").concat(id), data);
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  },
  // Get clients for a specific rule
  getByRuleId: function getByRuleId(ruleId) {
    return request('get', "/".concat(model), null, {
      filter: {
        where: {
          rule_id: ruleId
        }
      }
    });
  },
  // Delete all client entries for a rule
  deleteByRuleId: function deleteByRuleId(ruleId) {
    return request('delete', "/".concat(model, "/deleteByRuleId/").concat(ruleId));
  },
  // Create multiple client entries at once
  createMultiple: function createMultiple(ruleId, clientNames) {
    return request('post', "/".concat(model, "/createMultiple"), {
      ruleId: ruleId,
      clientNames: clientNames
    });
  }
};
export default blueRuleClients;