import React, { useState } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import ChartImage from './ChartImage';
import './styles.css';

const MAX_CHAR_COUNT = 60;

const ScenarioCard = ({ scenario, onEdit, isSelected, onSelect }) => {
  // Local state to track whether the description and tactic are expanded
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isTacticExpanded, setIsTacticExpanded] = useState(false);

  const handleEditClick = (event) => {
    event.stopPropagation();
    onEdit(scenario); // Call parent handler
  };

  // Helper to truncate long text
  const truncateText = (text, isExpanded) => {
    if (!text) return '';
    // If expanded or text is already below threshold, show full text
    if (isExpanded || text.length <= MAX_CHAR_COUNT) {
      return text;
    }
    return text.substring(0, MAX_CHAR_COUNT) + '...';
  };

  return (
    <Card
      className={`scenario-card shadow-sm h-100 d-flex flex-column ${
        isSelected ? 'selected-card' : ''
      }`}
      style={{ cursor: 'pointer', position: 'relative' }}
      onClick={() => onSelect(scenario)}
    >
      <CardBody className="flex-grow-1 d-flex flex-column">
        <div className="d-flex justify-content-between align-items-start">
          <CardTitle tag="h5" className="scenario-card-title">
            {scenario.silverBulletName}
          </CardTitle>

          <FaEdit
            onClick={handleEditClick}
            className="edit-icon"
            style={{ cursor: 'pointer', color: '#007bff' }}
            size={20}
          />
        </div>

        {/* DESCRIPTION SECTION */}
        {scenario.description && (
          <CardText className="scenario-card-text">
            {truncateText(scenario.description, isDescriptionExpanded)}
            {scenario.description.length > MAX_CHAR_COUNT && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDescriptionExpanded((prev) => !prev);
                }}
                style={{ color: '#007bff', cursor: 'pointer', marginLeft: '5px' }}
              >
                {isDescriptionExpanded ? 'Show Less' : 'Read More'}
              </span>
            )}
          </CardText>
        )}

        {/* CHART SECTION (if needed) */}
        <ChartImage silverBulletId={scenario.silverBulletId} />

        {scenario.scenarioDifficulty && (
          <CardText className="scenario-card-text">
            <strong>Difficulty:</strong> {scenario.scenarioDifficulty}
          </CardText>
        )}

        {scenario.scenarioImpact && (
          <CardText className="scenario-card-text">
            <strong>Impact:</strong> {scenario.scenarioImpact}
          </CardText>
        )}

        {/* TACTIC SECTION */}
        {scenario.tactic && (
          <CardText className="scenario-card-text">
            <strong>Tactic: </strong>
            {truncateText(scenario.tactic, isTacticExpanded)}
            {scenario.tactic.length > MAX_CHAR_COUNT && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setIsTacticExpanded((prev) => !prev);
                }}
                style={{ color: '#007bff', cursor: 'pointer', marginLeft: '5px' }}
              >
                {isTacticExpanded ? 'Show Less' : 'Read More'}
              </span>
            )}
          </CardText>
        )}
      </CardBody>
    </Card>
  );
};

export default withRouter(ScenarioCard);
